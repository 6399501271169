import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import logo from './logoTeal.png';
// import msg from './message.png';
import LinearProgress from '@material-ui/core/LinearProgress';
// import CenteredGrid from '../RoomSelector/CenteredGrid';
import ReactDOM from 'react-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      position: 'absolute',
      top: '90%',
    },
    barroot: {
      backgroundColor: '#FFFFFFAA',
    },
    colorPrimary: {
      backgroundColor: '#FFFFFF55',
    },
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: 7,
      width: '100vw',
      height: '100vh',
      background: '#1a1a1a',
    },
  })
);
export default function LoadingPage() {
  const classes = useStyles();

  return ReactDOM.createPortal(
    <React.Fragment>
      <div className={classes.overlay}>
        <div
          style={{
            backgroundColor: '#1a1a1a',
            width: '100%',
            height: '100%',
          }}
        >
          <img
            alt="logo"
            src={logo}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#1a1a1a',
              width: '40vw',
              height: '30vw',
            }}
          />
          <div className={classes.root}>
            <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barroot }} />
          </div>
        </div>
      </div>
    </React.Fragment>,
    document.body
  );
}
