/* eslint-disable @typescript-eslint/ban-ts-ignore */
import io from 'socket.io-client';
import hark from 'hark';
import { logger, isDevelopment, logEvent, AnalyticsCategory, AnalyticsActionCall } from '../../Analytics';
import VartyListener from '../VideoProvider/listener';
import { WebRtcPeer } from 'kurento-utils';
import VideoStreamMerger from 'video-stream-merger';
import adapter from 'webrtc-adapter';
import { isFirefox, isSafari } from 'react-device-detect';

export type AddStreamFunction = (
  user: User | null,
  socketId: string,
  track: MediaStreamTrack,
  isConference: boolean
) => void;

export type Streams = {
  [id: string]: StreamsObject;
};

export type MetaObject = {
  socketID: string;
  user: User | null;
  object: StreamsObject | null;
  type: 'add' | 'remove' | 'noop' | 'clear';
};

export type StreamsObject = {
  stream: MediaStream;
  conferenceStream: MediaStream;
  audioObject?: HTMLVideoElement;
  hark?: hark.Harker;
  volume: number;
  isSpeaking: boolean;
  conference: boolean;
};

export type RemoveStreamFunction = (
  socketId: string,
  removedUser?: boolean,
  user?: User,
  conferenceCheck?: boolean
) => void;

// TODO(kevin): Add KMS Turn server and remove other turn server
const iceServers: RTCIceServer[] = [
  { urls: 'stun:stun.l.google.com:19302?transport=tcp' },
  {
    urls: 'turn:54.175.92.144:443?transport=tcp',
    username: 'voiceuser',
    credential: 'thereddkingreallydoesfollow88u',
  },
  { urls: 'stun:stun1.l.google.com:19302' },
  { urls: 'stun:stun1.l.google.com:19302?transport=tcp' },
  { urls: 'stun:stun.voiparound.com?transport=tcp' },
  { urls: 'stun:stun.voiparound.com' },
  { urls: 'stun:stun2.l.google.com:19302' },
  {
    urls: 'turn:54.175.92.144:443',
    username: 'voiceuser',
    credential: 'thereddkingreallydoesfollow88u',
  },
  { urls: 'stun:stun2.l.google.com:19302?transport=tcp' },
  { urls: 'stun:stun3.l.google.com:19302' },
  { urls: 'stun:stun4.l.google.com:19302?transport=tcp' },
  { urls: 'stun:stun.ekiga.net' },
  { urls: 'stun:stun.ideasip.com' },
  { urls: 'stun:stun.schlund.de' },
  { urls: 'stun:stun.xten.com' },
  { urls: 'stun:stun.ekiga.net?transport=tcp' },
  { urls: 'stun:stun.ideasip.com?transport=tcp' },
  { urls: 'stun:stun.schlund.de?transport=tcp' },
  { urls: 'stun:stun.xten.com?transport=tcp' },
];
export const generatePeerConnectionConfig = (wrtc: WebRTCHelper): RTCConfiguration => {
  return {
    iceServers: iceServers.concat(wrtc.twilioIceServers),
  };
};

const constraints: MediaStreamConstraints = {
  video: {
    width: 320,
    height: 320,
    aspectRatio: 3 / 3,
    frameRate: 15,
  },
  audio: {
    // @ts-ignore
    optional: [{ sampleRate: 44100 }, { sampleSize: 16 }],
  },
  // https://stackoverflow.com/questions/46063374/is-it-really-possible-for-webrtc-to-stream-high-quality-audio-without-noise
};

const constraintsAudioOnly: MediaStreamConstraints = {
  video: false,
  audio: {
    // @ts-ignore
    optional: [{ sampleRate: 44100 }, { sampleSize: 16 }],
  },
  // https://stackoverflow.com/questions/46063374/is-it-really-possible-for-webrtc-to-stream-high-quality-audio-without-noise
};

export enum StreamAttribute {
  AUDIO = 'audio',
  VIDEO = 'video',
  ACTIVE = 'active',
  SCREENSHARE = 'screenshare',
}

// Each connection is a socket
export type Connection = {
  socket: SocketIOClient.Socket;
  connections: {
    [id: string]: {
      pc: RTCPeerConnection | null;
      audio: boolean;
      video: boolean;
      canOffer: boolean;
      conference: boolean;
    };
  };
  users: { [id: string]: User };
  roomId: string | null;
  // Either A ur sharing or B your watching someone's stream
  screenConnections: {
    [id: string]: RTCPeerConnection;
  };
  jitsiConference: JitsiMeetJS.JitsiConference;
  usersInConvo: string[];
  connInterval: number | null;
};

export type BackendConnection = {
  socket: SocketIOClient.Socket;
  pulseInterval: number | null;
  presentTogether: boolean; // Should this be enabled, people entering the conversation
  // Will automatically be added to the stream.
  kurento: {
    presenter: WebRtcPeer | null;
    viewers: {
      [socketID: string]: WebRtcPeer;
    };
    viewingIDs: string[];
    stream: {
      merger: VideoStreamMerger | null; // null unless presentTogether is true
      selected: string;
      current: MediaStream | null;
      added: {
        [id: string]: string;
      };
    };
  };
};

// FROM MAIN.TS
export type User = {
  id: string;
  name: string;
  location: number[];
  distance?: number; // Include this
  color: string;
  activeOn: boolean;
  videoOn: boolean;
  audioOn: boolean;
  screenShareOn: boolean;
  focused: string;
  conferenceID: string;
  conferenceUserID: string;
};

export type WebRTCHelper = {
  backconn: BackendConnection | null;
  conn: Connection | null;
  jitsiConnection: JitsiMeetJS.JitsiConnection;
  jitsiTracks: { [id: string]: JitsiMeetJS.JitsiTrack[] };
  jitsiLocalTracks: JitsiMeetJS.JitsiLocalTrack[];
  streamVideoElement: HTMLVideoElement | null;
  setStreamVideoElement: (element: HTMLVideoElement) => void;
  localStream: MediaStream;
  screenStream: MediaStream | null;
  videoEnabled: boolean;
  selectedInputDevice: string | null;
  selectedInputCameraDevice: string | null;
  selectedOutputDevice: string | null;
  throttleLevel: number;
  twilioIceServers: RTCIceServer[];
};

const RELATIONSHIP = {
  TALK: 1,
  VIDEO: 2,
  NONE: 0,
};

export const userJoinedAudio = new Audio('./assets/audio/JOINED.mp3');
export const userLeftAudio = new Audio('./assets/audio/MUTED_LEFT.mp3');

// ALSO SET SERVER SIDE!
export const TALK_RADIUS = 250;
export const VIDEO_RADIUS = 250;

const isPCClosed = (pc?: RTCPeerConnection | null): boolean => {
  return !pc || pc.connectionState === 'closed';
};
const relationshipBetween = (u: User, p: User): number => {
  if (!u || !u?.location || !p || !p?.location) {
    return RELATIONSHIP.NONE;
  }
  const dist = Math.sqrt(Math.pow(u.location[0] - p.location[0], 2) + Math.pow(u.location[1] - p.location[1], 2));
  if (dist <= VIDEO_RADIUS) {
    return RELATIONSHIP.VIDEO;
  } else if (dist <= TALK_RADIUS) {
    return RELATIONSHIP.TALK;
  }
  return RELATIONSHIP.NONE;
};

const getName = (wrtc: WebRTCHelper, socketId: string) => {
  if (wrtc.conn && wrtc.conn.users[socketId]) {
    return wrtc.conn.users[socketId].name || 'NO_NAME';
  }
  return 'NO_NAME';
};

var reloadNeeded = false;
const setupConnection = (
  wrtc: WebRTCHelper,
  socketListId: string,
  mm: number,
  addStream: AddStreamFunction,
  removedStream: RemoveStreamFunction
): RTCOfferOptions => {
  let options = {};
  const conn = wrtc?.conn;
  //Wait for their ice candidate
  const pc = conn?.connections[socketListId]?.pc;
  if (!conn || !conn?.connections[socketListId] || !pc) {
    logger.error("Connection null couldn't set up");
    return {};
  }
  let lastNegociated: { ll: boolean; failedTimeout: number | null } = {
    ll: false,
    failedTimeout: null,
  };
  pc.onnegotiationneeded = async () => {
    logger.info(`[SOCKET-ICE] Renegociation needed: ${getName(wrtc, socketListId)} ${pc.connectionState}`);
    if (lastNegociated.ll || pc.connectionState === 'closed' || pc.connectionState === 'new') {
      logger.info('[SOCKET-ICE] Just renegociated');
      return;
    }
    if (socketListId > (conn.socket.id || 0)) {
      logger.info(`[SOCKET-ICE] Renegociation is leader`);
      WebRTC.offerConnection(conn, socketListId, options);
    }
  };
  pc.onconnectionstatechange = (): void => {
    const currentpc = pc;

    if (currentpc && isPCClosed(currentpc)) {
      logger.info(`[SOCKET-ICE] PC Closed: ${getName(wrtc, socketListId)}`);
      currentpc.close();
      if (lastNegociated.failedTimeout) {
        clearTimeout(lastNegociated.failedTimeout);
        lastNegociated.failedTimeout = null;
      }
      if (conn.connections[socketListId]) {
        conn.connections[socketListId].pc = null;
        if (conn.connections[socketListId].conference === false) {
          removedStream(socketListId, false, conn.users[socketListId], false);
          delete conn.connections[socketListId];
        }
      }
      return;
    } else if (!wrtc.conn) {
      logger.info(`[SOCKET-ICE] Convo already ended!! ${getName(wrtc, socketListId)}`);
      // Oh no I've been end called and pc is not null!!!
      currentpc.close();
      if (lastNegociated.failedTimeout) {
        clearTimeout(lastNegociated.failedTimeout);
        lastNegociated.failedTimeout = null;
      }
      return;
    }
    logger.info(`[SOCKET-ICE] RTC Connection State: ${getName(wrtc, socketListId)} : ${currentpc.connectionState}`);
    VartyListener.notifyConnection(currentpc.connectionState, wrtc.conn?.users[socketListId] || null);
    if (currentpc?.connectionState === 'failed') {
      //TODO create a message to indicate that the user is having connection issues.
      // @ts-ignore
      currentpc.restartIce();
      lastNegociated.ll = false;
      if (lastNegociated.failedTimeout) {
        clearTimeout(lastNegociated.failedTimeout);
        lastNegociated.failedTimeout = null;
      }
      lastNegociated.failedTimeout = setTimeout(() => {
        logger.info(`[SOCKET-ICE] RTC ICE FAILURE 12 SECONDS: ${getName(wrtc, socketListId)}`);
        if (!wrtc.conn) {
          logger.info(`[SOCKET-ICE] Convo already ended!! ${getName(wrtc, socketListId)}`);
          return;
        }
        if (lastNegociated.failedTimeout && currentpc?.connectionState === 'closed') {
          clearTimeout(lastNegociated.failedTimeout);
          lastNegociated.failedTimeout = null;
        }
        // Only dominant side can offer connection
        if (socketListId > (conn.socket.id || 0)) {
          logger.info(`[SOCKET-ICE] Renegociation is leader!`);
          lastNegociated.ll = true;
          // Let's just reconnect the peer connection
          if (conn.connections[socketListId]) {
            conn.connections[socketListId].pc?.close();
            conn.connections[socketListId].pc = new RTCPeerConnection(generatePeerConnectionConfig(wrtc));
            if (lastNegociated.failedTimeout) {
              clearTimeout(lastNegociated.failedTimeout);
              lastNegociated.failedTimeout = null;
            }
            setupConnection(wrtc, socketListId, mm, addStream, removedStream);
            WebRTC.offerConnection(conn, socketListId, options);
          }
        }
      }, 12000);
    } else if (currentpc?.connectionState === 'connected') {
      WebRTC.setBandwidth(wrtc);
      lastNegociated.ll = false;
      if (lastNegociated.failedTimeout) {
        clearTimeout(lastNegociated.failedTimeout);
        lastNegociated.failedTimeout = null;
      }
    } else if (currentpc?.connectionState === 'connecting') {
      if (lastNegociated.failedTimeout) {
        clearTimeout(lastNegociated.failedTimeout);
        lastNegociated.failedTimeout = null;
      }
      lastNegociated.failedTimeout = setTimeout(() => {
        if (currentpc?.connectionState === 'connected') {
          return;
        }
        logger.info(`[SOCKET-ICE] RTC ICE CONNECTING MORE THAN 10 SECONDS: ${getName(wrtc, socketListId)}`);
        if (!wrtc.conn) {
          logger.info(`[SOCKET-ICE] Convo already endeda ${getName(wrtc, socketListId)}`);
          return;
        }
        if (lastNegociated.failedTimeout && currentpc?.connectionState === 'closed') {
          clearTimeout(lastNegociated.failedTimeout);
          lastNegociated.failedTimeout = null;
        }
        // Only dominant side can offer connection
        if (socketListId > (conn.socket.id || 0)) {
          logger.info(`[SOCKET-ICE] Renegociation is leaderLL`);
          lastNegociated.ll = true;
          // Let's just reconnect the peer connection
          if (conn.connections[socketListId]) {
            conn.connections[socketListId].pc?.close();
            conn.connections[socketListId].pc = new RTCPeerConnection(generatePeerConnectionConfig(wrtc));
            if (lastNegociated.failedTimeout) {
              clearTimeout(lastNegociated.failedTimeout);
              lastNegociated.failedTimeout = null;
            }
            setupConnection(wrtc, socketListId, mm, addStream, removedStream);
            WebRTC.offerConnection(conn, socketListId, options);
          }
        } else {
          logger.info("[SOCKET-ICE] You weakling, let's wait for a reconnect");
          conn.connections[socketListId]?.pc?.close();
          delete conn.connections[socketListId];
          removedStream(socketListId);
        }
      }, 10000);
    }
  };
  pc.oniceconnectionstatechange = () => {
    if (isDevelopment) {
      logger.info(`[SOCKET-ICE] RTC ICE state: ${getName(wrtc, socketListId)}: ` + pc.iceConnectionState);
    }
    if (pc?.iceConnectionState === 'failed') {
      logger.error('[SOCKET-ICE]', `Ice connection failed ${getName(wrtc, socketListId)}`);
      // @ts-ignore
      pc.restartIce();
      // Reoffer connection
      WebRTC?.offerConnection(conn, socketListId, {
        offerToReceiveVideo: true,
      });
    }
  };

  pc.onicecandidateerror = error => {
    if (isDevelopment) {
      logger.info('[SOCKET-ICE] Candidate error: ' + error.errorText + ' - ' + error.errorCode + ' - ' + error.url);
    }
  };

  pc.onicecandidate = (event: RTCPeerConnectionIceEvent): void => {
    if (event.candidate) {
      if (isDevelopment) {
        logger.info(
          '[SOCKET-ICE]',
          'onicecandidate ([protocol] [port])',
          event.candidate.protocol,
          event.candidate.port
        );
      }

      conn.socket.emit('signal', socketListId, JSON.stringify({ ice: event.candidate }));
    }
  };

  // Wait for their audio stream
  pc.ontrack = (event: RTCTrackEvent): void => {
    logger.info(`[SOCKET] Connection received: ${getName(wrtc, socketListId)} : ` + event.track.kind + '');
    const currentpc = conn?.connections[socketListId]?.pc;
    if (!wrtc.conn) {
      if (currentpc) {
        currentpc.close();
      }
      logger.info('[SOCKET-ICE] Convo already ended!');
      return;
    }

    if (conn.connections[socketListId].conference) {
      // If already conferencing cut the connection.
      logger.info('Already conferencing');
      if (currentpc) {
        currentpc.close();
        conn.connections[socketListId].pc = null;
      }
    }
    if (conn.users[socketListId]) {
      addStream(conn.users[socketListId], socketListId, event.track, false);
    } else {
      logger.info('[CALL] user currently null. Killing connection and waiting for other user to initiate');
      if (currentpc) {
        currentpc.close();
        delete conn.connections[socketListId];
        removedStream(socketListId);
      }
    }
  };
  if (mm === RELATIONSHIP.VIDEO) {
    if (wrtc.localStream.getAudioTracks()[0]) {
      try {
        pc?.addTrack(wrtc.localStream.getAudioTracks()[0]);
      } catch (err) {
        logger.error(err);
      }
    } else {
      logger.error('[AUDIO] No audio source');
      if (!reloadNeeded) {
        //TODO when we put back audio/video selector, replace this and allow to choose a different device.
        reloadNeeded = true;
        alert('Your microphone did not connect. We are refreshing the page to try again');
        window.location.reload();
      }
    }
    conn.connections[socketListId].audio = true;
    if (wrtc.localStream.getVideoTracks()[0]) {
      try {
        pc?.addTrack(wrtc.localStream.getVideoTracks()[0]);
      } catch (err) {
        logger.error(err);
      }
    } else {
      // Receive video even if you are unable to transmit video!
      options = {
        offerToReceiveVideo: true,
      };
      logger.error('[VIDEO] No video source');
      if (!reloadNeeded) {
        //TODO when we put back audio/video selector, replace this and allow to choose a different device.
        reloadNeeded = true;
        alert('Your camera did not connect. We are refreshing the page to try again');
        window.location.reload();
      }
    }
    conn.connections[socketListId].video = true;
  } else if (mm === RELATIONSHIP.TALK) {
    try {
      pc.addTrack(wrtc.localStream.getAudioTracks()[0]);
    } catch (err) {
      console.log(err);
    }
    conn.connections[socketListId].audio = true;
  }
  return options;
};

export const gotMessageFromServer = async (
  wrtc: WebRTCHelper,
  fromId: string,
  message: string,
  relationship: number,
  addStream: AddStreamFunction,
  removedStream: RemoveStreamFunction,
  forced?: boolean
): Promise<void> => {
  const conn = wrtc.conn;
  if (!conn) return;
  const pc = conn.connections[fromId]?.pc;
  if (pc && pc.signalingState === 'closed' && conn.connections[fromId].conference === false) {
    removedStream(fromId);
    await new Promise(resolve => setTimeout(resolve, 300)); // .3 sec
    delete conn.connections[fromId];
    logger.error('FISHY CODE');
  }
  if (!conn.connections[fromId]) {
    logger.info(`[SOCKET-CONNECT] creating connection: ${getName(wrtc, fromId)}`);
    // Connection doesn't exist
    conn.connections[fromId] = {
      pc: new RTCPeerConnection(generatePeerConnectionConfig(wrtc)),
      audio: false,
      video: false,
      canOffer: true,
      conference: false,
    };
    setupConnection(wrtc, fromId, relationship, addStream, removedStream);
  }
  //Parse the incoming signal
  const signal = JSON.parse(message);
  //Make sure it's not coming from yourself
  if (fromId !== conn.socket.id) {
    if (signal.sdp) {
      if (isDevelopment) {
        console.log('Removed', fromId, signal);
      }
      const currentpc = conn?.connections[fromId]?.pc;
      if (!currentpc) {
        return;
      }
      // Let me make sure the tracks are in place
      try {
        await currentpc.setRemoteDescription(new RTCSessionDescription(signal.sdp));
        if (signal.sdp.type === 'offer') {
          const description = await currentpc.createAnswer();

          await currentpc.setLocalDescription(description);

          conn.socket.emit(
            'signal',
            fromId,
            JSON.stringify({
              sdp: currentpc.localDescription,
            })
          );
        }
      } catch (e) {
        conn.connections[fromId]?.pc?.close();
        delete conn.connections[fromId];
        if (!forced) {
          logger.info('[EXTRA] YEP no can set remote description');
          gotMessageFromServer(wrtc, fromId, message, relationship, addStream, removedStream, true);
        } else {
          logger.info('[EXTRA] Waiting for reconnect later. User: ' + (conn?.users?.[fromId] != null));
          removedStream(fromId, true, conn?.users?.[fromId]);
        }
      }
    }
    if (signal.ice) {
      const currentpc = conn.connections[fromId]?.pc;
      if (currentpc) currentpc.addIceCandidate(new RTCIceCandidate(signal.ice)).catch(e => console.log(e));
    }
  }
};

const noiseReduction = (localStream: MediaStream): MediaStream => {
  // @ts-ignore
  const context = new (window.AudioContext ||
    // @ts-ignore
    window.webkitAudioContext)();

  const mediaStreamSource = context.createMediaStreamSource(localStream);
  const options = {
    interval: 50,
    threshold: -50,
  };
  const speechEvents = hark(localStream, options);
  const audioDestination = context.createMediaStreamDestination();
  const gainNode = context.createGain();
  mediaStreamSource.connect(gainNode);
  gainNode.connect(audioDestination);
  gainNode.gain.value = 0.45;
  speechEvents.on('speaking', () => {
    gainNode.gain.value = 1;
  });
  speechEvents.on('stopped_speaking', () => {
    gainNode.gain.value = 0.45;
  });
  return audioDestination.stream;
};

const WebRTC = {
  init: (setStreamVideoElement: (element: HTMLVideoElement) => void): WebRTCHelper => {
    console.log('WEBRTC init');
    const ret: WebRTCHelper = {
      localStream: new MediaStream(),
      jitsiConnection: null,
      jitsiTracks: {},
      jitsiLocalTracks: [],
      backconn: null,
      conn: null,
      screenStream: null,
      streamVideoElement: null,
      setStreamVideoElement: setStreamVideoElement,
      videoEnabled: true,
      selectedInputCameraDevice: null,
      selectedOutputDevice: null,
      selectedInputDevice: null,
      throttleLevel: 0,
      twilioIceServers: [],
    };
    return ret;
  },
  closeStreams: async (
    wrtc: WebRTCHelper,
    removedStream: (socketId: string, removedUser?: boolean) => void
  ): Promise<void> => {
    if (wrtc?.conn?.connections) {
      Object.keys(wrtc.conn.connections).forEach(socketId => {
        wrtc.conn?.connections[socketId]?.pc?.close();
        removedStream(socketId);
      });
      wrtc.conn.connections = {};
      Object.keys(wrtc.conn.screenConnections).forEach(socketId => {
        wrtc.conn?.screenConnections[socketId].close();
      });
      wrtc.conn.screenConnections = {};
    }
  },
  disconnect: async (wrtc: WebRTCHelper, removedStream: (socketId: string, removedUser?: boolean) => void) => {
    let socket = wrtc?.conn?.socket;
    if (!socket) return;
    logger.info('[SOCKET] Leaving convo');
    logEvent(AnalyticsCategory.Call, AnalyticsActionCall.Left);
    WebRTC.closeStreams(wrtc, removedStream);
    if (wrtc?.conn?.connInterval) clearInterval(wrtc?.conn?.connInterval);
    wrtc.conn = null;
    // TODO(kevin): Socket cannot disconnect until all users update the webcam back to normal
    await new Promise(res => setTimeout(res, 500));
    socket.disconnect();
  },
  close: (wrtc: WebRTCHelper, removedStream: (socketId: string, removedUser?: boolean) => void): void => {
    if (wrtc.conn) {
      WebRTC.closeStreams(wrtc, removedStream);
      if (wrtc.localStream) {
        wrtc.localStream.getTracks().forEach(t => t.stop());
      }
      if (wrtc.screenStream) {
        wrtc.screenStream.getTracks().forEach(t => t.stop());
      }
      wrtc.conn.socket.close();
      if (wrtc.conn.connInterval) clearInterval(wrtc.conn.connInterval);
      wrtc.conn = null;
      wrtc.localStream = new MediaStream();
      wrtc.screenStream = null;
    }
  },
  setBandwidth: async (wrtc: WebRTCHelper, throttleNumber?: number): Promise<boolean> => {
    // Throttles all connections
    if (throttleNumber) {
      if (throttleNumber < 0) throttleNumber = 0;
      if (throttleNumber > 6) throttleNumber = 6;
    }
    // We want to makes sure all connections have stablized before calling a throttle

    if (throttleNumber && throttleNumber < wrtc.throttleLevel) {
      logger.info("[THROTTLE] You've already lowered the throttle past this level");
    } else if (throttleNumber && throttleNumber >= wrtc.throttleLevel) {
      wrtc.throttleLevel = throttleNumber;
    }
    // In Chrome, use RTCRtpSender.setParameters to change bandwidth without
    // (local) renegotiation. Note that this will be within the envelope of
    // the initial maximum bandwidth negotiated via SDP.
    if (
      (adapter.browserDetails.browser === 'chrome' ||
        adapter.browserDetails.browser === 'safari' ||
        (adapter.browserDetails.browser === 'firefox' && (adapter.browserDetails.version || 0) >= 64)) &&
      'RTCRtpSender' in window &&
      'setParameters' in window.RTCRtpSender.prototype
    ) {
      // Yay passed!
    } else {
      return false;
    }
    if (!wrtc.conn?.connections) return false;
    const throttle4 = Object.keys(wrtc.conn?.connections).length === 3;
    const throttle5 = Object.keys(wrtc.conn?.connections).length > 3;
    try {
      Object.values(wrtc.conn?.connections).forEach(connection => {
        if (!connection.pc) return;
        if (connection.pc.connectionState !== 'connected') {
          return;
        }
        if (connection.pc.getSenders().length < 2) {
          return;
        }
        // Video always gets added second
        const sender = connection.pc.getSenders()[1];
        const parameters = sender.getParameters();
        if (!parameters) {
          logger.info('[THROTTLE] parameters null');
          return;
        }
        // This should only execute for Firefox
        if (!parameters.encodings) {
          parameters.encodings = [{}];
        }
        if (parameters.encodings.length === 0) {
          return;
        }
        // console.log('[THROTTLE]', connection.pc.getSenders(), parameters, parameters.encodings);
        if (throttle5) {
          parameters.encodings[0].maxBitrate = (125 - wrtc.throttleLevel * 13) * 1000;
        } else if (throttle4) {
          parameters.encodings[0].maxBitrate = (300 - wrtc.throttleLevel * 42) * 1000;
        } else {
          // delete parameters.encodings[0].maxBitrate;
          // Normally I wouldn't cap bandwidth, but this can save us a lot of headaches in the future for TURN costs
          parameters.encodings[0].maxBitrate = (500 - wrtc.throttleLevel * 75) * 1000;
        }
        sender
          .setParameters(parameters)
          .then(() => {
            logger.info(
              '[THROTTLE]: Framerate set',
              parameters.encodings.length > 0 ? parameters.encodings[0].maxBitrate : ' no encoding'
            );
          })
          .catch(e => logger.error('[THROTTLE]: ', e));
      });
      return true;
    } catch {
      logger.error('[THROTTLE]: something went wrong');
    }
    return false;
  },
  connect: async (
    wrtc: WebRTCHelper,
    name: string,
    roomId: string,
    audioOn: boolean,
    videoOn: boolean,
    userUpdate: (conn: Connection, user?: User, isForce?: boolean) => void,
    addStream: AddStreamFunction,
    removedStream: RemoveStreamFunction,
    disconnected: () => void,
    onConnection: (wrtc: WebRTCHelper) => void
  ): Promise<void> => {
    // Set up socket!!!
    if (roomId.length === 0) {
      return;
    }
    if (wrtc.conn && wrtc.conn.socket.connected) {
      // TODO (kevin): Because of waiting to disconnect socket this needs to also wait
      logger.warn("IT's Still happening");
      await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
      // Force close
      if (wrtc.conn && wrtc.conn.socket.connected) {
        logger.warn("IT's Still happening x2");
        wrtc.conn.socket.close();
      }
    }
    logEvent(AnalyticsCategory.Call, AnalyticsActionCall.Joined);
    const socket = io.connect('https://dev.joinvoiceplace.com/', { secure: true, reconnectionAttempts: 8 });
    // const socket = io.connect('/', { secure: true, reconnectionAttempts: 15 });

    const conn: Connection = {
      socket: socket,
      roomId: null,
      connections: {},
      users: {},
      usersInConvo: [],
      screenConnections: {},
      jitsiConference: null,
      connInterval: null,
    };

    wrtc.conn = conn;

    socket.on('disconnect', (reason: string) => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        logger.error('[SOCKET] DISCONNECTED');
        WebRTC.disconnect(wrtc, removedStream);
        disconnected();
      }
    });
    // TODO(kevinfang): set # of connection attempts before failing
    socket.on('reconnect_error', () => {
      logger.error('[SOCKET] Reconnect Error, reconnecting...');
      //TODO. Called when local internet fails.
    });
    socket.on('reconnect_failed', () => {
      WebRTC.disconnect(wrtc, removedStream);
      disconnected();
      logger.info('[SOCKET] Connection Failed');
      if (window.confirm('You have been disconnected from the network. Would you like to reconnect?')) {
        logger.info('[SOCKET] Reconnecting from failure');
        WebRTC.connect(
          wrtc,
          name,
          roomId,
          audioOn,
          videoOn,
          userUpdate,
          addStream,
          removedStream,
          disconnected,
          onConnection
        );
      } else {
        alert('Try to exit the call and rejoin or refresh the page!');
      }
    });

    conn.connInterval = setInterval(async () => {
      await Promise.all(
        // Connect all users every 5 seconds if not
        Object.values(conn.users).map(async user => {
          if (conn.socket.id !== user.id) {
            await WebRTC.connectUser(wrtc, user.id, addStream, userUpdate, removedStream);
          }
        })
      );
    }, 5000);

    socket.on('signal', async (fromId: string, message: string, relationship: number) => {
      await new Promise(resolve => setTimeout(resolve, 100)); // .1 sec
      gotMessageFromServer(wrtc, fromId, message, relationship, addStream, removedStream);
    });
    socket.on('connect', function() {
      socket.emit('name-set', name);
      let nameset: number | null = setInterval(() => socket.emit('name-set', name), 3000);
      socket.on('name-received', () => {
        if (nameset) {
          clearInterval(nameset);
          socket.emit('room-set', roomId);
          WebRTC.updateStream(conn, StreamAttribute.AUDIO, audioOn);
          WebRTC.updateStream(conn, StreamAttribute.VIDEO, videoOn);
          nameset = null;
          onConnection(wrtc);
        }
      });

      socket.on('user-left', (id: string) => {
        if (isDevelopment) {
          console.log('user left', conn?.users?.[id]);
        }
        if (conn?.connections?.[id]?.pc) {
          conn.connections[id].pc?.close();
          delete conn.connections[id];
          WebRTC.setBandwidth(wrtc);
        }
        // Remove stream before user
        removedStream(id, true, conn?.users?.[id]);
        if (conn?.users?.[id]) {
          delete conn.users[id];
        }
      });
      socket.on('user-update', async (user: User, force: boolean, mod?: string) => {
        if (isDevelopment) {
          console.log('user updated!', user, mod);
        }
        if (mod && mod.includes('ignoreself') && user.id === conn.socket.id) {
          conn.users[user.id] = user;
          return;
        }
        conn.users[user.id] = user;
        await Promise.all(
          // TODO(kevinfang): it's all users again
          Object.values(conn.users).map(async user => {
            if (conn.socket.id !== user.id) {
              await WebRTC.connectUser(wrtc, user.id, addStream, userUpdate, removedStream);
            }
          })
        );
        // We never update ourselves unless it's a sync -- will cause issues with LineCanvas if so
        userUpdate(conn, user, force);
      });
      socket.on('user-sync', async (count: string, usersList: User[]) => {
        if (isDevelopment) {
          console.log('USER-SYNC', usersList);
        }
        conn.users = {};
        usersList.forEach(user => {
          conn.users[user.id] = user;
        });
        // TODO(kevinfang): Put people in the same room

        // conn.usersInConvo = usersList;
        await Promise.all(
          usersList.map(async user => {
            const socketListId = user.id;
            // Only connect if they are also active
            if (conn.socket.id !== socketListId) {
              await WebRTC.connectUser(wrtc, socketListId, addStream, userUpdate, removedStream);
            }
          })
        );
        userUpdate(conn, undefined, true);
      });
      socket.on('user-joined', function(user: User, count: number) {
        if (isDevelopment) console.log('JOINED ROOM', user, count);
        conn.users[user.id] = user;
        userUpdate(conn, user, true);

        //Create an offer to connect with your local description
        //TODO(kevinfang): Guarantee that usersInConvo is updated before user-joined is called
        if (user.id !== conn.socket.id) {
          //&& conn.usersInConvo.includes(user.id)) {
          WebRTC.connectUser(wrtc, user.id, addStream, userUpdate, removedStream);
        }
      });
      socket.on('leave-conference', async () => {
        logger.info('[SOCKET] Leaving conference');
      });
      socket.on('should-conference', async (conferenceName: string) => {
        console.log('shoud conference');
      });
    });
  },
  getDevices: async (gotDevices: (info: MediaDeviceInfo[]) => void, addInterval?: boolean): Promise<number | null> => {
    const runDevices = () => {
      if (navigator.mediaDevices.enumerateDevices) {
        navigator.mediaDevices
          .enumerateDevices()
          .then(gotDevices)
          .catch((): void => {
            alert('Could not get input/output devices');
          });
      } else {
        alert('Could not get input/output devices');
      }
    };
    let interval = null;
    if (!isFirefox && !isSafari && !addInterval) {
      const perm = await navigator.permissions.query({ name: 'camera' });
      perm.onchange = runDevices;
      const permMic = await navigator.permissions.query({ name: 'microphone' });
      permMic.onchange = runDevices;
    } else {
      interval = setInterval(runDevices, 5000);
    }
    runDevices();
    return interval;
  },
  updateMediaSettings: async (
    wrtc: WebRTCHelper,
    videoEnabled: boolean,
    getUserMediaSuccess: (stream: MediaStream) => void,
    addStream: AddStreamFunction,
    userUpdate: (conn: Connection, user?: User, isForce?: boolean) => void,
    removedStream: (socketId: string, removedUser?: boolean) => void
  ): Promise<void> => {
    const success = async (stream: MediaStream): Promise<void> => {
      if (wrtc.conn) {
        WebRTC.closeStreams(wrtc, removedStream);
        (async (): Promise<void> => {
          await new Promise(resolve => setTimeout(resolve, 1000)); // 1.5 seconds to reconnect
          await Promise.all(
            // TODO(kevinfang): wat
            Object.values(wrtc.conn?.users || {}).map(async user => {
              const socketListId = user.id;
              // Only connect if they are also active
              if (wrtc.conn?.socket.id !== socketListId) {
                await WebRTC.connectUser(wrtc, socketListId, addStream, userUpdate, removedStream, true);
              }
            })
          );
        })();
      }
      getUserMediaSuccess(stream);
    };
    // @ts-ignore
    if (navigator.mediaDevices.getUserMedia) {
      wrtc.videoEnabled = videoEnabled;
      try {
        let theConstraint = videoEnabled ? constraints : constraintsAudioOnly;
        if (videoEnabled && wrtc.selectedInputCameraDevice != null) {
          theConstraint = {
            ...theConstraint,
            video: {
              // @ts-ignore
              ...theConstraint.video,
              deviceId: wrtc.selectedInputCameraDevice,
            },
          };
        }
        if (wrtc.selectedInputDevice != null) {
          theConstraint = {
            ...theConstraint,
            audio: {
              // @ts-ignore
              ...theConstraint.audio,
              deviceId: wrtc.selectedInputDevice,
            },
          };
        }
        navigator.mediaDevices
          .getUserMedia(theConstraint)
          .then(localStream => {
            console.log('Obtained NEW MEDIA', videoEnabled);
            const onlyAudio = new MediaStream(localStream.getAudioTracks());
            const stream = isFirefox || isSafari ? onlyAudio : noiseReduction(onlyAudio);
            localStream.getVideoTracks().forEach(s => stream.addTrack(s));
            wrtc.localStream = stream;
            success(stream);
          })
          .catch((e): void => {
            theConstraint = constraintsAudioOnly;
            if (wrtc.selectedInputDevice != null) {
              theConstraint = {
                ...theConstraint,
                audio: {
                  // @ts-ignore
                  ...theConstraint.audio,
                  deviceId: wrtc.selectedInputDevice,
                },
              };
            }
            logger.warn(e);
            navigator.mediaDevices
              .getUserMedia(theConstraint)
              .then(localStream => {
                console.log('BACKUP Obtained NEW MEDIA');
                const stream = noiseReduction(localStream);
                wrtc.localStream = stream;
                wrtc.videoEnabled = false;
                success(stream);
              })
              .catch((): void => {
                alert('Could not get microphone access');
              });
          });
      } catch (err) {
        logger.error(err);
        // alert(
        //   'Warning: We have detected a potential problem with your microphone. \nFeel free to continue to the room but you may have audio problems. \nIf you do, check to see if you microphone is disconnected.'
        // );
      }
    }
  },
  connectUser: async (
    wrtc: WebRTCHelper,
    socketListId: string,
    addStream: AddStreamFunction,
    userUpdate: (conn: Connection, user?: User, isForce?: boolean) => void,
    removedStream: RemoveStreamFunction,
    forceConnect?: boolean
  ): Promise<void> => {
    const conn = wrtc.conn;
    if (!conn) return;
    const userSelf = conn.users[conn.socket.id];
    if (!conn.users[socketListId] || !userSelf) {
      // User does not exist so we have no information.
      return;
    }
    const otherUser = conn.users[socketListId];
    // const shouldConference = otherUser.conferenceID === userSelf.conferenceID;
    // TODO (kevin): CONFERENCE MODE DISABLED
    const shouldConference = false;
    const mm = relationshipBetween(userSelf, otherUser);
    if (conn.connections?.[socketListId]) {
      // If there is already a connection but shouldn't be the case
      if (mm === RELATIONSHIP.NONE) {
        if (conn.connections[socketListId].pc) {
          conn.connections[socketListId].pc?.close();
        }
        delete conn.connections[socketListId];
        removedStream(socketListId);
      }
    }
    // So connection is still here
    if (conn?.connections?.[socketListId]) {
      // If we're both conferencing....
      if (shouldConference) {
        // Move from a non-conference connection to conference connection
        if (conn.connections[socketListId].conference === false) {
          logger.info('[SOCKET] moving to conference mode');
          if (wrtc?.jitsiTracks?.[otherUser.conferenceUserID]) {
            // If there is already a connection then we disconnect
            if (!isPCClosed(conn.connections[socketListId].pc)) {
              conn.connections[socketListId].pc?.close();
              conn.connections[socketListId].pc = null;
              conn.connections[socketListId].conference = true;
              removedStream(socketListId, false, conn.users[socketListId], true);
            }
            await new Promise(resolve => setTimeout(resolve, 300)); // .3 sec
            if (wrtc.jitsiTracks[otherUser.conferenceUserID]) {
              wrtc.jitsiTracks[otherUser.conferenceUserID].forEach(t => {
                addStream(otherUser, otherUser.id, t.getTrack(), true);
              });
              // TODO(kevinfang): remove hack to get locations to re-render
              await new Promise(resolve => setTimeout(resolve, 600)); // .3 sec
              userUpdate(conn, otherUser, false);
            }
          }
        }
        return;
        // If we shouldn't be in the same conference shouldConference == false
        // but we are in conference mode
      } else if (conn?.connections?.[socketListId]?.conference) {
        // If the connection used to be a JITSI conference but now it is no longer
        // Delete this connection and reconnection manually.
        removedStream(socketListId, false);
        conn.connections[socketListId].pc = new RTCPeerConnection(generatePeerConnectionConfig(wrtc));
        conn.connections[socketListId].conference = false;
        const options = setupConnection(wrtc, socketListId, mm, addStream, removedStream);
        WebRTC.offerConnection(conn, socketListId, options);
        logger.info('[SOCKET] Downgrading conference');
        return;
      } else if (conn?.connections?.[socketListId]?.pc) {
        // If not conference and should not be a conference, if the peer is closed then we should renegociate
        // Close connection if connected
        if (isPCClosed(conn.connections[socketListId].pc)) {
          console.log('[SOCKET] Socket closed');
          delete conn.connections[socketListId];
          removedStream(socketListId);
        } else {
          return; // Don't reconnect if you don't have too
        }
      } else {
        return;
      }
    }
    // If relationship doesn't exist then don't bother even creating a connection
    if (mm === RELATIONSHIP.NONE) {
      return;
    }
    if (conn?.connections?.[socketListId]) {
      // Something happened and I don't want to mess around with it. (Due to the awaits above)
      return;
    }
    logger.info(`[SOCKET] Created connection: ${getName(wrtc, socketListId)}`);
    conn.connections[socketListId] = {
      pc: new RTCPeerConnection(generatePeerConnectionConfig(wrtc)),
      audio: false,
      video: false,
      canOffer: true,
      conference: false, // Conferencing always starts false.
    };
    if (!conn.connections[socketListId] || !conn.connections[socketListId].pc) {
      return;
    }
    const options = setupConnection(wrtc, socketListId, mm, addStream, removedStream);
    const socketId = socketListId;
    if (
      conn.connections[socketId].canOffer &&
      mm !== RELATIONSHIP.NONE &&
      (socketId > userSelf.id || forceConnect === true)
    ) {
      logger.info(`[SOCKET-CONNECT] Offering connection: ${getName(wrtc, socketListId)}`);
      WebRTC.offerConnection(conn, socketId, options);
    }
  },
  offerConnection: (conn: Connection, socketId: string, options: RTCOfferOptions, failure?: Function): void => {
    if (!conn.connections[socketId]) {
      return;
    }
    conn.connections[socketId].canOffer = false;
    conn.connections[socketId].pc?.createOffer(options).then(function(description) {
      conn.connections[socketId].pc
        ?.setLocalDescription(description)
        .then(function() {
          conn.socket.emit(
            'signal',
            socketId,
            JSON.stringify({
              sdp: conn.connections[socketId].pc?.localDescription,
            })
          );
        })
        .catch((e: Error) => {
          if (failure) {
            failure();
          }
          console.log(e);
        });
    });
  },
  listen: (conn: Connection, locationUpdate: (socketId: string, x: number, y: number) => void): void => {
    const socket = conn.socket;
    socket.on('location-update', locationUpdate);
  },
  updateLocation: (conn: Connection, x?: number, y?: number, isSelf?: boolean): void => {
    const socket = conn.socket;
    if (x !== undefined && y !== undefined) {
      socket.emit('location-set', x, y, isSelf);
    } else {
      socket.emit('location-force');
    }
  },
  updateColor: (conn: Connection, name: string): void => {
    const socket = conn.socket;
    socket.emit('color-set', name);
  },
  updateName: (conn: Connection, name: string): void => {
    const socket = conn.socket;
    socket.emit('name-set', name);
  },
  updateStream: (conn: Connection, type: StreamAttribute, status: boolean): void => {
    const socket = conn.socket;
    socket.emit('stream-set', type.toString(), status ? '1' : '0');
  },
  summon: (conn: Connection, user: User): void => {
    const socket = conn.socket;
    socket.emit('summon', user.id, user.name);
  },
  subscribeToMessages: (
    conn: Connection,
    listener: (socketId: string, important: boolean, message: string) => void,
    summonListener: (socketId: string, message: string) => void
  ): void => {
    conn.socket.on('chat-receive', listener);
    conn.socket.on('summon', summonListener);
  },
  sendMessage: (conn: Connection, message: string): void => {
    conn.socket.emit('chat-send', message);
  },
  unsubscribeToMessages: (
    conn: Connection,
    listener: (socketId: string, important: boolean, message: string) => void,
    summonListener: (socketId: string, message: string) => void
  ): void => {
    conn.socket.removeListener('chat-receive', listener);
    conn.socket.removeListener('summon', summonListener);
  },
};
export default WebRTC;
