import { toast } from 'react-toastify';
import { AnalyticsAction, AnalyticsCategory, logEvent } from '../../Analytics';
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import WebRTC, { WebRTCHelper, Connection, User, StreamAttribute, generatePeerConnectionConfig } from './WebRTC';

function gotMessageFromServer(wrtc: WebRTCHelper, fromId: string, message: string): void {
  const conn = wrtc.conn;
  if (!conn) {
    return;
  }
  if (fromId !== conn.socket.id) {
    if (!conn.screenConnections[fromId]) {
      if (wrtc.screenStream == null) {
        return;
      }
      conn.screenConnections[fromId] = new RTCPeerConnection(generatePeerConnectionConfig(wrtc));

      conn.screenConnections[fromId].onicecandidate = (event): void => {
        if (event.candidate != null) {
          conn.socket.emit('screensharesignal', fromId, JSON.stringify({ ice: event.candidate }));
        }
      };
      wrtc.screenStream.getTracks().forEach(track => conn.screenConnections[fromId].addTrack(track));
    }
    // Parse the incoming signal
    const signal = JSON.parse(message);
    // Make sure it's not coming from yourself
    if (signal.sdp) {
      // TODO(kevin): change to awaits
      // Let me make sure the tracks are in place
      conn.screenConnections[fromId]
        .setRemoteDescription(new RTCSessionDescription(signal.sdp))
        .then(function() {
          if (signal.sdp.type === 'offer') {
            conn.screenConnections[fromId]
              .createAnswer()
              .then(function(description) {
                conn.screenConnections[fromId]
                  .setLocalDescription(description)
                  .then(function() {
                    conn.socket.emit(
                      'screensharesignal',
                      fromId,
                      JSON.stringify({
                        sdp: conn.screenConnections[fromId].localDescription,
                      })
                    );
                  })
                  .catch(e => console.log(e));
              })
              .catch(e => console.log(e));
          }
        })
        .catch(e => console.log(e));
    }
    if (signal.ice) {
      conn.screenConnections[fromId].addIceCandidate(new RTCIceCandidate(signal.ice)).catch(e => console.log(e));
    }
  }
}

const WebRTCScreenShare = {
  initialScreenShare: (wrtc: WebRTCHelper): void => {
    if (wrtc?.conn != null) {
      const socket = wrtc.conn.socket;
      if (socket != null) {
        socket.removeListener('screensharesignal');
        socket.on('screensharesignal', async (fromId: string, message: string) => {
          await new Promise(resolve => setTimeout(resolve, 100)); // .1 sec
          gotMessageFromServer(wrtc, fromId, message);
        });
      }
    }
  },
  destroyScreenShare: (wrtc: WebRTCHelper): void => {
    if (wrtc?.conn != null) {
      const socket = wrtc.conn.socket;
      if (socket != null) {
        socket.removeListener('screensharesignal');
      }
    }
  },
  updateScreenShare: (
    wrtc: WebRTCHelper,
    shouldShare: boolean,
    shareAudio: boolean,
    getDisplayMediaSuccess: (status: boolean, stream?: MediaStream) => void
  ): void => {
    if (!shouldShare) {
      // Disable streaming
      if (wrtc.screenStream != null) {
        wrtc.screenStream.getTracks().map(t => t.stop());
        wrtc.screenStream = null;
        if (wrtc.conn) {
          Object.values(wrtc.conn.screenConnections).map(pc => pc.close());
          wrtc.conn.screenConnections = {};
        }
        logEvent(AnalyticsCategory.Screensharing, AnalyticsAction.End);
      }
      if (wrtc.conn) {
        WebRTC.updateStream(wrtc.conn, StreamAttribute.SCREENSHARE, false);
      }
      getDisplayMediaSuccess(false);
      return;
    }
    if (
      // @ts-ignore
      navigator.mediaDevices.getDisplayMedia
    ) {
      navigator.mediaDevices
        // @ts-ignore
        .getDisplayMedia({
          video: {
            // @ts-ignore
            cursor: 'always',
          },
          audio: shareAudio,
        })
        .then((stream: MediaStream) => {
          wrtc.screenStream = stream;
          if (wrtc.conn) {
            WebRTC.updateStream(wrtc.conn, StreamAttribute.SCREENSHARE, true);
          }
          getDisplayMediaSuccess(true, stream);
          logEvent(AnalyticsCategory.Screensharing, AnalyticsAction.Start);
        })
        .catch(() => {
          getDisplayMediaSuccess(false);
        });
    }
  },
  listenToScreenShare: (
    wrtc: WebRTCHelper,
    user: User,
    addStream: (track: MediaStreamTrack) => void,
    endStream: () => void
  ): void => {
    const conn = wrtc.conn;
    if (!conn) {
      return;
    }
    if (conn.screenConnections[user.id]) {
      conn.screenConnections[user.id].close();
    }
    logEvent(AnalyticsCategory.Screensharing, AnalyticsAction.Joined);
    const pc = new RTCPeerConnection(generatePeerConnectionConfig(wrtc));
    conn.screenConnections[user.id] = pc;
    toast.success('Loading screenshare... (may take a couple of seconds)');
    //Wait for their ice candidate
    pc.onicecandidate = (event): void => {
      if (event.candidate != null) {
        conn.socket.emit('screensharesignal', user.id, JSON.stringify({ ice: event.candidate }));
      }
    };
    //Wait for their audio stream
    pc.ontrack = (event): void => {
      addStream(event.track);
    };
    pc.onconnectionstatechange = (): void => {
      const innerpc = conn.screenConnections[user.id];
      // TODO(kevin): Disconnected is not the best way of discovering disconnect
      if ((innerpc && innerpc.connectionState === 'closed') || innerpc.connectionState === 'disconnected' || !innerpc) {
        logEvent(AnalyticsCategory.Screensharing, AnalyticsAction.End);
        endStream();
        WebRTC.updateStream(conn, StreamAttribute.SCREENSHARE, false);
        if (innerpc) {
          innerpc.close();
          delete conn.screenConnections[user.id];
        }
      }
    };
    pc.createOffer({
      offerToReceiveVideo: true,
      offerToReceiveAudio: true,
    }).then(function(description) {
      conn.screenConnections[user.id]
        .setLocalDescription(description)
        .then(function() {
          conn.socket.emit(
            'screensharesignal',
            user.id,
            JSON.stringify({
              sdp: conn.screenConnections[user.id].localDescription,
            })
          );
        })
        .catch((e: Error) => {
          console.log(e);
        });
    });
  },
};
export default WebRTCScreenShare;
