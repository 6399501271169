import React, { createContext, ReactNode, useState, useEffect, SetStateAction, Dispatch, useCallback } from 'react';
import { logger } from '../../Analytics';
import { Callback } from '../../types';

import WebRTC, {
  WebRTCHelper,
  StreamAttribute,
  RemoveStreamFunction,
  AddStreamFunction,
  Connection,
  User,
  Streams,
  StreamsObject,
  MetaObject,
} from '../WebRTC/WebRTC';
import VartyListener from './listener';
import useBackend, { Backend } from './useBackend';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  wrtc: WebRTCHelper | null;
  backend: Backend;
  sound: {
    stream: MediaStream | null;
    streamVideoElement: HTMLVideoElement | null;
    audioOn: boolean;
    setAudioOn: Dispatch<SetStateAction<boolean>>;
    videoOn: boolean;
    setVideoOn: Dispatch<SetStateAction<boolean>>;
    videoEnabled: boolean;
  };
  otherFunctions: {
    userUpdate: (conn: Connection, user?: User, isForce?: boolean) => void;
    addStream: AddStreamFunction;
    clearAllStreams: () => void;
    removedStream: RemoveStreamFunction;
    initWebRTC: () => void;
  };
  users: { [uid: string]: User };
  streams: Streams;
  streamsMeta: MetaObject;
  onDisconnect: Callback;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  children: ReactNode;
}

export function VideoProvider({ children }: VideoProviderProps) {
  const [wrtc, setWrtc] = useState<WebRTCHelper | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  // users
  // eslint-disable-next-line
  const [users, setUsers] = useState<{ [id: string]: User }>({});
  const [streams, setStreams] = useState<Streams>({});
  const [streamsMeta, setStreamsMeta] = useState<MetaObject>({
    type: 'noop',
    socketID: '',
    user: null,
    object: null,
  });
  const [audioOn, setAudioOn] = useState(true);
  const [videoOn, setVideoOn] = useState(true);
  const [streamVideoElement, setStreamVideoElement] = useState<HTMLVideoElement | null>(null);

  const clearAllStreams = useCallback(() => {
    setStreamsMeta({
      type: 'clear',
      user: null,
      object: null,
      socketID: '',
    });
    setStreams({});
  }, [setStreams, setStreamsMeta]);

  // TODO(kevin): extra baggage move away
  const addStream = useCallback(
    (user: User | null, socketId: string, track: MediaStreamTrack, isConference: boolean): void => {
      let volume = 1;
      if (user != null) {
        // volume = calculateVolume(user.distance);
        // TODO(kevinfang): change when scaling
        volume = 1;
      }
      setStreams((s: Streams) => {
        const newObject: StreamsObject =
          s[socketId] != null
            ? s[socketId]
            : {
                stream: new MediaStream(),
                conferenceStream: new MediaStream(),
                volume: volume,
                isSpeaking: false,
                conference: isConference,
              };
        newObject.conference = isConference;
        if (isConference) {
          newObject.conferenceStream.addTrack(track);
          // When there are two video/audio tracks we need to chose the latest!
          if (newObject.conferenceStream.getVideoTracks().length > 1) {
            newObject.conferenceStream.removeTrack(newObject.conferenceStream.getVideoTracks()[0]);
          }
          if (newObject.conferenceStream.getAudioTracks().length > 1) {
            newObject.conferenceStream.removeTrack(newObject.conferenceStream.getAudioTracks()[0]);
          }
        } else {
          newObject.stream.addTrack(track);
        }
        if (user) {
          setStreamsMeta({
            type: 'add',
            socketID: socketId,
            user: user,
            object: newObject,
          });
        } else {
          logger.info('[EXTRA] User does not exist when setting streams');
        }
        // TODO(kevinfang): add meta
        return {
          ...s,
          [socketId]: newObject,
        };
      });
    },
    [setStreams, setStreamsMeta]
  );

  const removedStream = useCallback(
    (socketId: string, removedUser?: boolean, user?: User, conferenceCheck?: boolean): void => {
      setStreams((s: Streams) => {
        if (conferenceCheck == null) {
          delete s[socketId];
        }
        //TODO(kevinfang): re-add meta
        return { ...s };
      });
      if (wrtc && wrtc.conn != null && removedUser) {
        setStreamsMeta({
          type: 'remove',
          socketID: socketId,
          user: user || null,
          object: null,
        });
        setUsers(wrtc.conn.users);
        // TODO(kevinfang): not doing throttle
      }
    },
    [wrtc, setStreams, setStreamsMeta, setUsers]
  );
  const backend = useBackend(wrtc, removedStream, clearAllStreams);
  const userUpdate = useCallback(
    (conn, user?: User, isForce?: boolean) => {
      // TODO(kevinfang): throttle
      setUsers(conn.users);
      if (user) VartyListener.notifyUser(user);
    },
    [setUsers]
  );
  // TODO(ethan): or kevinfang -> let's make this into it's on useSound.tsx and have all the logic in there

  /**** START MOVE *****/
  const setNewStream = useCallback(
    (mystream: MediaStream): void => {
      mystream.getTracks().forEach(t => {
        if (t.kind !== 'audio') t.enabled = videoOn;
      });
      mystream.getTracks().forEach(t => {
        if (t.kind === 'audio') t.enabled = audioOn;
      });
      setStream(mystream);
    },
    [audioOn, videoOn]
  );

  useEffect(() => {
    if (stream != null) {
      stream.getTracks().forEach(t => {
        if (t.kind !== 'audio') t.enabled = videoOn;
      });
    }
    if (wrtc) {
      wrtc.jitsiLocalTracks
        .filter(t => !t.isAudioTrack())
        .forEach((t: JitsiMeetJS.JitsiLocalTrack) => {
          if (videoOn) {
            t.unmute();
          } else {
            t.mute();
          }
        });
    }
    if (wrtc?.conn != null) {
      WebRTC.updateStream(wrtc?.conn, StreamAttribute.VIDEO, videoOn);
    }
  }, [stream, videoOn, wrtc]);

  useEffect(() => {
    if (stream != null) {
      stream.getTracks().forEach(t => {
        if (t.kind === 'audio') t.enabled = audioOn;
      });
    }
    if (wrtc) {
      wrtc.jitsiLocalTracks
        .filter(t => t.isAudioTrack())
        .forEach((t: JitsiMeetJS.JitsiLocalTrack) => {
          if (audioOn) {
            t.unmute();
          } else {
            t.mute();
          }
        });
    }
    if (wrtc?.conn != null) {
      WebRTC.updateStream(wrtc.conn, StreamAttribute.AUDIO, audioOn);
    }
  }, [audioOn, stream, wrtc]);
  /**** END MOVE *****/

  const initWebRTC = useCallback(() => {
    if (wrtc) {
      WebRTC.updateMediaSettings(
        wrtc,
        true,
        setNewStream,
        () => {},
        () => {},
        () => {}
      );
      wrtc.setStreamVideoElement = video => setStreamVideoElement(video);
    }
  }, [wrtc, setNewStream, setStreamVideoElement]);

  useEffect(() => {
    setWrtc(mywrtc => {
      if (mywrtc) {
        mywrtc.setStreamVideoElement = setStreamVideoElement;
        return mywrtc;
      } else {
        return WebRTC.init(setStreamVideoElement);
      }
    });
  }, []);

  // Register onError and onDisconnect callback functions.
  // useHandleRoomDisconnectionErrors(room, onError);
  // useHandleTrackPublicationFailed(room, onError);
  // useHandleOnDisconnect(room, onDisconnect);

  return (
    <VideoContext.Provider
      value={{
        backend,
        sound: {
          stream: wrtc?.localStream || null,
          streamVideoElement,
          audioOn,
          setAudioOn,
          videoOn,
          setVideoOn,
          videoEnabled: wrtc?.videoEnabled || false,
        },
        users,
        streams,
        streamsMeta,
        otherFunctions: {
          addStream,
          clearAllStreams,
          removedStream,
          userUpdate,
          initWebRTC,
        },
        wrtc,
        onDisconnect: () => {},
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}
