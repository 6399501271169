import React from 'react';
import ReactDOM from 'react-dom';
// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import back from '../../assets/img/background.jpg';

/* ------------------------------------------------------- TODO/Notes -------------------------------------------------------
	TODO: Style the pop up
	TODO: Make the PopUp's background opaque so that the viewer will be able to roughly see the background
		NOTE: I think this has something to do with setting the z-index, but I cant get it to work
					All current attempts just make the whole thing transparent, meaning that you can see through the
					Pop Up
	TODO: Merge button creation and popUp creation together
	------------------------------------------------------- Using this -------------------------------------------------------
	To make a pop up you need to first create a state hook toggling/un-toggling the view of the pop up: 
		i.e. const [showPopUp, setShowPopUp] = useState(false);
	Now the pop up takes in three things, the state value, the state function, and the children. Passing in children is optional, but
	it will lead to a totally blank pop up. Without controlling the state there is no way to control the pop up. Create the pop up in the 
	following manner:
		<PopUp isShowing={showPopUp} hide={setShowPopUp}> children </PopUp> 
	
	Make sure you have a button that you can click to toggle the pop up in the first place. i.e.
		<Button variant="contained" name="pictureTaker" onClick={() => setShowPopUp(!showPopUp)}>
      Take a Picture using a webcam
    </Button>
  Then put the pop up underneath the button.
  Remember, name the state function whatever you want. Different names mean you can have multiple pop ups on the same page.
*/

const useStyles = makeStyles({
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    ZIndex: '1040',
    width: '100vw',
    height: '100vh',
    background: `url(${back}) no-repeat center`,
    backgroundSize: 'cover',
  },
  wrapper: {
    position: 'fixed',
    top: ' 0',
    left: ' 0',
    width: '100%',
    height: '100%',
    'overflow-x': 'hidden',
    'overflow-y': 'auto',
    outline: '0',
  },
  popUp: {
    background: 'white',
    position: 'relative',
    margin: '1.75rem auto',
    'border-radius': '10px',
    width: '50%',
    maxWidth: 500,
    minWidth: 450,
    maxHeight: 600,
    opacity: '1',
  },
  popUpHeader: {
    display: 'flex',
    'justify-content': 'flex-end',
    opacity: '1',
  },
});

export default function PopUp(props: any) {
  const classes = useStyles();

  // console.log(props.serverResponse);
  // !props.serverResponse
  return props.isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className={classes.overlay}>
            <div className={classes.wrapper}>
              <div className={classes.popUp}>
                <div className={classes.popUpHeader}></div>
                <div> {props.children} </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
}
