import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
// import { URL } from 'url';
import { logger } from '../../Analytics';
import * as VartyLogo from '../../assets/img/failedLoad.png';
import { pathToBarRoomPlayer } from './firebasePaths';
import { setUserRoomName } from './firebaseRoomActions';

const stylishHubData = {
  bounds: [470, 574, 106, 1336, 1902, 1334, 1542, 574],
  miniMapScale: [0.09],
  purpose: 'hub',
  roomName: 'stylishHub',
  scale: [0.5, 0.5],
  spawn: [1000, 580],
  visibility: 'public',
};

var cache: { [id: string]: Array<number> } = {};

const newUser = {
  nickname: '',
  photoUrl: '',
  age: '',
  height: '',
  location: '',
  education: '',
  gender: '',
  uid: '',
  avatar: 'blue',
  friends: [],
  requests: {},
  displayName: '',
  object: {},
  hubDesign: '',
} as NewUserType;

const newHistory = {
  map: {},
  list: [],
  uid: '',
};

const newRecentHistory = {
  list: [],
  uid: '',
};

var empty: number[];
empty = [];

const newRoom = {
  uid: '',
  name: '',
  description: '',
};

const newInvite = {
  pos: empty,
  from: '',
  to: '',
  state: 0,
};

const prepareDocForCreate = (doc: any) => {
  // console.log('called prepare doc for create');
  // timestamps
  doc.createdBy = firebase.auth().currentUser ? firebase.auth().currentUser?.uid : null;
  doc.createdOn = firebase.firestore.Timestamp.now();

  return doc;
};

const prepareDocForUpdate = (doc: any) => {
  // console.log('called prepare doc for update');
  // timestamps
  doc.updatedBy = firebase.auth().currentUser ? firebase.auth().currentUser?.uid : null;
  doc.updatedOn = firebase.firestore.Timestamp.now();
  return doc;
};

const getUser = (uid: string, caller = 'default'): Promise<any> => {
  // console.log('called getUser CALLER', caller);
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get()
      .then(doc => {
        if (!doc.exists) reject('User does not exist 2');
        else resolve(doc.data());
      })
      .catch(error => {
        reject(error);
      });
  });
};

const getRoom = (uid: string): Promise<any> => {
  // console.log('called get room');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('publicRooms')
      .doc(uid)
      .get()
      .then(doc => {
        if (!doc.exists) reject('Room does not exist');
        else resolve(doc.data());
      })
      .catch(error => {
        reject(error);
      });
  });
};

const updateCache = (): Promise<any> => {
  // console.log('called update cache');
  cache = {};
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('publicRooms')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const room = doc.data();
          if (!room.private) cache[doc.id] = room.pos;
        });
      })
      .then(res => {
        resolve(cache);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const getUsers = (users: string[]): Promise<any> => {
  // console.log('called get users');
  cache = {};
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        let ret: any[] = [];
        querySnapshot.forEach(doc => {
          let val = doc.data();
          if (users.includes(val.uid)) {
            ret.push(val);
          }
        });
        resolve(ret);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const getAllPublicRooms = (): Promise<any> => {
  // console.log('called get all public rooms');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('publicRooms')
      .get()
      .then(res => {
        let rooms: any[] = [];
        res.forEach(doc => {
          const room = doc.data();
          rooms.push(room);
        });
        resolve(rooms.reverse());
      })
      .catch(e => {
        reject(e);
      });
  });
};

const getAllHubStyles = (): Promise<any> => {
  // console.log('called get all hub styles');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('hubStyles')
      .get()
      .then(res => {
        let rooms: any[] = [];
        res.forEach(doc => {
          const room = doc.data();
          rooms.push(room);
        });
        resolve(rooms.reverse());
      })
      .catch(e => {
        reject(e);
      });
  });
};

const getPublicRoomData = (uid: string, userId: string): Promise<string | boolean> => {
  // console.log('called get public room data');
  return new Promise(resolve => {
    return firebase
      .firestore()
      .collection('publicRooms')
      .doc(uid)
      .get()
      .then(res => {
        const data = res.data();
        if (!data) resolve(false);
        else {
          setUserRoomName(userId, data.name);
          const auth = data.verification;
          resolve(auth ? auth : 'none');
        }
      })
      .catch(e => {
        resolve(false);
      });
  });
};

const addUser = (uid: string, displayName: string) => {
  // console.log('called add user');
  let user = newUser;
  newUser.uid = uid;
  newUser.displayName = displayName;
  newUser.nickname = displayName;
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .set(prepareDocForCreate(user))
      .then(res => {
        addHistory(uid);
        resolve(user);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const getHistory = (uid: string): Promise<any> => {
  // console.log('called get history');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('histories')
      .doc(uid)
      .get()
      .then(doc => {
        if (!doc.exists) reject('History does not exist');
        else resolve(doc.data());
      })
      .catch(error => {
        reject(error);
      });
  });
};

const addHistory = (uid: string) => {
  // console.log('called add history');
  let hist = newHistory;
  hist.uid = uid;
  addRecentHistory(uid);
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('histories')
      .doc(uid)
      .set(prepareDocForCreate(hist))
      .then(res => {
        resolve(hist);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const checkAccessToken = (uid: string, roomId: string): Promise<boolean> => {
  // console.log('called check access token');
  let date = new Date();
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('tokens')
      .doc(uid)
      .get()
      .then(doc => {
        if (!doc.exists) resolve(false);
        else {
          let obj = doc.data();
          if (obj?.date) {
            resolve(obj?.roomId === roomId && obj?.date.seconds > date.getSeconds());
          } else {
            if (obj?.singleUse) removeAccessToken(uid);
            resolve(obj?.roomId === roomId);
          }
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

const addAccessToken = (uid: string, data: any) => {
  // console.log('called add access token');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('tokens')
      .doc(uid)
      .set(prepareDocForCreate(data))
      .then(res => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const removeAccessToken = (uid: string): Promise<any> => {
  // console.log('called remove access token');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('tokens')
      .doc(uid)
      .delete()
      .then(() => {
        console.log('Token Deleted');
      })
      .catch(() => {
        console.log('Token Deletion Error');
      });
  });
};

const removePremiumToken = (userId: string): Promise<any> => {
  // console.log('called remove premium tokens');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('premiumTokens')
      .doc(userId)
      .delete()
      .then(() => {
        console.log('Token Deleted');
      })
      .catch(() => {
        console.log('Token Deletion Error');
      });
  });
};

const addPremiumToken = (userId: string, data: any) => {
  // console.log('called add premium tokens');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('premiumTokens')
      .doc(userId)
      .set(prepareDocForCreate(data))
      .then(res => {
        resolve(data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const checkPremiumToken = (uid: string, user: any): Promise<boolean> => {
  // console.log('called check premium tokens');
  let date = new Date();
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('premiumTokens')
      .doc(user?.uid)
      .get()
      .then(doc => {
        if (!doc.exists) reject('token does not exist');
        else {
          let obj = doc.data();
          removePremiumToken(user?.uid);
          if (obj?.success === uid) {
            user?.badges ? (user['badges']['premium'] = date) : (user['badges'] = { premium: date });
            updateUser(user.uid, user);
            resolve(true);
          } else {
            resolve(false);
          }
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

const addRecentHistory = (uid: string) => {
  // console.log('called add recent history');
  let hist = newRecentHistory;
  hist.uid = uid;
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('recentHistories')
      .doc(uid)
      .set(prepareDocForCreate(hist))
      .then(res => {
        resolve(hist);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const addRoom = (uid: string, name: string, description: string) => {
  // console.log('called add room');
  let room = newRoom;
  room.uid = uid;
  room.name = name;
  room.description = description;
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('publicRooms')
      .doc(uid)
      .set(prepareDocForCreate(room))
      .then(res => {
        resolve(room);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const updateRoom = (uid: string, data: object): Promise<any> => {
  // console.log('called update room');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('publicRooms')
      .doc(uid)
      .update(prepareDocForUpdate(data))
      .then(res => {
        resolve(true);
      })
      .catch(e => {
        console.log('error' + e);
        reject(e);
      });
  });
};

const addInvite = (inviter: string, invitee: string, pos: number[]) => {
  // console.log('called add invite');
  let invite = newInvite;
  invite.from = inviter;
  invite.to = invitee;
  invite.pos = pos;
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('invites')
      .doc(inviter)
      .set(prepareDocForCreate(invite))
      .then(res => {
        resolve(invite);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const addVerifiedEmailData = (email: string, data: any) => {
  // console.log('called add verified email');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('verifiedEmails')
      .doc(email)
      .set(prepareDocForCreate(data))
      .then(res => {
        resolve(true);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const getVerifiedEmailData = (email: string): Promise<any> => {
  // console.log('called get verified email data');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('verifiedEmails')
      .doc(email)
      .get()
      .then(doc => {
        if (!doc.exists) resolve(null);
        else resolve(doc.data());
      })
      .catch(error => {
        reject(error);
      });
  });
};

const updateInvite = (uid: string, data: object): Promise<any> => {
  // console.log('called update invite');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('invites')
      .doc(uid)
      .update(prepareDocForUpdate(data))
      .then(res => {
        resolve(true);
      })
      .catch(e => {
        console.log('error' + e);
        reject(e);
      });
  });
};

const updateUser = (uid: string, data: object): Promise<any> => {
  // console.log('\n\n\n\n\ncalled update user');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .update(prepareDocForUpdate(data))
      .then(res => {
        resolve(true);
      })
      .catch(e => {
        console.log('error in update user: ' + e);
        reject(e);
      });
  });
};

const uploadImage = (
  uid: string,
  img: Blob | any,
  specialURL: any = '',
  contentType: string = '',
  returnSmallURL: boolean = false
): Promise<any> => {
  // console.log('called upload image');
  return new Promise((resolve, reject) => {
    const epochDate = new Date();
    const seconds: string = Math.round(epochDate.getTime() / 1000).toString();
    const metadata = { contentType: contentType || img.fileType, pictureTime: seconds };
    let url = specialURL || `images/${uid}/prof.jpg`;
    let smallURL = specialURL ? `images/${uid}/thumbnails/userImages/currImage_100x100.png` : '';
    let data: Blob = img instanceof Blob ? img : new Blob(img);

    // TODO: Put a milisecond time stamp in the current image to ensure that the image is the same across loads.

    let smallUpload = firebase
      .storage()
      .ref()
      .child(smallURL);

    let upload = firebase
      .storage()
      .ref()
      .child(url);
    upload
      .put(data, { customMetadata: metadata })
      .then(res => {
        if (res.state === 'success') {
          upload.getDownloadURL().then(downloadURL => {
            if (!returnSmallURL) resolve(downloadURL); // If there is no need to get the smallURL, just resolve the promise here. This is the general case.
            smallUpload
              .getMetadata()
              .then(smallDownloadMetadata => {
                // console.log("🚀 ~ file: firebaseHelper.ts ~ line 602 ~ upload.getDownloadURL ~ smallDownloadMetadata", smallDownloadMetadata)
                if (smallDownloadMetadata.customMetadata.pictureTime === seconds) {
                  logger.info('[UPLOAD IMAGE] using smallImage');
                  smallUpload.getDownloadURL().then(smallDownloadURL => {
                    resolve({ downloadURL, smallDownloadURL });
                  });
                } else {
                  logger.info('[UPLOAD IMAGE] using original image');
                  resolve({ downloadURL, smallDownloadURL: downloadURL });
                }
              })
              .catch(e => {
                logger.info('[UPLOAD IMAGE] using original image (catch)');
                resolve({ downloadURL, smallDownloadURL: downloadURL });
              });
          });
        }
      })
      .catch(e => {
        logger.error(`[UPLOAD IMAGE] ERROR (${e})`);
        reject(e);
      });
  });
};

const addFriendHelper = (toUser: any, fromUser: any) => {
  // console.log('called add friend helper');
  if (!toUser || !fromUser) return;
  fromUser.friends ? fromUser.friends.push(toUser.uid) : (fromUser.friends = [toUser.uid]);
  toUser.friends ? toUser.friends.push(fromUser.uid) : (toUser.friends = [fromUser.uid]);
  updateUser(toUser.uid, toUser);
  updateUser(fromUser.uid, fromUser);
};

function addFriend(fromUserUid: any, toUserUid: string) {
  // console.log('\ncalled add friend');
  let toUser: any = null;
  let fromUser: any = null;
  firebase
    .firestore()
    .collection('users')
    .doc(toUserUid)
    .get()
    .then(doc => {
      toUser = doc.data();
      addFriendHelper(toUser, fromUser);
    });
  firebase
    .firestore()
    .collection('users')
    .doc(fromUserUid)
    .get()
    .then(doc => {
      fromUser = doc.data();
      addFriendHelper(toUser, fromUser);
    });
}

function changeName(userId: string, roomId: string, data: string) {
  // console.log('called change name');
  let user: any = null;
  firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .get()
    .then(doc => {
      user = doc.data();
      user.nickname = data;
      user.displayName = data;
      updateUser(userId, user);
    });
  const PATH = pathToBarRoomPlayer(roomId, userId);
  firebase
    .database()
    .ref(PATH)
    .update({ nickname: data });
}
const removeFriendHelper = (toUser: any, fromUser: any) => {
  // console.log('called remove friend helper');
  if (!toUser || !fromUser) return;
  let fromUserFriends = fromUser.friends;
  let fromUserFriendsNew: string[] = [];
  let toUserFriends = toUser.friends;
  let toUserFriendsNew: string[] = [];
  fromUserFriends.forEach((item: string) => {
    if (item === toUser.uid) return;
    fromUserFriendsNew.push(item);
  });
  toUserFriends.forEach((item: string) => {
    if (item === fromUser.uid) return;
    toUserFriendsNew.push(item);
  });
  fromUser.friends = fromUserFriendsNew;
  toUser.friends = toUserFriendsNew;
  updateUser(toUser.uid, toUser);
  updateUser(fromUser.uid, fromUser);
};

function removeFriend(fromUserUid: any, toUserUid: string) {
  // console.log('called remove friend');
  let toUser: any = null;
  let fromUser: any = null;
  firebase
    .firestore()
    .collection('users')
    .doc(toUserUid)
    .get()
    .then(doc => {
      toUser = doc.data();
      removeFriendHelper(toUser, fromUser);
    });
  firebase
    .firestore()
    .collection('users')
    .doc(fromUserUid)
    .get()
    .then(doc => {
      fromUser = doc.data();
      removeFriendHelper(toUser, fromUser);
    });
}

async function downloadImage(uid: string, file: string, folder: string = 'images'): Promise<any> {
  // console.log('called download image');
  const url = `${folder}/${uid}/${file}.png`;
  let result = await downloadHelper(url);
  console.log('IMAGE', result);
  if (!result) result = VartyLogo.toString();
  return result;
}

async function downloadBackground(folder: string, file: string): Promise<any> {
  // console.log('called download background');
  const url = `${folder}/${file}.png`;
  console.log(url);

  let result = await downloadHelper(url);
  // console.log('BACKGROUND', result);
  if (!result) {
    result = downloadBackground('backgrounds', 'stylishhub');
  }
  return result;
}

async function downloadHelper(url: string): Promise<any> {
  // console.log('called download helper');
  // console.log('THEURL', url);
  return await new Promise((resolve, reject) => {
    // console.log('Inside the promise');
    let download = firebase
      .storage()
      .ref()
      .child(url);
    download
      .getDownloadURL()
      .then(function(dataURL) {
        // var returnThis: any;
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function() {
          var blob = xhr.response;

          resolve(blob);
        };
        xhr.open('GET', dataURL);
        xhr.send();
        // resolve(xhr.onload())
      })
      .catch(e => {
        console.log('Could not reach server. Please refresh. Error Code DLI-DOWN-P-001'); // download image download image promise, first in func
        resolve(false);
      });
  }).catch(e => {
    console.log('Error Code DLI-DOWN-002');
  });
}

const getHubStylesBackgroundData = (roomID: string): Promise<BackgroundData> => {
  // console.log('called get hub styles background data');
  if (roomID === 'drink-wisconsinbly') roomID = 'dw';
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('backgroundData')
      .doc(roomID)
      .get()
      .then(doc => {
        resolve(doc.data() as BackgroundData);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const getUserBackgroundData = (userId: string): Promise<BackgroundData> => {
  // console.log('called get user background data');
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('backgroundData')
      .doc('users')
      .collection(userId)
      .doc('current')
      .get()
      .then(doc => {
        console.log(doc.data());
        if (!doc.exists) {
          // console.log('failed');
          let backup = stylishHubData;
          console.log(backup);
          resolve(backup);
          //reject(`Background ${roomID} does not exist 3`);
        } else {
          console.log(doc.data());
          resolve(doc.data() as BackgroundData);
        }
      })
      .catch(error => {
        // console.log('getBackgroundData error', userId, error);
        reject(error);
      });
  });
};

/**
 *
 * @param roomName string, name of the room
 * @param roomUid  string, uid of the room (Ethan P's proud creation)
 * @param visibility string, should be public, private, friends
 * @param bounds number[], bounds for collision
 * @param scale number[], scaling the background
 * @param spawn number[], where should people spawn?
 * @param miniMapScale  number[], how big should the minimap be?
 */
const createBackground = (
  collectionPath: string,
  roomName: string,
  roomUid: string,
  visibility: string,
  bounds: number[],
  scale: number[],
  spawn: number[],
  miniMapScale: number[],
  purp: string
): Promise<BackgroundData> => {
  // console.log('called create bacgrkound');
  const backgroundData: BackgroundData = {
    roomName: roomName,
    visibility: visibility,
    bounds: bounds,
    scale: scale,
    spawn: spawn,
    miniMapScale: miniMapScale,
    purpose: purp,
  };

  // console.log('backgroundData: ', backgroundData);
  // console.log('within the creation of Background');

  return new Promise((resolve, reject) => {
    // console.log('promise?????');
    return firebase
      .firestore()
      .collection(collectionPath)
      .doc('current')
      .set(backgroundData, { merge: true }) // Allow for merges on the data.
      .then(result => {
        // console.log('result: ', result); // result undefined
        resolve(backgroundData);
      })
      .catch(e => {
        // console.log('ERROR WILL ROBINSON');
        reject(e);
      });
  });
};

const storeLanding = (data: any) => {
  // console.log('called store landing');
  // We need to create the day
  const year = new Date();
  const month = year.getMonth() < 8 ? '0' + (year.getMonth() + 1).toString() : (year.getMonth() + 1).toString();
  const day = year.getDate() < 9 ? '0' + year.getDate().toString() : year.getDate().toString();

  // console.log(year.getDate());

  // Time to create the upload path
  const YMD = `${year.getFullYear()}-${month}-${day}`;
  const fullPath = `roomTracking/${YMD}/LandingPage`;
  const seconds: string = Math.round(year.getTime() / 1000).toString();

  // console.log(seconds);
  // console.log('YMD: ', YMD);
  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection(fullPath)
      .doc(seconds)
      .set(data)
      .then((doc: any) => {
        // console.log('data: ', data);
        // console.log('doc: ', doc); Doc is undefined
        resolve('Uploaded');
        // console.log("This might not do anything?");
      })
      .catch((e: any) => {
        console.log('e: ', e);
      });
  });
};

const createPublicRoom = (
  description: string,
  key: number,
  listed: boolean,
  name: string,
  uid: string,
  verification: string | null
): Promise<RoomType> => {
  // console.log('called create public room');
  const publicRoom: RoomType = {
    description: description,
    key: key,
    listed: listed,
    name: name,
    uid: uid,
    verification: verification,
  };

  // console.log('publicRoom: ', publicRoom);

  return new Promise((resolve, reject) => {
    return firebase
      .firestore()
      .collection('publicRooms')
      .doc(name)
      .set(publicRoom, { merge: true })
      .then(result => {
        console.log('result:', result);
        resolve(publicRoom);
      })
      .catch(e => {
        // console.log('Error with creating public room. firebaseHelper:789');
        console.log('e: ', e);
      });
  });
};

export {
  changeName,
  getAllPublicRooms,
  getAllHubStyles,
  getPublicRoomData,
  addFriend,
  removeFriend,
  getHistory,
  updateRoom,
  addVerifiedEmailData,
  getVerifiedEmailData,
  addAccessToken,
  checkAccessToken,
  addPremiumToken,
  checkPremiumToken,
  addInvite,
  updateInvite,
  getRoom,
  addRoom,
  newUser,
  getUsers,
  getUser,
  addUser,
  updateUser,
  uploadImage,
  downloadImage,
  downloadBackground,
  getHubStylesBackgroundData,
  getUserBackgroundData,
  createBackground,
  storeLanding,
  createPublicRoom,
  updateCache,
};
