import firebase from 'firebase/app';
import 'firebase/database';
import {
  pathToBarRoom,
  pathToBarRoomPlayers,
  pathToBarRoomPlayer,
  pathToBarRoomInvite,
  pathToBarRoomConvo,
  pathToBarRoomBroadcasts,
  pathToUsermapUser,
  pathToUsermapUserInvite,
  pathToUsermapUserInviter,
  pathToUsermapUserFriendinvite,
  pathToBarRoomRemoves,
  pathToUsermapPopup,
  pathToUsermapPopups,
  pathToBarRoomDesign,
  pathToBarRoomRegisteredAdmins,
  pathToBarRoomNameTag,
} from './firebasePaths';
import { UserMode, ArrowState, PopUpType } from '../../components/BarScene/BarMapsEnums';
import { AnalyticsActionFriends, AnalyticsCategory, logEvent } from '../../Analytics';
import { v4 as uuidv4 } from 'uuid';
// import { resolve } from 'dns';

const updatePlayerPosition = (uid: string, roomID: string, data: PlayerLocationType | PlayerType | FullPlayerType) => {
  // console.log("called update player position");
  const PATH = pathToBarRoomPlayer(roomID, uid);
  firebase
    .database()
    .ref(PATH)
    .update(data); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "update player pos\n\n%cFailure", "color:red;font-size:22;");});
};

const updateUserMap = async (uid: string, data: any, from: string = 'default') => {
  // console.log('called update usermap', from);
  const PATH = pathToUsermapUser(uid);
  let result = await firebase
    .database()
    .ref(PATH)
    .update(data)
    .then((a: any) => {
      return 'success';
    })
    .catch((e: any) => {
      console.log(e, 'update user map\n\n%cFailure', 'color:red;font-size:33;');
      return 'fail';
    });
  return result;
};

const updateRegisteredAdmins = (roomID: string, data: any) => {
  // console.log('called update registered admins');
  const PATH = pathToBarRoomRegisteredAdmins(roomID);
  firebase
    .database()
    .ref(PATH)
    .update(data); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "updateRegisteredAdmins\n\n%cFailure", "color:red;font-size:22;");});;
};

const updatePopup = (uid: string, userID: string, data: any) => {
  // console.log('called update popup');
  logEvent(AnalyticsCategory.Friends, AnalyticsActionFriends.Summoned);
  const PATH = pathToUsermapPopup(userID, uid);
  firebase
    .database()
    .ref(PATH)
    .update(data); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "updatePopup\n\n%cFailure", "color:red;font-size:22;");});;
};

const addPopupToUser = (
  uid: string,
  type: number,
  name: string | null,
  roomName: string | null,
  roomId: string | null,
  to: string,
  from: string | null,
  fromUser: any | null,
  broadcastValue: number | null
) => {
  // console.log('called add pop up to user');
  const data = {
    from: from,
    name: name,
    type: type,
    roomName: roomName,
    roomId: roomId,
    fromUser: fromUser,
    value: broadcastValue,
  };
  updatePopup(uid, to, data);
};

const removePopupFromUser = (uid: string, userID: string) => {
  // console.log('called remove popup from user');
  const PATH = pathToUsermapPopup(userID, uid);
  firebase
    .database()
    .ref(PATH)
    .remove(); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "removePopupFromUser\n\n%cFailure", "color:red;font-size:22;");});;
};

const inviteToRoom = (sender: string, reciever: string, senderRoom: string, senderName: string, roomName: string) => {
  // console.log('\n\n\nInviter called invite \n\ntoken');
  const popupKey = uuidv4().toString();
  addPopupToUser(popupKey, PopUpType.roomInvite, senderName, roomName, senderRoom, reciever, sender, null, null);
  const PATH = pathToUsermapUserInvite(reciever, sender);
  let data = {
    roomID: senderRoom,
    roomName: roomName,
    popup: popupKey,
  };
  firebase
    .database()
    .ref(PATH)
    .set(data); //.then(() => {console.log("\n\nInviter\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "inviteToRoom\n\n%cFailure", "color:red;font-size:22;");});;
};

// rtdb
const inviteFriend = (sender: string, reciever: string, name: string, image: any) => {
  // console.log('called invite friend');
  const popupKey = uuidv4().toString();
  addPopupToUser(popupKey, PopUpType.friendInvite, name, null, null, reciever, sender, null, null);
  const PATH = pathToUsermapUserFriendinvite(reciever, sender);
  const data = {
    name: name,
    image: image,
    popup: popupKey,
  };
  firebase
    .database()
    .ref(PATH)
    .set(data); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "inviteFriend\n\n%cFailure", "color:red;font-size:22;");});;
};

const disinviteFromRoom = (sender: string, reciever: string) => {
  // console.log('called disinvite from room');
  const PATH = pathToUsermapUserInvite(reciever, sender);
  const ref = firebase.database().ref(PATH);
  ref.once('value', (snap: any) => {
    let pop = snap.val()?.popup;
    if (!pop) return;
    removePopupFromUser(pop, reciever);
  });
  ref.remove(); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "disinviteFromRoom\n\n%cFailure", "color:red;font-size:22;");});;
};

const disinviteFriend = (sender: string, reciever: string) => {
  // console.log('called disinvite friend');
  const PATH = pathToUsermapUserFriendinvite(reciever, sender);
  const ref = firebase.database().ref(PATH);
  ref.once('value', (snap: any) => {
    const val = snap.val()?.popup;
    if (val) removePopupFromUser(val, reciever);
  });
  ref.remove(); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "updateRoomCountdisinviteFriend\n\n%cFailure", "color:red;font-size:22;");});;
};

const updateRoomCount = (roomID: string) => {
  // console.log('called update room count');
  var PATH = pathToBarRoomPlayers(roomID);
  firebase
    .database()
    .ref(PATH)
    .once('value', (snap: any) => {
      if (!snap.val()) setRoomCount(roomID, 0);
      else setRoomCount(roomID, Object.keys(snap.val())?.length);
    }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "updateRoomCount\n\n%cFailure", "color:red;font-size:22;");});;
};

const setRoomCount = (roomID: string, num: number) => {
  // console.log('called set room count');
  var PATH = pathToBarRoom(roomID);
  // eslint-disable-next-line
  firebase
    .database()
    .ref(PATH)
    .update({ count: num }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "setRoomCount\n\n%cFailure", "color:red;font-size:22;");});;
};

const setRoomDesign = (roomID: string, design: string) => {
  // console.log('called set room design');
  var PATH = pathToBarRoom(roomID);
  // eslint-disable-next-line
  let ref = firebase
    .database()
    .ref(PATH)
    .update({ design: design }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "setRoomDesign\n\n%cFailure", "color:red;font-size:22;");});;
};

const setRoomPremium = (roomID: string) => {
  // console.log('called set room premium ');
  var PATH = pathToBarRoom(roomID);
  // eslint-disable-next-line
  let ref = firebase
    .database()
    .ref(PATH)
    .update({ premium: true }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "setRoomPremium\n\n%cFailure", "color:red;font-size:22;");});;
};

const setHost = (roomID: string, host: string) => {
  // console.log('called set host');
  var PATH = pathToBarRoom(roomID);
  // eslint-disable-next-line
  let ref = firebase
    .database()
    .ref(PATH)
    .update({ host: host }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "setHost\n\n%cFailure", "color:red;font-size:22;");});;
};

const setNameTag = (roomID: string, value: boolean) => {
  // console.log('called set name tag');
  const PATH = pathToBarRoomNameTag(roomID);
  let ref = firebase
    .database()
    .ref(PATH)
    .update({ nameTag: value }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "setNameTag\n\n%cFailure", "color:red;font-size:22;");});;
  return ref;
};

const setFreeSpeech = (roomID: string, state: boolean) => {
  // console.log('called set free speech');
  var PATH = pathToBarRoom(roomID);
  // eslint-disable-next-line
  let ref = firebase
    .database()
    .ref(PATH)
    .update({ freeSpeech: state }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "setFreeSpeech\n\n%cFailure", "color:red;font-size:22;");});;
};

const getRoomDesign = (roomID: string): Promise<string | null> => {
  // console.log('called get room design');
  const stylePath = pathToBarRoomDesign(roomID);
  return new Promise<string | null>(resolve => {
    firebase
      .database()
      .ref(stylePath)
      .once('value', (snap: any) => {
        console.log(`design is ${snap.val()}`);
        resolve(snap.val() as string);
      }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "getRoomDesign\n\n%cFailure", "color:red;font-size:22;");});;
  });
};

const setInviter = (uid: string, data: string) => {
  // console.log('called set inviter');
  let PATH = pathToUsermapUser(uid);
  let userdata = {
    inviter: data,
  };
  firebase
    .database()
    .ref(PATH)
    .update(userdata); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "setInviter\n\n%cFailure", "color:red;font-size:22;");});;
};

const checkInviter = (uid: string, roomId: string) => {
  // console.log('called check inviter');
  let PATH = pathToUsermapUserInviter(uid);
  firebase
    .database()
    .ref(PATH)
    .once('value', (snap: any) => {
      /**
       * need to check if snap.val() is not empty string, because if it is (whenever I enter a room that DOESN'T have an invite for me to it)
       * we will check usermap/inviter, which doesn't exists, anc cause a rule deny.
       */
      if (snap.val() !== roomId && snap.val() !== '') {
        // console.log("\n\n\n\n%csnap val is", "color:orange;", snap.val(), PATH);
        let nextPATH = pathToUsermapUserInviter(snap.val());
        firebase
          .database()
          .ref(nextPATH)
          .once('value', (nextsnap: any) => {
            if (nextsnap.val() !== roomId) {
              setInviter(uid, '');
            }
          }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "checkInviter1\n\n%cFailure", "color:red;font-size:22;");});;
      }
    }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "checkInviter2\n\n%cFailure", "color:red;font-size:22;");});;
};

const setUserRoomName = (uid: string, data: string) => {
  // console.log('called set user room name');
  let PATH = pathToUsermapUser(uid);
  let userdata = {
    roomName: data,
  };
  firebase
    .database()
    .ref(PATH)
    .update(userdata); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "setUserRoomName\n\n%cFailure", "color:red;font-size:22;");});;
};

const addUserToList = (isAllowed: boolean, roomId: string, userId: string) => {
  // console.log('called add user to list');
  let PATH = pathToUsermapUser(roomId);
  let userdata = isAllowed
    ? {
        whitelist: userId,
      }
    : {
        blacklist: userId,
      };
  firebase
    .database()
    .ref(PATH)
    .update(userdata); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "addUserToList\n\n%cFailure", "color:red;font-size:22;");});;
};

const setUserRoomNameUser = (uid: string, useruid: string) => {
  // console.log('called set user room name ');
  // console.log('setUserRoomNameUser');
  let PATH = pathToUsermapUser(useruid);
  firebase
    .database()
    .ref(PATH)
    .once('value', (snap: any) => {
      if (snap.val()) setUserRoomName(uid, snap.val()?.name + "'s Hub");
    }); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "setUserRoomNameUser\n\n%cFailure", "color:red;font-size:22;");});;
};

const addPlayerToRoom = (uid: string, roomID: string, avatar: string, nickname: string, initialData: any = null) => {
  // TODO: Not sure if best idea to put avatar in RTDB or query
  // the users/uid table for their avatar once. Unnecessary to continually
  // send every users avatar on every position change.
  // console.log('called add player to room');
  const data: FullPlayerType = {
    nickname: nickname,
    curr_x: initialData?.curr_x ?? 20,
    curr_y: initialData?.curr_y ?? 20,
    click_x: initialData?.click_x ?? 20,
    click_y: initialData?.click_y ?? 20,
    convoID: initialData?.convoID ?? '',
    inviteID: initialData?.inviteID ?? '',
    socketID: initialData?.socketID ?? '',
    mode: initialData?.mode ?? UserMode.open,
    arrowState: initialData?.arrowState ?? ArrowState.inactive,
    displayName: initialData?.displayName ?? '',
    private: initialData?.private ?? false,
  };
  const userdata = {
    name: nickname,
    roomID: roomID,
    whitelist: [],
    blacklist: [],
  };
  updatePlayerPosition(uid, roomID, data);
  updateUserMap(uid, userdata, 'add player to room');
  checkInviter(uid, roomID);
  updateRoomCount(roomID);
};

const updateInvite = (uid: string, roomID: string, data: any) => {
  // console.log('called update invite');
  const PATH = pathToBarRoomInvite(roomID, uid);
  firebase
    .database()
    .ref(PATH)
    .update(data); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "updateInvite\n\n%cFailure", "color:red;font-size:22;");});;
};

const updateConvo = (uid: string, roomID: string, data: any) => {
  // console.log('called update convo');
  const PATH = pathToBarRoomConvo(roomID, uid);
  firebase
    .database()
    .ref(PATH)
    .update(data); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "updateConvo\n\n%cFailure", "color:red;font-size:22;");});;
};

const updateBroadcast = (roomID: string, data: any) => {
  // console.log("called update broadcast"); // TODO: call this wayyy less frequently
  const PATH = pathToBarRoomBroadcasts(roomID);
  firebase
    .database()
    .ref(PATH)
    .update(data); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "updateBroadcast\n\n%cFailure", "color:red;font-size:22;");});;
};

const addInviteToRoom = (uid: string, roomID: string, pos: number[], to: string, from: string) => {
  // console.log('called add invite to room');
  const data: InviteType = {
    toID: to,
    state: 0,
    fromID: from,
    pos: pos,
    uid: uid,
  };
  updateInvite(uid, roomID, data);
};

const addConvoToRoom = (uid: string, roomID: string, pos: number[], members: string[]) => {
  // console.log('called add convo to room');
  const data = {
    pos: pos,
    uid: uid,
    private: false,
    members: members,
  };
  updateConvo(uid, roomID, data);
};

const clearSummonPopups = (userID: string) => {
  // console.log('called clear summons popup');
  const PATH = pathToUsermapPopups(userID);
  const popRef = firebase.database().ref(PATH);
  //TODO: optimize
  popRef.once('value', (snap: any) => {
    let popups = snap.val();
    let newPopups: any[] = [];
    if (popups) {
      popups.forEach((element: any) => {
        if (element.type !== 2) newPopups.push(element);
      });
    }
    updateUserMap(userID, { popups: newPopups }, 'clear summons popup');
  });
};

const removePlayerFromRoom = (uid: string, roomID: string, caller = 'default') => {
  // console.log('called remove player from room');
  const PATH = pathToBarRoomPlayer(roomID, uid);
  firebase
    .database()
    .ref(PATH)
    .remove(); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "removePlayerFromRoom\n\n%cFailure", "color:red;font-size:22;");});;
  const data = {
    roomID: null,
  };
  updateUserMap(uid, data, 'remove player from room');
  updateRoomCount(roomID);
  clearSummonPopups(uid);
};

const removeInviteFromRoom = (uid: string, roomID: string) => {
  // console.log('called remove invite from room');
  const PATH = pathToBarRoomInvite(roomID, uid);
  firebase
    .database()
    .ref(PATH)
    .remove(); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "removeInviteFromRoom\n\n%cFailure", "color:red;font-size:22;");});;
};

const removeBroadcastFromRoom = (roomID: string) => {
  // console.log('called remove broadcast from room');
  const PATH = pathToBarRoomBroadcasts(roomID);
  firebase
    .database()
    .ref(PATH)
    .remove(); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "removeBroadcastFromRoom\n\n%cFailure", "color:red;font-size:22;");});;
};

const removeConvoFromRoom = (uid: string, roomID: string) => {
  // console.log('called remove convo from room');
  const PATH = pathToBarRoomConvo(roomID, uid);
  firebase
    .database()
    .ref(PATH)
    .remove(); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "removeConvoFromRoom\n\n%cFailure", "color:red;font-size:22;");});;
};

const removeRemovalFromRoom = (uid: string, roomID: string) => {
  // console.log('called remove removal from room');
  const PATH = pathToBarRoomRemoves(roomID);
  const data: any = {};
  data[uid] = null;
  firebase
    .database()
    .ref(PATH)
    .update(data); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "removeRemovalFromRoom\n\n%cFailure", "color:red;font-size:22;");});;
};

const addRemovalToRoom = (uid: string, roomID: string) => {
  // console.log('called add removal to room');
  const PATH = pathToBarRoomRemoves(roomID);
  const data: any = {};
  data[uid] = uid;
  firebase
    .database()
    .ref(PATH)
    .update(data); //.then(() => {console.log("\n\n%cSuccess", "color:blue;");}).catch((e) => {console.log(e, "addRemovalToRoom\n\n%cFailure", "color:red;font-size:22;");});;
};

export {
  setNameTag,
  setRoomPremium,
  addUserToList,
  removePopupFromUser,
  addPopupToUser,
  updatePopup,
  removeConvoFromRoom,
  updateUserMap,
  addConvoToRoom,
  updateConvo,
  inviteToRoom,
  inviteFriend,
  setInviter,
  setUserRoomName,
  setUserRoomNameUser,
  disinviteFromRoom,
  disinviteFriend,
  updatePlayerPosition,
  addPlayerToRoom,
  removePlayerFromRoom,
  addInviteToRoom,
  updateInvite,
  removeInviteFromRoom,
  updateBroadcast,
  removeBroadcastFromRoom,
  updateRoomCount,
  setRoomDesign,
  removeRemovalFromRoom,
  addRemovalToRoom,
  getRoomDesign,
  setHost,
  setFreeSpeech,
  updateRegisteredAdmins,
};
