import process from 'process';

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const isDev = (): boolean => {
  return development;
};

const pathToUsermap = () => {
  return `/usermap`;
};

const pathToUsermapUser = (uid: string) => {
  return `/usermap/${uid}`;
};

const pathToUsermapUserInvite = (reciever: string, sender: string) => {
  return `/usermap/${reciever}/roominvites/${sender}`;
};

const pathToUsermapUserImage = (uid: string) => {
  return `/usermap/${uid}/image`;
};

const pathToUsermapUserName = (uid: string) => {
  return `/usermap/${uid}/name`;
};

const pathToUsermapUserRoom = (uid: string) => {
  return `/usermap/${uid}/roomID`;
};

const pathToUsermapUserInvites = (reciever: string) => {
  return `/usermap/${reciever}/roominvites`;
};
const pathToUsermapUserInviter = (uid: string) => {
  return `/usermap/${uid}/inviter`;
};
const pathToUsermapUserFriendinvite = (reciever: string, sender: string) => {
  return `/usermap/${reciever}/friendinvites/${sender}`;
};

const pathToRooms = () => {
  return `/rooms`;
};

const pathToBarRoom = (roomID: string) => {
  return `/rooms/${roomID}`;
};

const pathToBarRoomPlayers = (roomID: string) => {
  return `/rooms/${roomID}/players`;
};

const pathToBarRoomInvites = (roomID: string) => {
  return `/rooms/${roomID}/invites`;
};

const pathToBarRoomRemoves = (roomID: string) => {
  return `/rooms/${roomID}/removes`;
};

const pathToBarRoomRegisteredAdmins = (roomID: string) => {
  return `/rooms/${roomID}/registeredAdmins`;
};

const pathToBarRoomBroadcasts = (roomID: string) => {
  return `/rooms/${roomID}/broadcasts`;
};

const pathToBarRoomConvos = (roomID: string) => {
  return `/rooms/${roomID}/convos`;
};

const pathToBarRoomPlayer = (roomID: string, userID: string) => {
  return `/rooms/${roomID}/players/${userID}`;
};

const pathToBarRoomCount = (roomID: string) => {
  return `/rooms/${roomID}/count`;
};
const pathToBarRoomPremium = (roomID: string) => {
  return `/rooms/${roomID}/premium`;
};
const pathToBarRoomVerification = (roomID: string) => {
  return `/rooms/${roomID}/verification`;
};
const pathToUsermapAuth = (uid: string) => {
  return `/usermap/${uid}/auth`;
};
const pathToBarRoomHost = (roomID: string) => {
  return `/rooms/${roomID}/host`;
};

const pathToBarRoomNameTag = (roomID: string) => {
  return `/rooms/${roomID}/nameTag`;
};
const pathToBarRoomPlayerName = (roomID: string, userID: string) => {
  return `/rooms/${roomID}/players/${userID}/nickname`;
};
const pathToBarRoomSpeech = (roomID: string) => {
  return `/rooms/${roomID}/freeSpeech`;
};
const pathToBarRoomDesign = (roomID: string) => {
  return `/rooms/${roomID}/design`;
};

const pathToBarRoomInvite = (roomID: string, inviteID: string) => {
  return `/rooms/${roomID}/invites/${inviteID}`;
};

const pathToBarRoomConvo = (roomID: string, convoID: string) => {
  return `/rooms/${roomID}/convos/${convoID}`;
};

const pathToUsermapPopup = (uid: string, popupID: string) => {
  return `/usermap/${uid}/popups/${popupID}`;
};

const pathToUsermapPopups = (uid: string) => {
  return `/usermap/${uid}/popups`;
};
const pathToUsermapUserRoomSelect = (uid: string) => {
  return `/usermap/${uid}/roomSelect`;
};

export {
  pathToUsermapUserName,
  pathToBarRoomPlayerName,
  pathToUsermapUserRoomSelect,
  pathToBarRoomNameTag,
  pathToUsermapPopups,
  pathToUsermapPopup,
  pathToBarRoomBroadcasts,
  isDev,
  pathToBarRoomVerification,
  pathToUsermap,
  pathToUsermapUser,
  pathToUsermapUserInvite,
  pathToUsermapUserInvites,
  pathToUsermapUserFriendinvite,
  pathToUsermapUserInviter,
  pathToUsermapUserImage,
  pathToUsermapUserRoom,
  pathToRooms,
  pathToBarRoomInvite,
  pathToBarRoom,
  pathToBarRoomCount,
  pathToUsermapAuth,
  pathToBarRoomPremium,
  pathToBarRoomPlayers,
  pathToBarRoomPlayer,
  pathToBarRoomInvites,
  pathToBarRoomConvo,
  pathToBarRoomConvos,
  pathToBarRoomDesign,
  pathToBarRoomRemoves,
  pathToBarRoomHost,
  pathToBarRoomSpeech,
  pathToBarRoomRegisteredAdmins,
};
