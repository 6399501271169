import React, { useState, useEffect, lazy, Suspense } from 'react';
import './LandingPage.scss';
import { useAppState } from '../../state';

import vartySmallIcon from '../../assets/img/cornerLogo.svg';
// import { DEFAULT_ROOM } from '../../App';
import UnsupportedBrowser from '../UnsupportedBrowser/UnsupportedBrowser';
// import background from '../../assets/img/background.jpg';
import squares from '../../assets/img/squares.svg';
import Grid from '@material-ui/core/Grid';

import {
  isIOS,
  isMobile,
  isAndroid,
  isChrome,
  isEdge,
  isSafari,
  isBrowser,
  osName,
  osVersion,
  // getUA,
  deviceType,
  isElectron,
  browserName,
  fullBrowserVersion,
  isFirefox,
} from 'react-device-detect';
import EmailIcon from '@material-ui/icons/Email';
import { Dialog } from '@material-ui/core';
import { Button, Container } from '@material-ui/core';
// import { logger } from '../../Analytics';
import { storeLanding } from '../../state/useFirebaseAuth/firebaseHelper';
import googleIcon from '../../assets/img/google.png';
// import * as firebase from 'firebase/app';
// import 'firebase/auth';

const ContactForm = lazy(() => import('../../components/ContactForm/ContactForm'));
const SignUp = lazy(() => import('./SignUp'));
const ContactFormBusiness = lazy(() => import('../../components/ContactForm/ContactFormBusiness'));

const SocialMedia = lazy(() => import('./SocialMedia'));
const EmailMenu = lazy(() => import('./EmailMenu'));

const handleScale = () => {
  const width = window.innerWidth;
  let scale: number;
  width < 1095 ? (scale = width * 0.0009) : (scale = 1);
  const left = (width / 2 - scale * 480) / 2;
  return [scale, left];
};

export default function LandingPage(props: any) {
  const { redirect } = props;
  const [scale, setScale] = useState<number[]>(handleScale);
  const [signInForms, setSignInForms] = useState(0); //0: base, 1:sign up, 2: sign in, 3: google
  const [email, setEmail] = useState('');
  const {
    // createAccountEmailPassword,
    // signInEmailPassword,
    signIn,
    isAuthReady,
    setLoginRedirect,
    loginRedirect,
    profile,
    user,
  } = useAppState();
  const [, setAuthError] = useState<Error | null>(null);
  useEffect(() => {
    window.addEventListener('resize', () => setScale(handleScale));
    return () => {
      window.removeEventListener('resize', () => setScale(handleScale));
    };
  }, []);
  //
  const login = (redirectURL: string, type: number) => {
    setLoginRedirect(redirectURL);
    setAuthError(null);
    if (user && profile) return;
    if (type === 3) {
      signIn?.(redirectURL)
        .then(() => {
          //sendEmail(userEmail.current, "thanks for using varty");
        })
        .catch(err => setAuthError(err));
    } else if (type) {
      setSignInForms(type);
    }
    //createAccountEmailPassword("eliwstewart10@yahoo.com", "123456789", "Eli Stewart");
    //signInEmailPassword("eliwstewart10@yahoo.com", "123456789");
  };

  // TODO(ethan): extract function call
  // function webgl_support() {
  //   try {
  //     var canvas = document.createElement('canvas');
  //     return !!window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
  //   } catch (e) {
  //     return null;
  //   }
  // }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailParam = urlParams.get('email');
    const redirectParam = urlParams.get('redirect');
    if (redirectParam || redirect) {
      setLoginRedirect(redirectParam || redirect);
    }
    if (emailParam) {
      setEmail(emailParam);
      setSignInForms(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let badBrowser: boolean = !(isChrome || isEdge || isFirefox || isSafari);
  // !(browserName === 'Chrome' || browserName === 'Edge'); /*isFirefox || isSafari || isOpera; // */
  let mobile = isIOS || isAndroid || isMobile;

  useEffect(() => {
    fetch('https://www.cloudflare.com/cdn-cgi/trace', { method: 'GET' })
      .then((data: any) => {
        // const reader = data.body.getReader();
        return data.text();
      })
      .then(res => {
        const userDict: any = {};
        res.split('\n').map((str: string) => {
          const split: string[] = str.split('=');
          if (!split[0]) return 'UNUSED';
          userDict[split[0]] = split[1];
          return 'UNUSED';
        });
        const date = new Date();
        userDict['mobile'] = isMobile;
        userDict['browser'] = isBrowser;
        userDict['browserName'] = browserName;
        userDict['fullBrowserVersion'] = fullBrowserVersion;
        userDict['osName'] = osName;
        userDict['osVersion'] = osVersion;
        userDict['deviceType'] = deviceType;
        userDict['isElectron'] = isElectron;
        userDict['date'] = date.toUTCString();

        storeLanding(userDict);
        return userDict;
      });
  }, []);

  useEffect(() => {
    if (redirect && (badBrowser || mobile)) {
      window.history.replaceState(null, 'Varty', redirect);
    }
  }, [badBrowser, mobile, redirect]);

  // add in && badBrow if we want to allow non-supported browsers
  if (badBrowser || mobile) {
    return <UnsupportedBrowser badBrowser={badBrowser} mobile={mobile} />;
  }

  let privacy = (
    <div id="privacy">
      By using Varty.io, you agree to the following terms: Do not use Varty if you are under 18. Do not transmit nudity,
      sexually harass anyone, publicize other peoples' private information, make statements that defame or libel anyone,
      violate intellectual property rights, use automated programs to start chats, or behave in any other inappropriate
      or illegal way on Varty. Understand that human behavior is fundamentally uncontrollable, that the people you
      encounter on Varty may not behave appropriately, and that they are solely responsible for their own behavior. Use
      Varty at your own peril. Disconnect if anyone makes you feel uncomfortable. You may be denied access to Varty for
      inappropriate behavior, or for any other reason. VARTY IS PROVIDED AS IS, AND TO THE MAXIMUM EXTENT ALLOWED BY
      APPLICABLE LAW, IT IS PROVIDED WITHOUT ANY WARRANTY, EXPRESS OR IMPLIED, NOT EVEN A WARRANTY OF MERCHANTABILITY OR
      FITNESS FOR A PARTICULAR PURPOSE. TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, THE PROVIDER OF VARTY, AND ANY
      OTHER PERSON OR ENTITY ASSOCIATED WITH VARTY’S OPERATION, SHALL NOT BE HELD LIABLE FOR ANY DIRECT OR INDIRECT
      DAMAGES ARISING FROM THE USE OF VARTY, OR ANY OTHER DAMAGES RELATED TO VARTY OF ANY KIND WHATSOEVER. By using
      Varty, you accept the practices outlined in Varty’s{' '}
      <a href={`${window.location.origin}/terms`}>TERMS OF SERVICE</a> (updated 2021-01-05 – contains important
      information about video chat monitoring). For more information on how we handle and protect your personal
      information as well as information on our compliance with GDPR and CCPA guidelines, see our{' '}
      <a href={`${window.location.origin}/privacy`}>PRIVACY NOTICE</a>.
    </div>
  );
  return (
    <div id="landing-page">
      <div id="primary-body" style={{ height: redirect ? '100%' : '80%' }}>
        <div className="background-image" style={{ backgroundImage: `url(${squares})` }}></div>
        {redirect ? (
          <>
            <div
              style={{
                height: `${window.innerHeight - 300}px`,
                position: 'absolute',
                width: '100%',
                paddingLeft: 'auto',
                paddingRight: 'auto',
              }}
            >
              <Button
                onClick={() => {
                  login(redirect, -1);
                }}
                id="continue-button"
                style={{ left: `${window.innerWidth / 2 - window.innerWidth * 0.19}px`, top: '40%' }}
              >
                <p style={{ fontSize: '2vw', position: 'absolute', top: '.7vw', left: '5vw' }}>
                  Sign in to continue to room
                </p>
              </Button>
            </div>
          </>
        ) : (
          <>
            <div id="bar">
              <img className="p-2" id="varty-logo" src={vartySmallIcon} alt="" />
            </div>
            <div id="main-wrapper">
              <div id="middle-wrapper">
                <div id="left-wrapper">
                  <Container id="left-wrapper-container">
                    <p className="left title">Host virtual parties</p>
                    <p className="left subtitle">Spatialized video chat for better group dynamics</p>
                    <div id="cta">
                      <Button
                        onClick={() => {
                          login('/hub', -1);
                        }}
                        className="circular-button"
                        id="join-button"
                      >
                        Start a Varty
                      </Button>
                    </div>
                  </Container>
                </div>
                <div id="video-wrapper">
                  <iframe
                    title="Unique Title Property"
                    frameBorder="0"
                    id="video"
                    width={440}
                    height={247.5}
                    src="https://www.youtube.com/embed/aqDdh-s6SZc?autoplay=1&mute=1&showinfo=0&controls=0&loop=1&disablekb=1&playlist=aqDdh-s6SZc"
                  ></iframe>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {redirect ? (
        <></>
      ) : (
        <>
          <Suspense fallback={null}>
            <SocialMedia />
          </Suspense>
          <div id="secondary-body">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2112"
              height="720.687"
              viewBox="0 0 2112 720.687"
              preserveAspectRatio="none"
            >
              <g transform="translate(-21215.5 10588.5)">
                <path
                  className="a"
                  d="M1004.55,701.186c-20.775,0-42.169-.8-63.586-2.364-20.492-1.5-41.564-3.748-62.631-6.682-20.091-2.8-40.7-6.292-61.242-10.382-19.536-3.891-39.527-8.422-59.42-13.468A1381.851,1381.851,0,0,1,593.5,615.085V374.229h2112v273.8c-20.429,5.272-40.89,10.017-60.815,14.1-21.2,4.349-42.384,8.07-62.95,11.06-21.768,3.164-43.433,5.593-64.393,7.22-22.082,1.714-44,2.584-65.145,2.584-18.947,0-37.708-.433-55.761-1.288-17.195-.813-34.327-2.036-50.92-3.635-30.579-2.947-60.017-7.182-90-12.947-24.948-4.8-50.68-10.762-78.665-18.234-24.958-6.663-49.216-13.841-72.675-20.782l-.015,0-.015,0-.01,0c-21.146-6.257-43.012-12.726-65.055-18.714-24.691-6.709-47.109-12.121-68.536-16.546-25.67-5.3-50.516-9.338-75.957-12.343-13.776-1.627-27.906-2.961-42-3.964-14.747-1.049-30-1.774-45.343-2.154-6.156-.152-12.421-.229-18.621-.229-14.536,0-29.406.427-44.2,1.268-14.138.8-28.673,2.016-43.2,3.6-26.912,2.931-54.51,7.169-84.373,12.957-25.349,4.913-51.8,10.818-83.23,18.583-27.7,6.841-54.931,14.053-83.765,21.688l-.009,0-.109.029-.091.024-.042.011c-64.667,17.125-131.535,34.833-204.463,48.274-18.839,3.472-37.643,6.569-55.89,9.2-19.555,2.824-39.235,5.226-58.5,7.141-20.416,2.03-41.091,3.584-61.451,4.621C1047.9,700.634,1026.115,701.186,1004.55,701.186Z"
                  transform="translate(20622 -10569)"
                />
                <path
                  className="b"
                  d="M791.916,1159.187c-16.245,0-32.98-.622-49.739-1.849-16.035-1.174-32.518-2.933-48.993-5.227-15.709-2.188-31.827-4.92-47.906-8.122-15.279-3.043-30.917-6.587-46.48-10.534-53.854-13.659-109.322-32.706-164.863-56.611-21.821-9.392-43.7-19.565-65.041-30.236-17.072-8.538-33.835-17.412-49.823-26.376-27.032-15.155-42.962-25.628-43.12-25.732v-485H2387.138v503.811l-.01.006c-.611.347-15.241,8.626-39.673,20.563-14.664,7.165-30,14.258-45.569,21.083-19.469,8.532-39.365,16.663-59.137,24.169a1119.9,1119.9,0,0,1-148.215,45.252c-28.5,6.482-56.665,11.5-83.7,14.913-14.532,1.834-29.024,3.24-43.072,4.178-14.688.981-29.3,1.478-43.438,1.478-14.818,0-29.493-.339-43.619-1.008-13.459-.637-26.861-1.594-39.832-2.844-23.926-2.306-46.954-5.619-70.4-10.128-19.51-3.752-39.638-8.418-61.536-14.263-19.518-5.211-38.5-10.828-56.861-16.26l-.019-.006c-16.542-4.894-33.648-9.955-50.889-14.639-19.32-5.249-36.857-9.483-53.612-12.943-20.08-4.147-39.515-7.3-59.417-9.656-10.777-1.273-21.831-2.316-32.854-3.1-11.562-.822-23.5-1.389-35.469-1.684-4.811-.119-9.71-.179-14.563-.179-11.375,0-23.007.334-34.572.992-11.048.629-22.419,1.576-33.8,2.815-21.059,2.295-42.648,5.61-66,10.136-43.537,8.438-85.919,19.662-130.79,31.545l-.025.006c-50.591,13.4-102.905,27.251-159.953,37.766-30.833,5.683-60.1,9.865-89.477,12.786-15.969,1.588-32.142,2.8-48.069,3.614C825.82,1158.755,808.777,1159.187,791.916,1159.187Z"
                  transform="translate(20940 -11098)"
                />
              </g>
            </svg>
            <div className="overlay">
              <div>{redirect ? 'Please sign in to continue to room' : 'HOST A VIRTUAL SPACE'}</div>
            </div>
            <div id="login-buttons-text">
              <div id="left-button-text">
                <div className="body">
                  <h1 className="title">HOST AN EVENT</h1>
                  <div className="description">
                    Invite friends by copying and sharing a room link
                    <br />
                    We offer rooms for:
                    <br />
                    <div id="col1">
                      ‣  Bars
                      <br />
                      ‣ Movie Theaters
                      <br />‣ Conferences
                    </div>
                    <div id="col2">
                      ‣ Concerts
                      <br />
                      ‣ Classrooms
                      <br />‣ Clubs
                    </div>
                  </div>
                </div>
              </div>
              <div id="right-button-text">
                <div className="body">
                  <h1 className="title">EXPLORE EVENTS</h1>
                  <div className="description">
                    Try out the platform at our Global Bar room and explore various other events
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tertiary-body">
            <div id="contact">
              <div className="title">CONTACT US</div>
              <div id="left-contact" className="form-box">
                <div className="scalable-form-wrapper" style={{ transform: `scale(${scale[0]})`, left: scale[1] }}>
                  <Suspense fallback={null}>
                    <ContactFormBusiness
                      submitFunction={() => {
                        return;
                      }}
                    />
                  </Suspense>
                </div>
              </div>
              <div id="right-contact" className="form-box">
                <div className="scalable-form-wrapper" style={{ transform: `scale(${scale[0]})`, left: scale[1] }}>
                  <Suspense fallback={null}>
                    <ContactForm
                      submitFunction={() => {
                        return;
                      }}
                    />
                  </Suspense>
                </div>
              </div>
            </div>
            {privacy}
          </div>
        </>
      )}
      {redirect ? privacy : <></>}
      <Dialog
        BackdropProps={{
          classes: {
            root: "{backgroundColor: 'transparent'}",
          },
        }}
        onClose={() => {
          setSignInForms(0);
        }}
        open={signInForms === 1}
      >
        <SignUp setSignInForms={setSignInForms} />
      </Dialog>
      <Dialog
        BackdropProps={{
          classes: {
            root: "{backgroundColor: 'transparent'}",
          },
        }}
        onClose={() => {
          setSignInForms(0);
        }}
        open={signInForms === 2}
      >
        <Suspense fallback={null}>
          <EmailMenu setSignInForms={setSignInForms} email={email} />
        </Suspense>
      </Dialog>

      <Dialog
        open={signInForms === -1}
        onClose={() => {
          setSignInForms(0);
        }}
        maxWidth="xs"
      >
        <div style={{ padding: 15 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant={'contained'}
                  startIcon={<img src={googleIcon} style={{ height: 25 }} alt="Logo for Google"></img>}
                  onClick={() => {
                    login(loginRedirect || '/hub', 3);
                  }}
                >
                  Sign in with Google
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant={'contained'}
                  onClick={() => {
                    login(loginRedirect || '/hub', 2);
                  }}
                  startIcon={<EmailIcon />}
                >
                  Sign in with email
                </Button>
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant={'contained'}
                  onClick={() => {
                    login('/hub', 1);
                  }}
                  startIcon={<EmailIcon />}
                >
                  Email sign up
                </Button>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
