import * as PIXI from 'pixi.js-legacy';

export type BarData = {
  boundingPoly: PIXI.Polygon;
  boundingPolyGraphics: PIXI.Graphics;
  background: PIXI.Sprite;
  scale: PIXI.Point;
  respawnX: number;
  respawnY: number;
};

enum BroadcastMode {
  closedListener = 0,
  closedHost = -1,
  openListener = 1,
  openHost = 2,
}

enum UserMode {
  open = -1,
  inviting = 1,
  invited = 2,
  accepted = 3,
  // startingCall = 4, DEPRECATE
  joiningCall = 5,
  inCall = 6,
  arrived = 7,
  auto = 8,
  admin = 9,
  afk = 10,
  awaitingCall = 11,
  leavingCall = 12,
  pendingJoin = 13,
  //watch these carefully. They should only be updated in the database and not locally
  privateCall = 14,
  privateAdmin = 15,
  disconnected = 16,
  endingCall = 17,
}

enum InviteState {
  open = 0,
  accepted = 1,
  rejected = -1,
  calling = 2,
}

enum PopUpType {
  roomInvite = 0,
  friendInvite = 1,
  callOver = 2,
  entryRequest = 3,
  entryBlocked = 4,
  broadcast = 5,
  webGL = 6,
}

enum BroadcastType {
  presenting = 0,
  failed = -1,
  screensharing = -2,
  warning = -3,
  media = -4,
}

enum LockType {
  open = 0,
  locked = 1,
  lockedFriends = 2,
}

enum RoomType {
  OpenPublic = 0,
  LockedPublic = 1,
  OpenHub = 2,
  LockedHub = 3,
}

enum ArrowState {
  stop = -1,
  inactive = 0,
  north = 1,
  northeast = 2,
  east = 3,
  southeast = 4,
  south = 5,
  southwest = 6,
  west = 7,
  northwest = 8,
}

enum SpriteLayers {
  webcam = 'webcam',
  face = 'face',
  border = 'border',
  filter = 'layer',
}

const glowMap = {
  base: 'base',
  glow: 'glow',
  admin: 'admin',
  privGlow: 'privGlow',
  afk: 'afk',
};

export {
  LockType,
  PopUpType,
  RoomType,
  UserMode,
  InviteState,
  ArrowState,
  SpriteLayers,
  glowMap,
  BroadcastMode,
  BroadcastType,
};
