import React from 'react';
import './UnsupportedMobile.scss';
import instagramIcon from '../../assets/img/instagram.svg';
import facebookIcon from '../../assets/img/facebook.svg';
import twitterIcon from '../../assets/img/twitter.svg';
import unsupported from '../../assets/img/message.png';

export default function UnsupportedMobile() {
  return (
    <div className="UnsupportedMobile">
      <div className="Page">
        <div className="Body">
          <img alt="varty" className="unsupported" src={unsupported}></img>
          <div className="Social-Media-Div">
            <div className="instagram">
              <a href="https://instagram.com/varty.io">
                <img alt="" className="Icon" src={instagramIcon}></img>
              </a>
            </div>
            <div className="twitter">
              <a href="https://twitter.com/varty_io">
                <img alt="" className="Icon" src={twitterIcon}></img>
              </a>
            </div>
            <div className="facebook">
              <a href="https://www.facebook.com/vartyio-110265477464918">
                <img alt="" className="Icon" src={facebookIcon}></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
