import { toast } from 'react-toastify';
import { logger } from '../../Analytics';
import { WebRTCHelper, BackendConnection } from './WebRTC';
import kurentoUtils from 'kurento-utils';
import VideoStreamMerger from 'video-stream-merger';

/*** SERVER SIDE MUST BE SAME! */
export type KurentoMessage = {
  id: string;
  socketID: string;
  message?: string;
  sdpAnswer?: string;
  sdpOffer?: string;
  response?: string;
  candidate?: RTCIceCandidate;
};
const WebRTCKurento = {
  endConnection: (conn: BackendConnection, sendMessage: boolean, isPresenter: boolean, socketID: string) => {
    if (isPresenter && conn.kurento.presenter) {
      if (sendMessage) {
        const message: KurentoMessage = {
          id: 'stop',
          socketID: socketID,
        };
        WebRTCKurento.sendMessage(conn, message);
      }
      conn.kurento.presenter.dispose();
      // Never disconnect even if people mute until presenter ends
      conn.kurento.presenter = null;
      conn.kurento.stream.merger?.destroy();
      conn.kurento.stream.merger = null;
      conn.kurento.stream.added = {};
    }
    if (!isPresenter && conn.kurento.viewers[socketID]) {
      conn.kurento.viewers[socketID].dispose();
      delete conn.kurento.viewers[socketID];
    }
  },
  present: (
    wrtc: WebRTCHelper,
    localVideo: HTMLVideoElement,
    presentTogether: boolean
  ): VideoStreamMerger | undefined => {
    // https://doc-kurento.readthedocs.io/en/stable/features/kurento_utils_js.html
    const conn = wrtc?.backconn;

    if (!conn) {
      logger.info('[KURENTO] Conn null');
      toast.info('Something went wrong, refresh the page...');
      return;
    }
    if (conn.kurento.presenter) {
      toast.info('Already presenting!');
      return;
    }
    if (conn.kurento.stream.merger) {
      conn.kurento.stream.merger.destroy();
      conn.kurento.stream.merger = null;
      conn.kurento.stream.added = {};
    }
    const videoStreamMerger = new VideoStreamMerger();
    const local = wrtc?.localStream.clone();
    // Enable all tracks
    local.getTracks().forEach(track => (track.enabled = true));
    videoStreamMerger.addStream(local);
    videoStreamMerger.start();
    const options = {
      localVideo,
      videoStream: videoStreamMerger.result,
      onicecandidate: (candidate: RTCIceCandidate) => WebRTCKurento.onIceCandidate(conn, candidate, conn.socket.id),
    };
    const peer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, error => {
      if (error) {
        logger.info('[KURENTO] Present error: ' + error);
        toast.info('Something went wrong, refresh the page...');
        return;
      }
      peer.generateOffer((error2, sdpOffer) => {
        if (!wrtc?.backconn || error2) {
          logger.info('[KURENTO] Conn null');
          return;
        }
        const message: KurentoMessage = {
          id: 'presenter',
          socketID: conn.socket.id,
          sdpOffer: sdpOffer,
        };
        WebRTCKurento.sendMessage(wrtc.backconn, message);
      });
    });
    conn.kurento.presenter = peer;
    conn.presentTogether = presentTogether;
    conn.kurento.stream.merger = videoStreamMerger;
    return videoStreamMerger;
  },
  view: (wrtc: WebRTCHelper, remoteVideo: HTMLVideoElement, presenter: string): boolean => {
    const conn = wrtc?.backconn;
    if (!conn) {
      logger.info('[KURENTO] Conn null');
      return false;
    }
    if (conn.kurento.viewers[presenter]) {
      logger.info('[KURENTO] Already viewing!');
      return false;
    }
    const options = {
      remoteVideo,
      onicecandidate: (candidate: RTCIceCandidate) => WebRTCKurento.onIceCandidate(conn, candidate, presenter),
    };
    const peer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, error => {
      if (error) {
        logger.info('[KURENTO] View error: ' + error);
        return;
      }
      peer.generateOffer((error2, sdpOffer) => {
        if (!wrtc.backconn || error2) {
          logger.info('[KURENTO] Conn null');
          return;
        }
        const message: KurentoMessage = {
          id: 'viewer',
          socketID: presenter,
          sdpOffer: sdpOffer,
        };
        WebRTCKurento.sendMessage(wrtc.backconn, message);
      });
    });
    conn.kurento.viewers[presenter] = peer;
    return true;
  },
  ping: (conn: BackendConnection) => {
    conn.socket.emit('kurento-ping');
  },
  sendMessage: (conn: BackendConnection, message: KurentoMessage) => {
    conn.socket.emit('kurento-message', JSON.stringify(message));
  },
  onIceCandidate: (conn: BackendConnection, candidate: RTCIceCandidate, socketID: string) => {
    const message = {
      id: 'onIceCandidate',
      socketID: socketID,
      candidate: candidate,
    };
    WebRTCKurento.sendMessage(conn, message);
  },
  response: (conn: BackendConnection, message: KurentoMessage, isPresenter: boolean) => {
    if (message.response !== 'accepted') {
      toast.info('Something went wrong');
      logger.info('[KURENTO] Error: ' + (message.message || 'Unknown error'));
      WebRTCKurento.endConnection(conn, false, isPresenter, message.socketID);
    } else if (isPresenter && conn.kurento.presenter && message.sdpAnswer) {
      conn.kurento.presenter.processAnswer(message.sdpAnswer);
    } else if (!isPresenter && conn.kurento.viewers[message.socketID] && message.sdpAnswer) {
      conn.kurento.viewers[message.socketID].processAnswer(message.sdpAnswer);
    }
  },
};
export default WebRTCKurento;
