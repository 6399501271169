import React from 'react';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import Fab from '@material-ui/core/Fab';
import { pathToBarRoomConvos, pathToBarRoomPlayer } from '../../state/useFirebaseAuth/firebasePaths';
import firebase from 'firebase/app';
import 'firebase/database';

const DynamicControl = (props: any) => {
  const { state, convo, roomID, player, isConvo } = props;
  const [selected, setSelected] = React.useState(state);

  React.useEffect(() => {
    let PATH: string;
    let ref: any;
    if (convo) {
      PATH = `${pathToBarRoomConvos(roomID)}/${convo}/private`;
      ref = firebase.database().ref(PATH);
      ref.on('value', (snap: any) => {
        const val = snap.val();
        if (val === null) return;
        setSelected(snap.val());
      });
    } else if (player) {
      PATH = pathToBarRoomPlayer(roomID, player);
      ref = firebase.database().ref(PATH);
      ref.on('value', (snap: any) => {
        let player = snap.val();
        if (!player) return;
        setSelected(player.private);
      });
    }

    return () => {
      ref.off('value');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ position: 'relative', alignItems: 'center' }}>
      <Fab style={{ backgroundColor: '#1a1a1a', color: 'white' }} size="small">
        {selected ? <LockIcon /> : <LockOpenIcon />}
      </Fab>
      <p
        style={{
          left: isConvo ? (selected ? '-12px' : '-7px') : '-1px',
          textTransform: 'none',
          width: 'max-content',
          lineHeight: 0,
          marginTop: 0,
          marginLeft: 5,
          alignItems: 'center',
          alignSelf: 'center',
          fontSize: 10,
          color: 'white',
          fontFamily: 'Arial',
          bottom: '0px',
        }}
      >
        {isConvo ? (selected ? 'Unlock Call' : 'Lock Call') : 'Private'}
      </p>
    </div>
  );
};
export default React.memo(DynamicControl);
