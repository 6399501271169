import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUsers, faPlus, faUserPlus, faDoorOpen, faUser, faClock } from '@fortawesome/free-solid-svg-icons';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import theme from './theme';
import './types';
import App from './App';

library.add(faUser, faUsers, faPlus, faUserPlus, faDoorOpen, faClock);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </MuiThemeProvider>,
  document.getElementById('root')
);
