import React, { useEffect } from 'react';
// import { useAppState } from '../../state';
import MainScene from '../BarScene/MainScene';
import MainSceneHub from '../BarScene/MainSceneHub';
import '../../types';
import { VideoProvider } from '../VideoProvider';
import UnsupportedBrowser from '../UnsupportedBrowser/UnsupportedBrowser';
import { isChrome, isEdge, isFirefox, isSafari } from 'react-device-detect';
import { logger } from '../../Analytics';

// const canvasSize = 600;
// const pictureSize = 320;
// const useStyles = makeStyles({
//   popUpPaper: {
//     display: 'flex',
//     alignItems: 'center',
//     flexDirection: 'column',
//     color: 'black',
//     borderRadius: 10,
//   },
//   popupText: {
//     display: 'flex',
//     justifyContent: 'center',
//     position: 'relative',
//     color: 'black',
//     fontFamily: 'Arial Black',
//     fontSize: 25,
//   },
// });

function webgl_detect() {
  if (!!window.WebGLRenderingContext) {
    var canvas = document.createElement('canvas'),
      names = ['webgl2', 'webgl', 'experimental-webgl', 'moz-webgl', 'webkit-3d'],
      context: any = false;

    for (var i = 0; i < names.length; i++) {
      try {
        context = canvas.getContext(names[i]);
        if (context && typeof context.getParameter == 'function') {
          // WebGL is enabled
          return true;
        }
      } catch (e) {}
    }

    // WebGL is supported, but disabled
    return false;
  }

  // WebGL not supported
  return false;
}

const VideoApp = (props: any) => {
  const { roomKey } = props;
  // TODO: remove image
  // eslint-disable-next-line

  // For now, these are the only good browsers
  let badBrowser: boolean = !(isChrome || isEdge || isFirefox || isSafari);
  let mobile = false;
  let webGLSupport: boolean = webgl_detect();

  var theRoom: any;
  if (roomKey) {
    theRoom = <MainSceneHub roomKey={roomKey} webGLSupport={webGLSupport} />;
  } else {
    theRoom = <MainScene webGLSupport={webGLSupport} />;
  }

  logger.info(
    `We check the following: bad browser: ${badBrowser}, mobile: ${mobile}, and webGLSupport: ${webGLSupport}`
  );

  // add in && badBrow if we want to allow non-supported browsers
  if (badBrowser || mobile) {
    return <UnsupportedBrowser badBrowser={badBrowser} mobile={mobile} />;
  }

  return <VideoProvider>{theRoom}</VideoProvider>;
};

export default VideoApp;
