import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  title: {
    color: 'Black',
  },
});
type Props = {
  Query: string;
  Description: string;
  AcceptText: string;
  DeclineText: string;
  AcceptFunction: any;
  open: boolean;
  setOpen: Function;
};
export default function PopUpConfirm({
  Query,
  Description,
  AcceptText,
  DeclineText,
  AcceptFunction,
  open,
  setOpen,
}: Props) {
  const classes = useStyles();

  const handleCloseAccept = () => {
    setOpen(false);
    AcceptFunction();
  };
  const handleCloseDecline = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDecline}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          {Query}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{Description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDecline} color="primary">
            {DeclineText}
          </Button>
          <Button onClick={handleCloseAccept} color="primary" autoFocus>
            {AcceptText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
