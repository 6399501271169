import React, { useEffect, lazy, Suspense } from 'react';
import AppStateProvider from './state';
import VideoApp from './components/VideoApp/VideoApp';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import TheHub from './components/RoomSelector/TheHub';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Analytics, { initializePage, isDevelopment } from './Analytics';
import LandingPage from './components/LandingPage/LandingPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { setupIntercom } from './widgets/intercom.js';
const GDPR = lazy(() => import('./components/Terms/GDPR'));
const Terms = lazy(() => import('./components/Terms/Terms'));

export const DEFAULT_ROOM = isDevelopment ? '/room/development' : '/room/global-lobby';

function App() {
  function getURL() {
    const url = window.location.href;
    if (url.includes('room/')) return url;
    return '';
  }
  useEffect(() => {
    initializePage();
    setupIntercom();
  }, []);
  (window as any).intercomSettings = {
    app_id: 'avd9fpm5',
    alignment: 'right',
    horizontal_padding: 10,
    vertical_padding: 10,
  };

  return (
    <Router>
      <Analytics />
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar closeOnClick draggable pauseOnHover />
      <AppStateProvider>
        <Switch>
          <PrivateRoute path="/room/:BarID">
            <VideoApp />
          </PrivateRoute>
          <PrivateRoute path="/hub">
            <TheHub />
          </PrivateRoute>
          <Route path="/login">
            <LandingPage redirect={getURL()} />
          </Route>
          <Route path="/terms">
            <Suspense fallback={null}>
              <Terms />
            </Suspense>
          </Route>
          <Route path="/privacy">
            <Suspense fallback={null}>
              <GDPR />
            </Suspense>
          </Route>
          <Route path="/">
            <Redirect to={'/login'} />
          </Route>
        </Switch>
      </AppStateProvider>
    </Router>
  );
}
export default App;
