/* eslint-disable no-shadow */
import { useCallback, useEffect, useState, useRef } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { newUser, getUser, addUser } from './firebaseHelper';
import { updateUserMap } from './firebaseRoomActions';
import { setLogUser, USER_SIGNEDOUT } from '../../Analytics';
import { logger } from '../../Analytics';
// import { Button } from 'reactstrap';

const firebaseConfig = {
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

export default function useFirebaseAuth() {
  const [user, setUserFinal] = useState<firebase.User | null>(null);
  const [profile, setProfile] = useState<any | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [loginRedirect, setLoginRedirect] = useState('');
  const userEmail = useRef('');

  async function setUser(input: firebase.User | null) {
    console.log(input);
    if (!input) {
      console.log('null');
      setUserFinal(input);
      return;
    }
    const currUser = firebase.auth().currentUser;
    if (currUser?.displayName) {
      logger.info('[AUTH] User info in Firebase auth - ' + userEmail.current);
      await getUser(input.uid, 'setUser')
        .then(res => {
          if (res) {
            setUserFinal(input);
            logger.info('[AUTH] User in Firestore - ' + userEmail.current);
          }
          return;
        })
        .catch(() => {
          setUser(null);
        });
      return;
    }
    setUser(null);
  }

  const getToken = useCallback(
    async (identity: string, roomName: string) => {
      const headers = new window.Headers();

      const idToken = await user!.getIdToken();
      headers.set('Authorization', idToken);

      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
      const params = new window.URLSearchParams({ identity, roomName });
      return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
    },
    [user]
  );

  useEffect(() => {
    if (user && profile && loginRedirect) {
      window.location.href = loginRedirect;
      setLoginRedirect('');
    }
  }, [loginRedirect, user, profile]);

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    firebase.auth().onAuthStateChanged(async user => {
      if (user?.uid && user?.email) updateUserMap(user.uid, { auth: user.email.split('@')[1] }, 'use Firebase auth');
      await setUser(user);
      setLogUser(user?.email || 'NULL');
      userEmail.current = user?.email || '';
      setIsAuthReady(true);
      if (user?.uid)
        await getUser(user?.uid, 'USEEFFECT')
          .then((res: any) => setProfile(res))
          .catch((e: any) => {
            logger.info(`[AUTH] User not Verified - ${user?.email} `);
          });
      await setUser(user);
    });
    processSignInLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(() => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/plus.login');
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(async user => {
        const currUser = firebase.auth().currentUser;
        if (currUser && !currUser?.displayName) {
          await currUser.updateProfile({
            displayName: 'No Name',
          });
        }
        setLogUser(user.user?.email || 'NULL');
        await createProfile(user.user?.uid, user.user?.displayName, user.user?.email);
        await setUser(user.user);
      });
    // TODO: Fix exhaustive dep error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function createUnverifiedAccount(email: string) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setLogUser(USER_SIGNEDOUT);
        setProfile(null);
      });
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, email)
      .then(async user => {
        var actionCodeSettings = {
          url: `${window.location.origin}/login?email=${email}&redirect=${loginRedirect}`,
          handleCodeInApp: true,
        };
        const thisRes = await firebase
          .auth()
          .currentUser?.sendEmailVerification(actionCodeSettings)
          .then(function() {
            return 'success'; //'Account creation successful. We sent you a verification email. Follow the link in the email to complete the creation of your account then sign in using the email and password you just set.';
          })
          .catch(function(error) {
            console.log('E', error);
            signOut();
            return 'emailFail'; //'We were unable to send a verification email to the email you entered. Please try again with a different email.';
          });
        return thisRes;
      })
      .catch(async error => {
        signOut();
        return 'accountFail'; //"Unable to create an account with this email"
      });
    return res;
  }

  //When needsInfo
  async function updateVerifiedAccount(email: string, password: string, name: string) {
    return await firebase
      .auth()
      .signInWithEmailAndPassword(email, email)
      .then(async function(user) {
        var thisUser = firebase.auth().currentUser;
        if (thisUser?.emailVerified) {
          return await thisUser
            .updatePassword(password)
            .then(async function() {
              if (user.user?.uid) {
                logger.info(`[AUTH] ACCOUNT CREATED ${email}`);
                return await signInEmailPassword(email, password, name);
                // setUser(user.user);
                // //userEmail.current = user?.user?.email || '';
                // setLogUser(user.user?.email || 'NULL');
                // createProfile(user.user?.uid, name, user.user?.email);
              }
            })
            .catch(async function(error) {
              return 'Failed to activate account';
            });
        }
      })
      .catch((e: any) => {
        return 'Try a different email';
      });
  }

  async function assessEmail(email: string) {
    let createAttempt = await createUnverifiedAccount(email);
    switch (createAttempt) {
      case 'success':
        return 'newSent';
      case 'emailFail':
        return 'newEmailFail';
      case 'accountFail':
        let signInAttempt = await signInEmailPassword(email, email);
        switch (signInAttempt) {
          case 'needsInfo':
            return 'needsInfo';
          case 'unverified':
            return 'unverified';
          case 'fail':
            return 'hasInfo';
          default:
            return 'fail both';
        }
      default:
        return 'unexpected' + createAttempt;
    }
  }

  async function createAccountEmailPassword(email: string, password: string, name: string) {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setLogUser(USER_SIGNEDOUT);
        setProfile(null);
      });
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async user => {
        var actionCodeSettings = {
          url: `${window.location.origin}/login?email=${email}&redirect=${loginRedirect}`,
          handleCodeInApp: true,
        };
        const thisRes = await firebase
          .auth()
          .currentUser?.sendEmailVerification(actionCodeSettings)
          .then(function() {
            if (user.user?.uid && name) {
              addUser(user.user?.uid, name)
                .then((r: any) => {
                  console.log(r);
                })
                .catch((e: any) => console.log(e));
            }
            return 'Account creation successful. We sent you a verification email. Follow the link in the email to complete the creation of your account then sign in using the email and password you just set.';
          })
          .catch(function(error) {
            console.log('E', error);
            return 'We were unable to send a verification email to the email you entered. Please try again with a different email.';
          });
        return thisRes;
        //alert(`Account created!\nWe have created an account for email address ${email}. If this is the wrong address, please make a new account with the correct one or you will be unable to reset your password if you forget.`);
        // setUser(user.user);
        // console.log(user, user?.user?.email, user.user?.uid, name, user.user?.email);
        // //userEmail.current = user?.user?.email || '';
        // setLogUser(user.user?.email || 'NULL');
        // createProfile(user.user?.uid, name, user.user?.email);
        // return true
      })
      .catch(async error => {
        var thisRes = await signInEmailPassword(email, password);
        if ((thisRes = 'unverified')) {
        }
        return thisRes;
      });
    return res;
  }

  async function signInEmailPassword(email: string, password: string, name = '') {
    const res = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async user => {
        const currUser = firebase.auth().currentUser;
        if (currUser?.emailVerified) {
          if (email === password) {
            return 'needsInfo';
          } else {
            await currUser
              .updateProfile({
                displayName: name || user.user?.displayName || ' ',
              })
              .then(function() {
                logger.info(`[AUTH] Updated Auth display name - ${email} - ${name}`);
              })
              .catch(function(error) {
                logger.error(`[AUTH] FAILED update auth display name - ${email} - ${name}`);
              });

            userEmail.current = user?.user?.email || '';
            setLogUser(user.user?.email || 'NULL');
            await createProfile(user.user?.uid, name || user.user?.displayName || ' ', user.user?.email);
            await setUser(user.user);
            return 'success'; //'Success! Welcome to your Varty account';
          }
        } else {
          // var actionCodeSettings = {
          //   url: window.location.origin,
          //   handleCodeInApp: true,
          // };
          firebase.auth().currentUser?.sendEmailVerification();
          return 'unverified'; //'You have created an account but need to verify it before you can contine. We sent you another verfication link in case you missed the last one.';
        }
      })
      .catch(error => {
        logger.error(`[AUTH] Error detected. Error: , ${error} - ${email}`);
        console.log('Error detected. Error: ', error);
        return 'fail'; //'Incorrect email or password';
      });
    return res;
  }

  async function forgotPassword(email: string) {
    const res = await firebase
      .auth()
      .sendPasswordResetEmail(email, { url: `${window.location.origin}/login` })
      .then(function() {
        return true;
      })
      .catch(function(error) {
        return false;
      });
    return res;
  }

  function sendSignInEmail() {
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: 'http://varty.io/login',
      // This must be true.
      handleCodeInApp: true,
    };
    firebase
      .auth()
      .sendSignInLinkToEmail('eliwstewart10@yahoo.com', actionCodeSettings)
      .then(function() {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', 'eliwstewart10@yahoo.com');
      })
      .catch(function(error) {
        // Some error occurred, you can inspect the code: error.code
      });
  }

  function processSignInLink() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink('eliwstewart10@yahoo.com', window.location.href)
        .then(function(result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function(error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }

  const signOut = useCallback(() => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setLogUser(USER_SIGNEDOUT);
        setProfile(null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createProfile = async (uid: any, displayName: any, email: any) => {
    if (profile) return;
    if (uid === null) throw new Error('Uid is null in createProfile');

    await getUser(uid, 'createProfile')
      .then(res => {
        if (res.nickname === '') {
          res.nickname = res.displayName;
        }
        logger.info('[AUTH] Loaded existing User - ' + email);
        setProfile(res);
      })
      .catch(() => {
        console.log('ADDING NEW USER');
        addUser(uid, displayName).then(() => {
          let thisUser = newUser;
          thisUser.nickname = displayName;
          thisUser.displayName = displayName;
          logger.info('[AUTH] Created new User - ' + email);
          setProfile(thisUser);
          return;
        });
      });
  };

  const updateProfile = (data: any) => {
    setProfile({ ...profile, data });
  };

  return {
    assessEmail,
    updateVerifiedAccount,
    forgotPassword,
    createAccountEmailPassword,
    signInEmailPassword,
    sendSignInEmail,
    processSignInLink,
    setLoginRedirect,
    loginRedirect,
    user,
    getUser,
    signIn,
    signOut,
    isAuthReady,
    getToken,
    profile,
    updateProfile,
    userEmail,
  };
}
