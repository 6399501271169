import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab } from '@material-ui/core';
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import VartyListener, { Observer } from '../VideoProvider/listener';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { toast } from 'react-toastify';

type Props = {
  userID: string;
  roomID: string;
  myPlayerRef: RefObject<PlayerType>;
};
const BroadcastAudio = (props: Props) => {
  const search = new URLSearchParams(window.location.search);
  const [isRecording, setIsRecording] = useState(false);
  const [bufferQueue, setBufferQueue] = useState<Blob[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [, setChunks] = useState<Buffer[]>([]);
  const contx = useVideoContext();

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const receiveAudio = (userID: string, convoID: string, blob: Blob) => {
      if (convoID.length > 0 && convoID === props.myPlayerRef.current?.convoID) {
        return;
      }
      if (blob.size === 0) return;
      setBufferQueue(q => [...q, blob]);
    };
    VartyListener.attach(receiveAudio, Observer.receivedBroadcast);
    return () => {
      VartyListener.detach(receiveAudio, Observer.receivedBroadcast);
    };
  }, [props.myPlayerRef]);

  const updateBufferQueue = useCallback(() => {
    setIsPlaying(false);
    setBufferQueue(q => {
      if (!audioRef.current || q.length === 0) return q;
      const currentBlob = q[0];
      audioRef.current.src = window.URL.createObjectURL(currentBlob);
      audioRef.current.play();
      setIsPlaying(true);
      return [...q.slice(1)];
    });
  }, []);

  const audioObject = useMemo(() => <audio ref={audioRef} onEnded={updateBufferQueue} />, [updateBufferQueue]);

  useEffect(() => {
    if (!audioRef.current || bufferQueue.length === 0) return;
    if (isRecording) return;
    if (isPlaying) return;
    updateBufferQueue();
  }, [bufferQueue, isRecording, isPlaying, updateBufferQueue]);

  useEffect(() => {
    if (isRecording) {
      setIsPlaying(playing => {
        if (playing) audioRef.current?.pause();
        return playing;
      });
    } else {
      setIsPlaying(playing => {
        try {
          if (playing) audioRef.current?.play();
        } catch {}
        return playing;
      });
    }
  }, [isRecording]);

  const broadcastingAudio = useMemo(
    () => (
      <div
        style={{ color: 'white', marginLeft: 5 }}
        onClick={() => {
          if (isRecording) return;
          toast.info('Shouting to room (5 seconds)!');
          setChunks([]);
          setIsRecording(true);
          // @ts-ignore
          const mediaRecorder = new MediaRecorder(new MediaStream(contx.sound.stream?.getAudioTracks()));
          let order = 0;
          mediaRecorder.ondataavailable = (e: { data: Buffer }) => {
            setChunks(chunk => {
              return [...chunk, e.data];
            });
          };
          mediaRecorder.onstop = () => {
            setChunks(chunk => {
              const blob = new Blob(chunk, { type: 'audio/ogg; codecs=opus' });
              contx.backend.sendAudio(
                props.userID,
                props.roomID,
                props.myPlayerRef.current?.convoID || '',
                order,
                false,
                blob
              );
              return [];
            });
          };
          // Start recording
          mediaRecorder.start();
          // Stop recording after 5 seconds and broadcast it to server
          const restop = setInterval(() => {
            mediaRecorder.stop();
            mediaRecorder.start();
          }, [2000]);
          setTimeout(function() {
            clearInterval(restop);
            mediaRecorder.stop();
            setIsRecording(false);
          }, 5000);
        }}
      >
        <div style={{ alignItems: 'center' }}>
          <Fab
            style={{
              backgroundColor: '#1a1a1a',
              color: isRecording ? 'red' : 'white',
            }}
            size="small"
          >
            <FontAwesomeIcon icon={faBullhorn} />
          </Fab>
          <p
            style={{
              lineHeight: 0,
              marginTop: -1,
              marginLeft: 5,
              alignItems: 'center',
              alignSelf: 'center',
              fontSize: 10,
              color: 'white',
              fontFamily: 'Arial',
            }}
          >
            Shout
          </p>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRecording, props.myPlayerRef, props.roomID, props.userID]
  );
  return (
    <>
      {search.get('shout') != null ? broadcastingAudio : null}
      <div style={{ display: 'none' }}>{audioObject}</div>
    </>
  );
};

export default BroadcastAudio;
