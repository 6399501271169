import React, { useRef } from 'react';
import { ArrowState, UserMode } from './BarMapsEnums';

interface IBarSceneContext {
  myPlayerRef: React.MutableRefObject<PlayerType>;
  refStatus: {
    audioOn: boolean;
    videoOn: boolean;
  };
  players: AllPlayerType;
}

export const BarSceneContext = React.createContext<IBarSceneContext>(null!);

let playerSpawnX = 1000;
let playerSpawnY = 525;
let myPlayer: PlayerType = {
  curr_x: playerSpawnX,
  curr_y: playerSpawnY,
  click_x: playerSpawnX,
  click_y: playerSpawnY,
  inviteID: '',
  mode: UserMode.open,
  convoID: '',
  arrowState: ArrowState.inactive,
  socketID: '',
  private: false,
};
let refStatus = {
  audioOn: true,
  videoOn: true,
};
let players: AllPlayerType = {};

type BarSceneProviderProps = {
  children: React.ReactNode;
};

export const BarSceneProvider = ({ children }: BarSceneProviderProps) => {
  const myPlayerRef = useRef(myPlayer);

  return (
    <BarSceneContext.Provider
      value={{
        myPlayerRef,
        refStatus,
        players,
      }}
    >
      {children}
    </BarSceneContext.Provider>
  );
};
