import React, { createContext, useContext, useState } from 'react';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import { User } from 'firebase';

export interface StateContextType {
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  profile?:
    | any
    | null
    | {
        uid: undefined;
        nickname: undefined;
        photoURL: undefined;
        age: undefined;
        height: undefined;
        location: undefined;
        education: undefined;
        gender: undefined;
      };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  updateProfile?(user?: any): void;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  userEmail: any;
  setLoginRedirect: Function;
  loginRedirect: string;
  sendSignInEmail: Function;
  processSignInLink: Function;
  signInEmailPassword: Function;
  createAccountEmailPassword: Function;
  forgotPassword: Function;
  assessEmail: Function;
  updateVerifiedAccount: Function;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  var isFetching = false;
  const [activeSinkId, setActiveSinkId] = useState('default');
  let contextValue = {
    isFetching,
    activeSinkId,
    setActiveSinkId,
    ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  } as StateContextType;

  return <StateContext.Provider value={{ ...contextValue }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
