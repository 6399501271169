/* eslint-disable */
import React from 'react';
import { useAppState } from '../../state';
import './style.scss';

import 'firebase/firestore';
import 'firebase/storage';
import VideoApp from '../VideoApp/VideoApp';
import LoadingPage from '../LoadingPage/LoadingPage';

////
function TheHub() {
  const { profile } = useAppState();

  //WAIT till profile is loaded
  if (!profile?.uid) {
    return <LoadingPage />;
  }

  return <VideoApp roomKey={profile.uid} />;
}

export default TheHub;
