import React, { useEffect, useState, Fragment, lazy, Suspense } from 'react';
import * as PIXI from 'pixi.js-legacy';
import { SIDEBAR_WIDTH } from '../BarScene/BarScene';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {
  addPlayerToRoom,
  addPopupToUser,
  setUserRoomName,
  setHost,
  removePopupFromUser,
  updateUserMap,
  setRoomPremium,
  updateRegisteredAdmins,
  getRoomDesign,
  setRoomDesign,
} from '../../state/useFirebaseAuth/firebaseRoomActions';
import Dialog from '@material-ui/core/Dialog';
import { pathToUsermapUser, pathToUsermapAuth } from '../../state/useFirebaseAuth/firebasePaths';
import './canvas.css';
import { checkAccessToken, getAllHubStyles } from '../../state/useFirebaseAuth/firebaseHelper';
import { RoomType, LockType } from '../BarScene/BarMapsEnums';
import firebase from 'firebase/app';
import 'firebase/database';
import '../../types';
import PopUp from '../PopUp/PopUp';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import LoadingPage from '../LoadingPage/LoadingPage';
import { Button } from '@material-ui/core';
import { handleResize } from '../BarScene/BarSceneUtils';
import { logPage } from '../../Analytics';
import { Cull } from '@pixi-essentials/cull';
import { Viewport } from 'pixi-viewport';
import { PopUpType } from '../../components/BarScene/BarMapsEnums';
import errorSVG from '../../assets/img/errorNotification.svg';
import { v4 as uuidv4 } from 'uuid';
import { logger } from '../../Analytics';
// import { BrandingWatermarkSharp, Widgets } from '@material-ui/icons';
import { blobToText } from '../BarScene/BarSceneUtils';
// import { Console } from 'console';
import { useAppState } from '../../state';
import sha256 from 'sha256';
import { BarSceneProvider } from '../BarScene/BarSceneProvider';
const BarScene = lazy(() => import('../BarScene/BarScene'));
const PictureTaker = lazy(() => import('./PictureTaker'));
const EmailVerification = lazy(() => import('../EmailVerification/EmailVerification'));

const canvasSize = 600;
const pictureSize = 320;
const useStyles = makeStyles({
  popUpPaper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: 'black',
    borderRadius: 10,
  },
  popupText: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    color: 'black',
    fontFamily: 'Arial Black',
    fontSize: 25,
  },
});
let isNotRoom = false;
let notiKey = uuidv4().toString();
let popupKey = uuidv4().toString();
let warningKey = uuidv4().toString();
let querySet: boolean = false;
let tokenQueried = false;
let hubStyle: any = '';
// let availableRoom = false;
let blocked: boolean = false;
type Props = {
  capacity: number;
  count: number;
  baseRoomId: string;
  publicRoomData: string | boolean;
  roomId: string;
  userId: string;
  disablePictureTaker: boolean;
  webGLSupport: boolean;
};
function BarLoader({
  capacity,
  count,
  baseRoomId,
  publicRoomData,
  roomId,
  userId,
  disablePictureTaker,
  webGLSupport,
}: Props) {
  const [user, setUser] = useState<any>(null);
  const [roomType, setRoomType] = useState<number | null>(null);
  const [isLocked, setIsLocked] = useState<number | null>(null);
  const [auth, setAuth] = useState<string | null>(null);
  const [accessCode, setAccessCode] = useState<string | null>(null);
  const [roomName, setRoomName] = useState<string | null>(null);
  const [dialogScale, setDialogScale] = useState<number[]>([1, 1]);
  const [querySent, setQuerySent] = useState(false);
  const [isHub, setIsHub] = useState<number>(0);
  const [ready, setReady] = useState(false);
  const [isShowingPictureTaker, togglePictureTaker] = React.useState(true);
  const [responded, setResponded] = React.useState(false);
  const [image, setImage] = React.useState('');
  const [clicked, setClicked] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const classes = useStyles();
  const [app, setApp] = useState<PIXI.Application | null>(null);
  const [minimap, setMinimap] = useState<PIXI.Application | null>(null);
  const [isVerifyOpen, setIsVerifyOpen] = useState(0);
  const [roomAuth, setRoomAuth] = useState<string | null>(null);
  const [authList, setAuthList] = useState<string[]>([]);
  const [emailWhiteList, setEmailWhitelist] = useState<string[]>([]);
  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const { userEmail } = useAppState();
  const [hubList, setHubList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    getAllHubStyles().then(setHubList);

    if (!webGLSupport) {
      let popupKeyWebGL = uuidv4().toString();
      addPopupToUser(popupKeyWebGL, PopUpType.webGL, null, null, null, userId, userId, null, null);
    }
    var unsubscribe = firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .onSnapshot(function(doc) {
        const user = doc.data();
        if (user) {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const premiumToken = urlParams.get('p');

          const data = user?.badges?.['premium'];
          let isUserPremium: boolean = false;
          if (data?.seconds) {
            let dateMonth = new Date();
            dateMonth.setMonth(dateMonth.getMonth() + 1);
            let datePremium = new Date(data.seconds * 1000);
            isUserPremium = datePremium < dateMonth;
          }
          if (premiumToken) {
            let newURL = window.location.href;
            newURL = newURL.replace('p=attempted', '');
            window.history.replaceState(null, 'Varty', newURL);
            enqueueSnackbar(isUserPremium ? 'Premium enabled!' : 'Premium purchase failed', {
              variant: isUserPremium ? 'success' : 'error',
            });
          }
          if (isUserPremium && userId === baseRoomId) setRoomPremium(baseRoomId);
          setUser(user);
        }
      });

    const roomPath = pathToUsermapUser(baseRoomId);
    const roomRef = firebase.database().ref(roomPath);
    const authPath = pathToUsermapAuth(userId);
    const authRef = firebase.database().ref(authPath);
    // const roomAuthPath = pathToUsermapAuth(baseRoomId);
    // const roomAuthRef = firebase.database().ref(roomAuthPath);
    if (publicRoomData) {
      setRoomAuth(null);
      // getBackgroundData(baseRoomId).then((bgd: BackgroundData) => {
      //   if (bgd.purpose === 'room' || bgd.purpose === 'both') {
      //     availableRoom = true;
      //   }
      // });
      if (publicRoomData === 'none') {
        setRoomType(RoomType.OpenPublic);
      } else {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('t');
        if (token && !tokenQueried) {
          tokenQueried = true;
          checkAccessToken(token, baseRoomId).then((valid: boolean) => {
            if (valid) setRoomType(RoomType.OpenPublic);
            else setRoomType(RoomType.LockedPublic);
          });
        } else {
          setRoomType(RoomType.LockedPublic);
        }
      }
    } else {
      // roomAuthRef.once('value', (snap: any) => {
      //   setRoomAuth(snap.val());
      // });
      getRoomDesign(baseRoomId).then((design: any) => {
        console.log('%c We are in bar loader', 'color:blue;');
        console.log('got the design, and it is', design);
        if (design && design !== userId) hubStyle = design.toLowerCase();
        else hubStyle = design;
        if (design === userId || design === '') {
          console.log('We are in this situation');
          hubStyle = 'stylishhub';
          setRoomDesign(baseRoomId, hubStyle);
        }
        console.log('out of the situation');
      });
      if (baseRoomId === userId) {
        setHost(baseRoomId, userId);
        //setFreeSpeech(baseRoomId, true);
        setRoomType(RoomType.OpenPublic);
      }

      // roomRef.once('value', (snap: any) => {
      //   const val = snap.val();
      //   console.log(val)
      //   //IDK why the name check was necesary, but it was.
      //   let isHubtmp = val && val?.name !== undefined ? 2 : 1;
      //   // if (roomID === userId) isHubtmp = 2;
      //   if (isHubtmp === 2) {
      //     // if (val?.hubRoomName) setUserRoomName(userId, val.hubRoomName);
      //     // else setUserRoomNameUser(userId, baseRoomId);
      //     // if (val?.authentications) setAuthList(val.authentications);
      //     // if (val?.emailWhitelist) setEmailWhitelist(val.emailWhitelist);
      //     // if (val?.adminEmails) setAdminEmails(val.adminEmails);
      //     // if (val?.capacity) setCapacity(val.capacity || 25);
      //     setIsLocked(val?.isLocked);
      //     if (val?.isLocked && baseRoomId !== userId) {
      //       setRoomType(RoomType.LockedHub);
      //     } else {
      //       setRoomType(RoomType.OpenHub);
      //     }
      //   } else {
      //     setUserRoomName(userId, '');
      //   }
      //   setIsHub(isHubtmp);
      // });
      roomRef.child('isLocked').on('value', (snap: any) => {
        const val = snap.val();
        setIsLocked(val);
        if (val && baseRoomId !== userId) {
          setRoomType(RoomType.LockedHub);
        } else {
          setRoomType(RoomType.OpenHub);
        }
        setIsHub(2);
      });
      roomRef.child('adminEmailsEncrypted').on('value', (snap: any) => {
        const val = snap.val();
        setAdminEmails(val);
      });
      roomRef.child('emailWhitelistEncrypted').on('value', (snap: any) => {
        const val = snap.val();
        setEmailWhitelist(val);
      });
      roomRef.child('authenticationsEncrypted').on('value', (snap: any) => {
        const val = snap.val();
        setAuthList(val);
      });
      roomRef.child('accessCodeEncrypted').on('value', (snap: any) => {
        const val = snap.val();
        setAccessCode(val);
      });
      roomRef.child('hubRoomName').on('value', (snap: any) => {
        setRoomName(snap.exists() ? snap.val() : '');
      });
      roomRef.child('whitelist').on('value', (snap: any) => {
        const val = snap.val();
        if (val?.includes(userId)) {
          updateUserMap(baseRoomId, { whitelist: '' }, 'Bar Loader 1');
          setRoomType(RoomType.OpenHub);
        }
      });
      roomRef.child('blacklist').on('value', (snap: any) => {
        const val = snap.val();
        if (val?.includes(userId)) {
          updateUserMap(baseRoomId, { blacklist: '' }, 'Bar Loader 2');
          setRoomType(RoomType.LockedPublic);
        }
      });
      let offRoomSubscribed = false;
      roomRef.child('roomID').on('value', (snap: any) => {
        const val = snap.val();
        if (!val && userId !== baseRoomId && isHub && !offRoomSubscribed) {
          offRoomSubscribed = true;
          const action = (key: any) => (
            <Fragment>
              <Button
                onClick={() => {
                  closeSnackbar(key);
                  return;
                }}
              >
                Wait
              </Button>
              <Button
                onClick={() => {
                  closeSnackbar(key);
                  window.location.href = `${window.location.origin}/room/${userId}`;
                }}
              >
                Go to Hub
              </Button>
            </Fragment>
          );
          enqueueSnackbar('Host is not currently on the platform', {
            key: warningKey,
            variant: 'info',
            persist: true,
            action,
          });
        }
      });
    }
    authRef.once('value', (snap: any) => {
      setAuth(`@${snap?.val()?.toLowerCase()}`);
    });
    // const countPath = pathToBarRoomCount(roomId);
    // const countRef = firebase.database().ref(countPath);
    // countRef.on('value', (snap: any) => {
    //   const roomCounttmp = snap.val();
    //   if (!roomCounttmp) setRoomCount(0);
    //   else setRoomCount(roomCounttmp);
    // });

    window.onbeforeunload = () => {
      roomRef.child('whitelist').off('value');
      roomRef.child('blacklist').off('value');
      roomRef.child('roomID').off('value');
      roomRef.child('isLocked').off('value');
      roomRef.child('adminEmailsEncrypted').off('value');
      roomRef.child('emailWhitelistEncrypted').off('value');
      roomRef.child('authenticationsEncrypted').off('value');
      roomRef.child('accessCodeEncrypted').off('value');
      roomRef.child('hubRoomName').off('value');
      //countRef.off('value');
      unsubscribe();
      removePopupFromUser(popupKey, roomId);
    };
    if (disablePictureTaker) {
      blobToText(userId).then((e: any) => {
        setImage(e);
        setImageLoaded(true);
      });
      // .catch((e: any) => {
      //   console.log('%c ERROR with contacting server', 'color: blue;');
      //   faceSprite.texture = PIXI.Texture.from(VartyLogo.toString());
      //   console.log('Error contacting server, please refresh. Error code BS-LOAD-001');
      // });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logger.info(
      `[BARLOADER] Progress  - ${userEmail} - image: ${
        image ? 'image exists ' : 'doesnt exist'
      } imageLoaded: ${imageLoaded} app: ${app} minimap: ${minimap} user: ${user}`
    );
  }, [image, imageLoaded, app, minimap, user]);

  const updateScales = (dhArr: number[]) => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const dw = 510;
    let toSet: number[] = [];
    dhArr.forEach((dh: number) => {
      console.log(dw, dw, height, width);
      if (height < dh + 60 || width < dw + 60) {
        let scale = Math.min((height - 60) / dh, (width - 60) / dw);
        toSet.push(scale);
      }
    });
    setDialogScale(toSet);
  };

  useEffect(() => {
    updateScales([300, 200]);
    window.addEventListener('resize', () => updateScales([300, 200]));
    return () => {
      window.removeEventListener('resize', () => updateScales([300, 200]));
    };
  }, []);

  useEffect(() => {
    if (adminEmails && adminEmails.includes(sha256(userEmail.current.toLowerCase()))) {
      let data: any = {};
      data[userId] = true;
      updateRegisteredAdmins(baseRoomId, data);
      setUserIsAdmin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminEmails]);
  useEffect(() => {
    console.log(user, roomName);
    if (user && roomName !== null) {
      setUserRoomName(userId, roomName === '' ? `${user.nickname}'s Room` : roomName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, roomName]);
  useEffect(() => {
    if (!clicked) return;
    PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
    PIXI.settings.ROUND_PIXELS = true;
    setApp(a => {
      if (a) a.destroy(true);
      const params = {
        antialias: true,
        backgroundColor: 0x000,
        resolution: webGLSupport ? 1 : 0.7,
        autoDensity: true,
        // width: window.innerWidth - SIDEBAR_WIDTH,	        // width: window.innerWidth - SIDEBAR_WIDTH,
        // height: window.innerHeight,
        autoResize: true,
      };
      let myapp: PIXI.Application | null = null;
      // https://medium.com/javascript-in-plain-english/inside-pixijs-the-ultimate-scene-graph-optimization-35b62bb2153
      try {
        myapp = new PIXI.Application(params);
        const cull = new Cull();
        cull.add(myapp.stage);
        myapp.renderer.on('prerender', () => {
          // Cull out all objects that don't intersect with the screen
          if (myapp) {
            cull.cull(myapp.renderer.screen);
          }
        });
      } catch {
        myapp = new PIXI.Application({ ...params, forceCanvas: true });
      }
      const viewport = new Viewport({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        worldWidth: 1000,
        worldHeight: 1000,
        interaction: myapp.renderer.plugins.interaction, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
      });
      viewport.interactive = false;
      viewport.sortableChildren = true;
      // viewport
      //   .drag()
      //   .pinch()
      //   .wheel()
      //   .decelerate();
      if (myapp) {
        myapp.stage.addChild(viewport);
        myapp.stage.setChildIndex(viewport, 0);
        myapp.stage.interactive = false;
        myapp.stage.sortableChildren = true;
      }
      // myapp.renderer.resolution = 4;
      // myapp.renderer.resolution = 0.5;
      return myapp;
    });

    setMinimap(a => {
      if (a) a.destroy(true);
      const params = {
        backgroundColor: 0x000,
        resolution: 1,
        autoDensity: true,
        width: 185,
        height: 120,
      };
      let myapp: PIXI.Application | null = null;
      try {
        myapp = new PIXI.Application(params);
      } finally {
        myapp = new PIXI.Application({ ...params, forceCanvas: true });
      }
      if (myapp) {
        const container = new PIXI.ParticleContainer();
        container.name = 'particlecontainer';
        const backgroundContainer = new PIXI.Container();
        myapp.stage.addChild(backgroundContainer);
        myapp.stage.setChildIndex(backgroundContainer, 0);
        myapp.stage.addChild(container);
        myapp.stage.setChildIndex(container, 1);
      }
      return myapp;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked]);

  useEffect(() => {
    if (!app) return;
    const resize = () => handleResize(app, window.innerWidth - SIDEBAR_WIDTH, window.innerHeight);
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [app]);
  // useEffect(() => {
  //   if (roomCount && roomCount >= roomLimit && isHub === 1) {
  //     roomNumber += 1;
  //   }
  //   if (roomNumber) roomId = `${baseRoomId}-${roomNumber}`;
  // }, [roomCount, isHub]);
  //
  const sendQuery = () => {
    enqueueSnackbar('Waiting for permission to enter the room', {
      variant: 'info',
      persist: true,
      key: notiKey,
    });
    addPopupToUser(popupKey, PopUpType.entryRequest, user.nickname, null, null, baseRoomId, userId, null, null);
    setQuerySent(true);
  };
  const handleSubmitPassword = () => {
    const value = (document.getElementById('request-access') as HTMLInputElement).value;
    if (sha256(value) === accessCode) {
      removePopupFromUser(popupKey, baseRoomId);
      closeSnackbar(notiKey);
      setReady(true);
    } else {
      enqueueSnackbar('Wrong password, please try again', {
        variant: 'error',
        persist: false,
        key: '',
      });
    }
  };
  useEffect(() => {
    if (publicRoomData === false && isHub === 1 && !isNotRoom) {
      isNotRoom = true;
      logger.info(`[BARLOADER] Room does not exist - room: ${roomId}`);
      const action = (key: any) => (
        <Fragment>
          <Button
            onClick={() => {
              logger.info(`[BARLOADER] Room does not exist - Going to global bar`);
              closeSnackbar(key);
              window.location.href = `${window.location.origin}/room/global-lobby`;
            }}
          >
            Go to Global Lobby
          </Button>
          <Button
            onClick={() => {
              logger.info(`[BARLOADER] Room does not exist - Going to hub`);
              closeSnackbar(key);
              window.location.href = `${window.location.origin}/room/${userId}`;
            }}
          >
            Go to Hub
          </Button>
        </Fragment>
      );
      enqueueSnackbar('Room does not exist', {
        variant: 'error',
        persist: true,
        action,
      });
    } else {
      if (!user || ready) return;
      if (roomType === RoomType.OpenPublic) {
        removePopupFromUser(popupKey, baseRoomId);
        closeSnackbar(notiKey);
        setReady(true);
        return;
      } else if (roomType === RoomType.LockedPublic) {
        console.log('LockedPublic');
        let found: boolean = false;
        //console.log(auth,`@${roomAuth}`)
        // if (auth === `@${roomAuth}`) {
        //   found = true;
        //   removePopupFromUser(popupKey, baseRoomId);
        //   closeSnackbar(notiKey);
        //   setReady(true);
        //   return;
        // }
        if (publicRoomData === auth) {
          found = true;
          removePopupFromUser(popupKey, baseRoomId);
          closeSnackbar(notiKey);
          setReady(true);
          return;
        }
        if (user?.['badges']) {
          Object.keys(user?.['badges']).forEach((key: string) => {
            if (key.toLowerCase() === baseRoomId.toLowerCase() || key.toLowerCase() === publicRoomData) {
              found = true;
              removePopupFromUser(popupKey, baseRoomId);
              closeSnackbar(notiKey);
              setReady(true);
              return;
            }
          });
        }
        if (!found && !blocked) {
          blocked = true;
          enqueueSnackbar(`You do not have permission to enter this bar`, {
            variant: 'info',
          });
          window.location.href = `${window.location.origin}/hub`;
        }
      } else if (roomType === RoomType.OpenHub) {
        if (capacity && count >= capacity && !blocked && userId !== baseRoomId) {
          blocked = true;
          addPopupToUser(
            uuidv4().toString(),
            PopUpType.entryBlocked,
            user.nickname,
            null,
            null,
            baseRoomId,
            userId,
            null,
            null
          );
          enqueueSnackbar('Free room capacity limit reached', {
            variant: 'info',
          });
          window.location.href = `${window.location.origin}/hub`;
        }
        if (capacity && count <= capacity) removePopupFromUser(popupKey, baseRoomId);
        closeSnackbar(notiKey);
        setReady(true);
        return;
      } else if (roomType === RoomType.LockedHub) {
        if (!capacity) return;
        if (capacity && count >= capacity && !blocked) {
          blocked = true;
          addPopupToUser(
            uuidv4().toString(),
            PopUpType.entryBlocked,
            user.nickname,
            null,
            null,
            baseRoomId,
            userId,
            null,
            null
          );
          enqueueSnackbar('Room capacity limit reached', {
            variant: 'info',
          });
          window.location.href = `${window.location.origin}/hub`;
        } else if (
          (isLocked === LockType.lockedFriends && user.friends.includes(baseRoomId)) ||
          (emailWhiteList?.length && emailWhiteList?.includes(sha256(userEmail.current.toLowerCase()))) ||
            (adminEmails?.length && adminEmails?.includes(sha256(userEmail.current.toLowerCase())))
        ) {
          removePopupFromUser(popupKey, baseRoomId);
          closeSnackbar(notiKey);
          setReady(true);
          return;
        } else if (authList?.length && auth) {
          if (authList.includes(sha256(auth))) {
            removePopupFromUser(popupKey, baseRoomId);
            closeSnackbar(notiKey);
            setReady(true);
          } else {
            let found = false;
            if (user?.['badges']) {
              Object.keys(user?.['badges']).forEach((key: string) => {
                if (authList?.includes(sha256(`@${key}`))) {
                  found = true;
                  removePopupFromUser(popupKey, baseRoomId);
                  closeSnackbar(notiKey);
                  setReady(true);
                  return;
                }
              });
            }
            if (!found) {
              if (!querySet) {
                setIsVerifyOpen(1);
              }
            }
          }
        } else {
          setIsVerifyOpen(1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHub, isLocked, auth, roomAuth, authList, count, user, roomType, isShowingPictureTaker, image, capacity]);

  useEffect(() => {
    if (!ready || image === '' || !imageLoaded) return;
    closeSnackbar(warningKey);
    const userData = {
      click_x: -10000,
      click_y: -10000,
      curr_x: -10000,
      curr_y: -10000,
      age: user.age,
      displayName: user.displayName,
      education: user.education,
      gender: user.gender,
      height: user.height,
      location: user.location,
    };
    addPlayerToRoom(userId, roomId, user.avatar, user.displayName, userData);
    //updatePlayerPosition(userId, roomId, userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, image, imageLoaded]);
  //
  useEffect(() => {
    logger.info(`[BARLOADER] ENTERED  - ` + userEmail);
    logPage();
  }, []);
  if (!ready && (!isShowingPictureTaker || disablePictureTaker)) {
    return (
      <>
        <Dialog
          PaperProps={{
            style: {
              background: '#1a1a1a',
              color: '#ffffff',
              transform: `scale(${dialogScale[0]})`,
              height: '300px',
              minHeight: '300px',
              width: '500px',
              minWidth: '500px',
              position: 'relative',
              overflow: 'none',
            },
          }}
          open={isVerifyOpen === 1}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="hub-settings"
        >
          <div className="dialog-title">This Room Is Locked</div>
          <div className="secondary description">Need access? </div>
          <input
            id="request-access"
            style={{ display: 'none' }}
            disabled={querySent}
            onClick={() => sendQuery()}
          ></input>
          <label htmlFor="request-access">
            <div
              style={{
                textAlign: 'center',
                height: '30px',
                width: '260px',
                position: 'absolute',
                left: '120px',
                lineHeight: '30px',
                top: '100px',
                border: '1px solid',
                borderColor: querySent ? '#717171' : 'white',
                color: querySent ? '#717171' : 'white',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              <p>Request Access from Room Owner</p>
            </div>
          </label>
          <input
            id="subdomain"
            style={{ display: 'none' }}
            disabled={isVerifyOpen === 2 || authList === null}
            onClick={() => setIsVerifyOpen(2)}
          ></input>
          <label htmlFor="subdomain">
            <div
              style={{
                textAlign: 'center',
                height: '30px',
                width: '260px',
                position: 'absolute',
                left: '120px',
                lineHeight: '30px',
                top: '170px',
                border: '1px solid',
                borderColor: isVerifyOpen === 2 || authList === null ? '#717171' : 'white',
                color: isVerifyOpen === 2 || authList === null ? '#717171' : 'white',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              <p>Authenticate with Email Subdomain</p>
            </div>
          </label>
          <input
            id="password"
            style={{ display: 'none' }}
            disabled={isVerifyOpen === 3 || accessCode === null || accessCode === ''}
            onClick={() => setIsVerifyOpen(3)}
          ></input>
          <label htmlFor="password">
            <div
              style={{
                textAlign: 'center',
                height: '30px',
                width: '260px',
                position: 'absolute',
                left: '120px',
                lineHeight: '30px',
                top: '240px',
                border: '1px solid',
                borderColor: isVerifyOpen === 3 || accessCode === null || accessCode === '' ? '#717171' : 'white',
                color: isVerifyOpen === 3 || accessCode === null || accessCode === '' ? '#717171' : 'white',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              <p>Authenticate with Password</p>
            </div>
          </label>
        </Dialog>
        <Dialog
          PaperProps={{
            style: {
              background: '#1a1a1a',
              color: '#ffffff',
              transform: `scale(${dialogScale[1]})`,
              height: '200px',
              minHeight: '200px',
              width: '500px',
              minWidth: '500px',
              position: 'relative',
              overflow: 'none',
            },
          }}
          open={isVerifyOpen === 3}
          onClose={() => setIsVerifyOpen(1)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="hub-settings"
        >
          <div className="dialog-title">Enter the password for this room</div>
          <input
            id="request-access"
            style={{
              textAlign: 'center',
              height: '30px',
              width: '260px',
              position: 'absolute',
              left: '120px',
              lineHeight: '30px',
              top: '100px',
              border: '1px solid',
              borderColor: querySent ? '#717171' : 'white',
              color: querySent ? '#717171' : 'white',
              borderRadius: '5px',
              cursor: 'text',
              background: 'transparent',
            }}
          ></input>
          <input id="submit" style={{ display: 'none' }} onClick={() => handleSubmitPassword()}></input>
          <label htmlFor="submit">
            <div
              style={{
                textAlign: 'center',
                height: '30px',
                width: '60px',
                position: 'absolute',
                left: '400px',
                lineHeight: '30px',
                top: '140px',
                border: '1px solid',
                borderColor: 'white',
                color: 'white',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              <p>Submit</p>
            </div>
          </label>
        </Dialog>
        <Suspense fallback={null}>
          <EmailVerification
            clickState={false}
            Description={
              'You are currently not authorized to enter ths room. If you have an email with a subdomain associated with this room, please enter it now.'
            }
            ButtonAppearance={<></>}
            user={user}
            verifications={Array.isArray(authList) ? authList : ['']}
            roomId={baseRoomId}
            externalOpen={isVerifyOpen === 2}
            setExternalOpen={setIsVerifyOpen}
          />
        </Suspense>
        <LoadingPage />
      </>
    );
  }

  // span.finish();
  // transaction.finish();

  // Localhost picturetaker not an issue in production
  return isShowingPictureTaker && userId && !disablePictureTaker ? (
    clicked ? (
      <LoadingPage />
    ) : (
      <PopUp isShowing={isShowingPictureTaker} hide={togglePictureTaker} serverResponse={responded}>
        <Paper className={classes.popUpPaper} elevation={0}>
          <Suspense fallback={null}>
            <PictureTaker
              cameraWidth={pictureSize}
              cameraHeight={pictureSize}
              facingMode={'user'}
              canvasWidth={canvasSize}
              canvasHeight={canvasSize}
              userID={userId}
              responded={setResponded}
              imagur={setImage}
              togglePictureTaker={togglePictureTaker}
              setClicked={setClicked}
              setImageLoaded={setImageLoaded}
            />
          </Suspense>
        </Paper>
      </PopUp>
    )
  ) : image !== '' && imageLoaded && app && minimap && user ? (
    <BarSceneProvider>
      <Suspense fallback={<LoadingPage />}>
        <BarScene
          miniMap={minimap}
          app={app}
          avatar={user.avatar}
          name={user.nickname}
          image={image}
          roomID={roomId}
          userID={userId}
          hubStyle={hubStyle}
          hubList={hubList}
          isHub={isHub === 2}
          baseRoomID={baseRoomId}
          userIsAdmin={userIsAdmin || userId === baseRoomId}
        />
      </Suspense>
    </BarSceneProvider>
  ) : (
    <>
      <LoadingPage />
    </>
  );
}

let iconStyle = {
  height: '25px',
  width: 'auto',
  marginRight: '10px',
};

let entryBlockedIcon = <img style={iconStyle} src={errorSVG} alt="Blocked from walking"></img>;

type Props2 = {
  baseRoomId: string;
  publicRoomData: string | boolean;
  roomId: string;
  userId: string;
  disablePictureTaker: boolean;
  webGLSupport: boolean;
  capacity: number;
  count: number;
};

export default function BarLoaderWrapper({
  baseRoomId,
  roomId,
  userId,
  disablePictureTaker,
  publicRoomData,
  webGLSupport,
  capacity,
  count,
}: Props2) {
  return (
    <SnackbarProvider
      maxSnack={3}
      iconVariant={{
        info: entryBlockedIcon,
      }}
    >
      <BarLoader
        capacity={capacity}
        count={count}
        baseRoomId={baseRoomId}
        publicRoomData={publicRoomData}
        roomId={roomId}
        userId={userId}
        disablePictureTaker={disablePictureTaker}
        webGLSupport={webGLSupport}
      />
    </SnackbarProvider>
  );
}
