import { StreamsObject, User } from '../WebRTC/WebRTC';

type StreamUpdateObserver = (user: User | null, streamObject: StreamsObject | null) => void;
type ConnectionObserver = (action: string, user: User | null) => void;
type UserUpdateObserver = (user: User) => void;
type ReceivedBroadcastObserver = (userID: string, convoID: string, blob: Blob) => void;
type KurentoUpdateObserver = (message: string, presenters: [string, string][], queued: [string, string][]) => void;
type ActionObserver = (action: string) => void;
// type CallEnterObserver = ()

export enum Observer {
  stream = 'stream',
  user = 'user',
  receivedBroadcast = 'received-broadcast',
  kurento = 'kurento',
  action = 'action',
  connection = 'connection',
}

class EventListener {
  /****  Voice Server  ****/
  private streamUpdateObservers: StreamUpdateObserver[] = [];
  private userUpdateObservers: UserUpdateObserver[] = [];
  private connectionObservers: ConnectionObserver[] = [];
  /****  Normal Server ****/
  private receivedBroadcastObservers: ReceivedBroadcastObserver[] = [];
  private kurentoUpdateObservers: KurentoUpdateObserver[] = [];
  private actionObservers: ActionObserver[] = [];

  attach(observer: StreamUpdateObserver, type: Observer.stream): void;
  attach(observer: UserUpdateObserver, type: Observer.user): void;
  attach(observer: ConnectionObserver, type: Observer.connection): void;
  attach(observer: ReceivedBroadcastObserver, type: Observer.receivedBroadcast): void;
  attach(observer: KurentoUpdateObserver, type: Observer.kurento): void;
  attach(observer: ActionObserver, type: Observer.action): void;

  public attach(observer: any, type: Observer) {
    switch (type) {
      case Observer.stream:
        this.streamUpdateObservers.push(observer);
        break;
      case Observer.connection:
        this.connectionObservers.push(observer);
        break;
      case Observer.user:
        this.userUpdateObservers.push(observer);
        break;
      case Observer.receivedBroadcast:
        this.receivedBroadcastObservers.push(observer);
        break;
      case Observer.kurento:
        this.kurentoUpdateObservers.push(observer);
        break;
      case Observer.action:
        this.actionObservers.push(observer);
        break;
      default:
        break;
    }
  }

  detach(observer: StreamUpdateObserver, type: Observer.stream): void;
  detach(observer: UserUpdateObserver, type: Observer.user): void;
  detach(observer: ConnectionObserver, type: Observer.connection): void;
  detach(observer: ReceivedBroadcastObserver, type: Observer.receivedBroadcast): void;
  detach(observer: KurentoUpdateObserver, type: Observer.kurento): void;
  detach(observer: ActionObserver, type: Observer.action): void;

  public detach(observer: any, type: Observer) {
    switch (type) {
      case Observer.stream:
        this.streamUpdateObservers = this.streamUpdateObservers.filter(o => o !== observer);
        break;
      case Observer.user:
        this.userUpdateObservers = this.userUpdateObservers.filter(o => o !== observer);
        break;
      case Observer.connection:
        this.connectionObservers = this.connectionObservers.filter(o => o !== observer);
        break;
      case Observer.receivedBroadcast:
        this.receivedBroadcastObservers = this.receivedBroadcastObservers.filter(o => o !== observer);
        break;
      case Observer.kurento:
        this.kurentoUpdateObservers = this.kurentoUpdateObservers.filter(o => o !== observer);
        break;
      case Observer.action:
        this.actionObservers = this.actionObservers.filter(o => o !== observer);
        break;
      default:
        break;
    }
  }

  public notifyStream(user: User | null, streamObject: StreamsObject | null) {
    this.streamUpdateObservers.forEach(observer => observer(user, streamObject));
  }

  public notifyUser(user: User) {
    this.userUpdateObservers.forEach(observer => observer(user));
  }
  public notifyReceivedBroadcast(userID: string, convoID: string, blob: Blob) {
    this.receivedBroadcastObservers.forEach(observer => observer(userID, convoID, blob));
  }
  public notifyKurento(message: string, presenters: [string, string][], queued: [string, string][]) {
    this.kurentoUpdateObservers.forEach(observer => observer(message, presenters, queued));
  }
  public notifyAction(action: string) {
    this.actionObservers.forEach(observer => observer(action));
  }
  public notifyConnection(action: string, user: User | null) {
    this.connectionObservers.forEach(observer => observer(action, user));
  }
}

const VartyListener = new EventListener();
export default VartyListener;
