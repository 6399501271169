import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { Button, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';
import { User } from '../WebRTC/WebRTC';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import WebRTCScreenShare from '../WebRTC/WebRTCScreenShare';
import { Checkbox, Fab, FormControlLabel } from '@material-ui/core';
import './style.scss';
import { toast } from 'react-toastify';
import { isChrome, isFirefox, isSafari, isMacOs, isWindows } from 'react-device-detect';

const RoomScreenShare: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const { wrtc } = useVideoContext();
  const conn = wrtc?.conn;
  const usersList = wrtc?.conn?.users;
  const [screensharingUsers, setScreensharingUsers] = useState<User[]>([]);
  const [shareAudio, setShareAudio] = useState(false);

  const updateScreenshare = useCallback(() => {
    setScreensharingUsers(s => {
      const currentUsers = s.map(u => u.id);
      const socketID = wrtc?.conn?.socket.id;
      if (!socketID || !usersList) return s;
      const newME = Object.values(usersList).filter(u => u.screenShareOn && u.id !== socketID);
      if (currentUsers.length === newME.length) {
        for (const uu of newME) {
          if (!currentUsers.includes(uu.id)) {
            return newME;
          }
        }
        return s;
      }
      return newME;
    });
  }, [usersList, wrtc]);

  // TODO(kevinfang): Change this to a listen rather than a poll
  useEffect(() => {
    const ind = setInterval(() => {
      updateScreenshare();
    }, 5000);
    return () => {
      clearInterval(ind);
    };
  }, [updateScreenshare]);

  const video = useMemo(
    () => (
      <video
        ref={(myvideo): void => {
          if (myvideo) {
            myvideo.srcObject = stream;
            myvideo.volume = 1;
          }
        }}
        height="100%"
        width="100%"
        autoPlay
      />
    ),
    [stream]
  );

  const screensharingButton = useMemo(
    () => (
      <div
        style={{ color: 'white', marginLeft: 5 }}
        onClick={() => {
          setIsOpen(o => !o);
          updateScreenshare();
        }}
      >
        <div style={{ alignItems: 'center' }}>
          <Fab
            style={{
              backgroundColor: '#1a1a1a',
              color: isScreenSharing ? 'red' : screensharingUsers.length > 0 ? 'orange' : 'white',
            }}
            size="small"
          >
            <FontAwesomeIcon icon={faDesktop} />
          </Fab>
          <p
            style={{
              lineHeight: 0,
              marginTop: -1,
              marginLeft: 7,
              alignItems: 'center',
              alignSelf: 'center',
              fontSize: 10,
              color: 'white',
              fontFamily: 'Arial',
            }}
          >
            Share
          </p>
        </div>
      </div>
    ),
    [isScreenSharing, screensharingUsers.length, updateScreenshare]
  );

  useEffect(() => {
    if (!wrtc || !conn) return;
    WebRTCScreenShare.initialScreenShare(wrtc);
    return (): void => {
      WebRTCScreenShare.destroyScreenShare(wrtc);
      setIsScreenSharing(false);
      setIsOpen(false);
    };
  }, [wrtc, conn]);

  useEffect(() => {
    // Auto screenshare
    if (isOpen && screensharingUsers.length > 0) {
      if (!wrtc?.conn) {
        return;
      }
      if (isScreenSharing) {
        const socketID = wrtc.conn.socket.id;
        const isNotGreatestUser = screensharingUsers.filter(u => u.id > socketID).length > 0;
        if (isNotGreatestUser) {
          WebRTCScreenShare.updateScreenShare(wrtc, false, shareAudio, (status, mystream) => {
            setIsScreenSharing(status);
            toast.warn('Someone else is already screensharing!');
            setIsOpen(false);
          });
        }
        return;
      }
      const u = screensharingUsers[0];
      WebRTCScreenShare.listenToScreenShare(
        wrtc,
        u,
        track => {
          setUser(u);
          setStream(s => {
            if (s) {
              return new MediaStream(s.getTracks().concat([track]));
            } else {
              ReactGA.event({
                category: 'Room',
                action: 'joinscreenshare',
              });
              return new MediaStream([track]);
            }
          });
          track.onended = (): void => {
            setStream(null);
          };
        },
        () => {
          setUser(null);
          setStream(null);
        }
      );
    }
  }, [isOpen, isScreenSharing, screensharingUsers, shareAudio, wrtc]);

  const memoStuff = useMemo(
    () => (
      <>
        <div className="text-right roomscreenshare-exit-button">
          <Button
            onClick={(): void => {
              if (!wrtc?.conn) {
                setIsOpen(false);
                return;
              }
              if (user && wrtc.conn.screenConnections[user.id]) {
                wrtc.conn.screenConnections[user.id].close();
                delete wrtc.conn.screenConnections[user.id];
                setUser(null);
                setStream(null);
              }
              setIsOpen(false);
            }}
            block
            style={{ textAlign: 'right' }}
            color="white"
          >
            Close window
          </Button>
        </div>
        {stream ? (
          video
        ) : (
          <div className="p-4 text-center">
            <Button
              onClick={(): void => {
                if (!wrtc) return;
                WebRTCScreenShare.updateScreenShare(wrtc, !isScreenSharing, shareAudio, (status, mystream) => {
                  setIsScreenSharing(status);
                  if (mystream) {
                    mystream.getVideoTracks()[0].onended = (): void => {
                      WebRTCScreenShare.updateScreenShare(wrtc, false, shareAudio, () => {
                        setIsScreenSharing(false);
                      });
                    };
                  }
                  ReactGA.event({
                    category: 'Room',
                    action: 'screenshare',
                    value: status ? 1 : 0,
                  });
                });
              }}
              color={isScreenSharing ? 'danger' : 'primary'}
              className="my-4"
            >
              {isScreenSharing ? 'Stop Screensharing' : 'Start Screensharing'}
            </Button>
            <br />
            {!isFirefox && !isSafari ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shareAudio}
                    onChange={() => setShareAudio(a => !a)}
                    name="checkedB"
                    color="primary"
                  />
                }
                disabled={isScreenSharing}
                label="Share audio from computer"
              />
            ) : null}
            {(isChrome && isMacOs) || (!isWindows && !isMacOs) /* Linux */ ? (
              <p>
                You can only share audio if you share a specific tab <br /> (and also click the share audio checkbox in
                the next menu screen!)
              </p>
            ) : null}
          </div>
        )}
      </>
    ),
    [shareAudio, stream, isScreenSharing, video, wrtc, user]
  );

  const modalMemo = useMemo(
    () => (
      <Modal
        isOpen={isOpen}
        size="lg"
        className={`roomscreenshare-modal${stream ? ' sharing' : ''}`}
        toggle={() => setIsOpen(false)}
      >
        {isOpen ? memoStuff : null}
      </Modal>
    ),
    [isOpen, memoStuff, stream]
  );

  return (
    <>
      {modalMemo}
      {screensharingButton}
    </>
  );
};

export default React.memo(RoomScreenShare);
