import { useCallback } from 'react';
import VartyListener from './listener';
import io from 'socket.io-client';
import { currConvo } from '../BarScene/BarScene';
import WebRTCKurento, { KurentoMessage } from '../WebRTC/WebRTCKurento';
import { addPopupToUser, updatePlayerPosition } from '../../state/useFirebaseAuth/firebaseRoomActions';
import { logger } from '../../Analytics';
import WebRTC, { RemoveStreamFunction, WebRTCHelper } from '../WebRTC/WebRTC';
import { v4 as uuidv4 } from 'uuid';
import { PopUpType, BroadcastType } from '../BarScene/BarMapsEnums';

export type Backend = {
  enterRoom: (userID: string, roomID: string, myPlayerRef: React.MutableRefObject<PlayerType>) => void;
  exitRoom: () => void;
  joinConvo: (convoID: string) => void;
  sendAudio: (userID: string, roomID: string, convoID: string, order: number, last: boolean, blob: Blob) => void;
  sendHeartbeat: (userID: string, roomID: string, convoID: string) => void;
  sendImage: (userID: string, imageName: string) => void;
};

const useBackend = (
  wrtc: WebRTCHelper | null,
  removedStream: RemoveStreamFunction,
  clearAllStreams: Function
): Backend => {
  /**
   * So, since everything is destroyed and rebuilt when it comes to the change of anything, we do not need to
   * ever reset the interval that sends the conversational data to the backend.
   *
   * This is good! Because we can begin tracking the conversation in a manner that we never were able to before. Furthermore, this means
   * we can track the conversations in a different way than we track room tracking.
   *
   */

  console.log('%c Conversational reset???\n');

  const enterRoom = useCallback(
    (userID: string, roomID: string, myPlayerRef: React.MutableRefObject<PlayerType>) => {
      if (!wrtc) return;
      if (wrtc.backconn) {
        wrtc.backconn.socket.close();
        if (wrtc.backconn.pulseInterval) {
          clearInterval(wrtc.backconn.pulseInterval);
        }
        if (wrtc.backconn.kurento.presenter) {
          wrtc.backconn.kurento.presenter.dispose();
        }
        Object.values(wrtc.backconn.kurento.viewers).forEach(peer => peer.dispose());
        wrtc.backconn.kurento.stream.merger?.destroy();
        wrtc.backconn = null;
      }
      let options: SocketIOClient.ConnectOpts = { path: '/api/socket.io' };
      if (process.env.REACT_APP_USE_APP_ENGINE) {
        options.host = 'https://varty-backend.uc.r.appspot.com';
      }
      const mysocket = io(options);
      wrtc.backconn = {
        socket: mysocket,
        pulseInterval: setInterval(() => {
          const data = { profileID: userID, roomID: roomID, convoID: currConvo };
          mysocket.emit('heartbeat', data);
        }, 3000),
        presentTogether: true,
        kurento: {
          presenter: null,
          viewers: {},
          viewingIDs: [],
          stream: {
            merger: null,
            selected: 'webcam',
            current: null,
            added: {},
          },
        },
      };
      const initdata = { profileID: userID, roomID: roomID };
      mysocket.on('connect', () => {
        mysocket.emit('init', initdata, myPlayerRef.current?.convoID || '');
        if (myPlayerRef.current) {
          updatePlayerPosition(userID, roomID, myPlayerRef.current);
        }
        if (wrtc?.backconn) WebRTCKurento.ping(wrtc?.backconn);
        setTimeout(() => {
          if (wrtc?.backconn) WebRTCKurento.ping(wrtc?.backconn);
        }, 3000);
      });

      mysocket.on('disconnect', (reason: string) => {
        //Signal for disconnect comes in here

        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server, you need to reconnect manually
          logger.error('[BACKEND] DISCONNECTED');
          WebRTC.disconnect(wrtc, removedStream);
        }
      });

      mysocket.on(
        'audio-broadcast',
        (
          otherUserID: string,
          otherRoomID: string,
          convoID: string,
          order: number,
          last: boolean,
          blobString: string
        ) => {
          if (roomID !== otherRoomID) return;
          // TO TEST COMMENT THE LINE BELOW
          if (otherUserID === userID) return;
          // console.log(blobString);
          // console.log("RECEIVED AUDIO BROADCASAT");
          const blob = new Blob([blobString], { type: 'audio/ogg; codecs=opus' });
          VartyListener.notifyReceivedBroadcast(otherUserID, convoID, blob);
        }
      );
      mysocket.on('kurento-message', async (message: string) => {
        if (!wrtc.backconn) {
          logger.info('[KURENTO] Connection nil');
          return;
        }
        const json: KurentoMessage = JSON.parse(message);
        switch (json.id) {
          case 'presenterResponse':
            WebRTCKurento.response(wrtc.backconn, json, true);
            break;
          case 'viewerResponse':
            WebRTCKurento.response(wrtc.backconn, json, false);
            break;
          case 'stopCommunicationViewer':
            WebRTCKurento.endConnection(wrtc.backconn, false, false, json.socketID);
            break;
          case 'stopCommunicationPresenter':
            WebRTCKurento.endConnection(wrtc.backconn, false, true, json.socketID);
            break;
          case 'iceCandidatePresenter':
            if (wrtc.backconn.kurento.presenter && json.candidate) {
              wrtc.backconn.kurento.presenter.addIceCandidate(json.candidate);
            }
            break;
          case 'iceCandidateViewer':
            if (wrtc.backconn.kurento.viewers[json.socketID] && json.candidate) {
              wrtc.backconn.kurento.viewers[json.socketID].addIceCandidate(json.candidate);
            }
            break;
          default:
            console.error('Unrecognized message', json);
        }
      });

      mysocket.on('twilio', (iceServers: string) => {
        if (wrtc) {
          wrtc.twilioIceServers = JSON.parse(iceServers);
        }
      });

      mysocket.on('delete', () => {
        window.location.reload();
      });

      mysocket.on('kurento-ping', (presenters: [string, string][], queued: [string, string][]) => {
        VartyListener.notifyKurento('', presenters, queued);
      });
      mysocket.on('kick', () => {
        VartyListener.notifyAction('kick');
      });
      mysocket.on('kurento-kick', () => {
        addPopupToUser(
          uuidv4().toString(),
          PopUpType.broadcast,
          null,
          null,
          null,
          userID,
          null,
          null,
          BroadcastType.warning
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wrtc]
  );

  const joinConvo = useCallback(
    (convoID: string) => {
      if (!wrtc) return;
      if (!wrtc.backconn?.socket) return;
      console.log('Reset through sending joining a conversation');
      wrtc.backconn.socket.emit('join-convo', convoID);
    },
    [wrtc]
  );

  const sendHeartbeat = useCallback(
    (userID: string, roomID: string, convoID: string) => {
      console.log('Reset through sending heartbeat');
      if (!wrtc) return;
      if (!wrtc.backconn?.socket) return;
      const data = { profileID: userID, roomID: roomID, convoID: convoID };
      wrtc.backconn?.socket.emit('heartbeat', data);
    },
    [wrtc]
  );

  const exitRoom = useCallback(() => {
    if (!wrtc) return;
    if (wrtc.backconn) {
      wrtc.backconn.socket.close();
      if (wrtc.backconn.pulseInterval) {
        clearInterval(wrtc.backconn.pulseInterval);
      }
      wrtc.backconn = null;
    }
  }, [wrtc]);

  const sendAudio = useCallback(
    (userID: string, roomID: string, convoID: string, order: number, last: boolean, blob: Blob) => {
      if (!wrtc) return;
      if (wrtc.backconn) wrtc.backconn.socket.emit('audio-broadcast', userID, roomID, convoID, order, last, blob);
    },
    [wrtc]
  );

  const sendImage = useCallback(
    (userID: string, imageName: string) => {
      console.log('inside use backend');
      if (!wrtc) return;
      console.log('wrtc exists. I think this is a helper');
      // if (wrtc.backconn) {
      console.log('we have a connection');
      wrtc.backconn?.socket.emit('userBackgroundImage', userID, imageName) || console.log('object was null');
      console.log('Sent!');
      // }
    },
    [wrtc]
  );

  return {
    enterRoom,
    exitRoom,
    joinConvo,
    sendAudio,
    sendHeartbeat,
    sendImage,
  };
};

export default useBackend;
