import * as PIXI from 'pixi.js-legacy';
// import { BarData } from './BarMapsEnums';

import { downloadImage, downloadBackground } from '../../state/useFirebaseAuth/firebaseHelper';
import { spriteSize } from './BarScene';
import { logger } from '../../Analytics';

const handleResize = (app: PIXI.Application, w: number, h: number) => {
  app.renderer.resize(w, h);
  let background = app.stage.getChildByName('background') as PIXI.Graphics;
  if (!background) return;
  background.width = w;
  background.height = h;
};

async function backgroundBlobToBase64(folder: string, file: string): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      const imageBlob = await downloadBackground(folder, file);
      var reader = new FileReader();
      reader.onloadend = function(event: ProgressEvent<FileReader>) {
        const base64String = reader.result;
        if (typeof base64String === 'string') {
          resolve(base64String);
        } else {
          reject();
          logger.error('Image not base64');
        }
      };
      reader.readAsDataURL(imageBlob);
    } catch (e) {
      console.log('Could not reach server. Please refresh. Error Code BTT-DOWN-P-001'); // Blob to text download image promise first in func
      reject();
    }
  });
}

async function blobToText(key: string, file: string = 'currImage', folder: string = 'images') {
  let imageBlob: any;

  await downloadImage(key, file, folder)
    .then((str: Blob | string) => {
      imageBlob = str;
      if (typeof imageBlob === 'string') return imageBlob;
    })
    .catch((e: any) => {
      console.log('Could not reach server. Please refresh. Error Code BTT-DOWN-P-001'); // Blob to text download image promise first in func
    });
  return await new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onloadend = function(e: ProgressEvent<FileReader>) {
      var base64String = reader.result;
      resolve(base64String);
    };
    reader.readAsDataURL(imageBlob);
  }).catch((e: ProgressEvent<FileReader>) => {
    // blob to text, promise, second possible place in the function.
    console.log("Error Code BTT-P-002. Firebase didn't find the image. Error message: ", e);
  });
}

const sendEmail = async (email: string, message: string, subject: string, html: string) => {
  fetch('/token', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ email: email, message: message, subject: subject, html: html }),
  });
};

const barBounds = (points: number[], lineFill: number = 25): any => {
  /**
   * points: an array of points that will be interpreted as
   * [x,y, x,y, x,y, x,y, ...]
   * that will then create the polygon in question and
   * return it.
   *
   * Returns:
   * boundingPoly:  this is the actual polygon that we will be checking.
   * (PIXI.Polygon) Why? There is a problem with PIXI's containsPoint
   *                method on a graphics object. As such, we need to use
   *                a separate graphics object for visual debugging
   *
   * boundingPolyGraphics: A visual object that will help us with debugging
   * (PIXI.Graphics)       the location of the boundingPolygon. Not needed in
   *                       the final game.
   *
   */

  let boundingPoly = new PIXI.Polygon(points);
  let boundingPolyGraphics = new PIXI.Graphics()
    .beginFill(0x000, 0)
    .lineStyle(lineFill, 0xff0000) // enable this to see the bounding polygon
    .drawPolygon(points)
    .endFill();
  boundingPolyGraphics.name = 'boundingPolyGraphics';
  boundingPolyGraphics.interactive = true;

  return { boundingPoly, boundingPolyGraphics };
};

const barBoundsPoly = (points: number[]): PIXI.Polygon => {
  /** points: an array of points that will be interpreted as
   * [x,y, x,y, x,y, x,y, ...]
   * that will then create the polygon in question and
   * return it. Wrapper function for creating a PIXI.Polygon.
   *
   * Returns:
   * boundingPoly:  this is the actual polygon that we will be checking.
   * (PIXI.Polygon) Why? There is a problem with PIXI's containsPoint
   *                method on a graphics object. As such, we need to use
   *                a separate graphics object for visual debugging
   */
  if (!points?.length) return new PIXI.Polygon([0, 0, 100, 0, 100, 100, 0, 100]);
  let boundingPoly = new PIXI.Polygon(points);
  return boundingPoly;
};

const barBoundsGraphics = (points: number[], lineFill: number = 25): PIXI.Graphics => {
  /** points: an array of points that will be interpreted as
   * [x,y, x,y, x,y, x,y, ...]
   * that will then create the Graphic object in question and
   * return it. Wrapper function for PIXI.Graphics creator.
   *
   * boundingPolyGraphics: A visual object that will help us with debugging
   * (PIXI.Graphics)       the location of the boundingPolygon. Not needed in
   *                       the final game.
   *
   * Making this commit incase I fail at pulling from master
   */

  let boundingPolyGraphics = new PIXI.Graphics()
    .beginFill(0x000, 0)
    .lineStyle(lineFill, 0xff0000) // enable this to see the bounding polygon
    .drawPolygon(points)
    .endFill();
  boundingPolyGraphics.name = 'boundingPolyGraphics';
  boundingPolyGraphics.interactive = true;

  return boundingPolyGraphics;
};

const isBlocked = (boundingPoly: PIXI.Polygon, currX: number, currY: number, step: number): any => {
  // TODO: Who wrote this? Why are there magic numbers?
  // @Eli, I'm starting with you.
  currX += 2;
  currY += 2;
  let blocked = true;
  let topLeft: PIXI.Point = new PIXI.Point(currX, currY); // Typical sprite point
  let topRight: PIXI.Point = new PIXI.Point(currX + spriteSize[0] - 4, currY); // adding the width to the x gives the right position
  let bottomLeft: PIXI.Point = new PIXI.Point(currX, currY + spriteSize[1] - 4); // Adding y will give is the down position
  let bottomRight: PIXI.Point = new PIXI.Point(currX + spriteSize[0] - 4, currY + spriteSize[1] - 4); // Adding both will give the furthest point from the calc point
  const allCorners = [topLeft, topRight, bottomLeft, bottomRight];
  allCorners.forEach((corner: PIXI.Point) => {
    blocked = blocked && !boundingPoly.contains(corner.x, corner.y);
  });

  return blocked;
};

const enforceBarriers = (
  boundingPoly: PIXI.Polygon, // By passing in the polygon, we enable moving this function out of barScene.
  currX: number,
  currY: number,
  nextX: number,
  nextY: number,
  xString: string,
  yString: string,
  noRecurse = false
): any => {
  if (nextX < 1) nextX = 0;
  if (nextY < 1) nextY = 0;

  let trueX = nextX;

  let trueY = nextY;

  let topLeft: PIXI.Point = new PIXI.Point(nextX, nextY); // Typical sprite point
  let topRight: PIXI.Point = new PIXI.Point(nextX + spriteSize[0], nextY); // adding the width to the x gives the right position
  let bottomLeft: PIXI.Point = new PIXI.Point(nextX, nextY + spriteSize[1]); // Adding y will give is the down position
  let bottomRight: PIXI.Point = new PIXI.Point(nextX + spriteSize[0], nextY + spriteSize[1]); // Adding both will give the furthest point from the calc point

  /**
   * This is complicated logic, but let me help you break it down.
   *
   * We need to understand that there are three states for both x and y:
   * y: up, down, not pressed
   * x: left, right, not pressed
   *
   * This logic goes through these 9 states and calculates if the next position is valid,
   * or if the sprite needs to stay there
   *
   * UP: check if we hit something on the top. If so, dont move up
   *     BUT, if the sprite hits a wall and is moving up, one point will be valid,
   *     but the other wont be. In this case we still need to move the sprite upwards.
   *
   *     LEFT: when the sprite is moving up-left, if the left point is valid, the sprite
   *           must keep going left, but must no longer go up.
   *
   * The following logic does this in code for all nine states.
   */
  switch (yString) {
    case 'up': {
      let TL_in: boolean = boundingPoly.contains(topLeft.x, topLeft.y);
      let TR_in: boolean = boundingPoly.contains(topRight.x, topRight.y);
      if (!TL_in || !TR_in) {
        trueY = currY;
      }
      if (xString === 'right') {
        let BR_in: boolean = boundingPoly.contains(bottomRight.x, bottomRight.y);
        if (!BR_in) {
          trueX = currX;
          trueY = !TR_in && TL_in ? nextY : currY;
        }
        break;
      } else if (xString === 'left') {
        let BL_in: boolean = boundingPoly.contains(bottomLeft.x, bottomLeft.y);
        if (!BL_in) {
          trueX = currX;
          trueY = !TL_in && TR_in ? nextY : currY;
        }
        break;
      }
      break;
    }
    case 'down': {
      let BL_in: boolean = boundingPoly.contains(bottomLeft.x, bottomLeft.y);
      let BR_in: boolean = boundingPoly.contains(bottomRight.x, bottomRight.y);
      if (!BL_in || !BR_in) {
        trueY = currY;
      }
      if (xString === 'right') {
        let TR_in: boolean = boundingPoly.contains(topRight.x, topRight.y);
        if (!TR_in) {
          trueX = currX;
          trueY = !BR_in && BL_in ? nextY : currY;
        }
        break;
      } else if (xString === 'left') {
        let TL_in: boolean = boundingPoly.contains(topLeft.x, topLeft.y);
        if (!TL_in) {
          trueX = currX;
          trueY = !BL_in && BR_in ? nextY : currY;
        }
        break;
      }
      break;
    }
    default: {
      let TR_in: boolean = boundingPoly.contains(topRight.x, topRight.y);
      let BR_in: boolean = boundingPoly.contains(bottomRight.x, bottomRight.y);
      let BL_in: boolean = boundingPoly.contains(bottomLeft.x, bottomLeft.y);
      let TL_in: boolean = boundingPoly.contains(topLeft.x, topLeft.y);
      if (xString === 'right') {
        if (!TR_in || !BR_in) {
          trueX = currX;
        }
      } else if (xString === 'left') {
        if (!TL_in || !BL_in) {
          trueX = currX;
        }
      }
    }
  }
  return { trueX, trueY };
};

export {
  isBlocked,
  blobToText,
  backgroundBlobToBase64,
  barBounds,
  enforceBarriers,
  handleResize,
  sendEmail,
  barBoundsGraphics,
  barBoundsPoly,
};
