import React, { useState, useEffect } from 'react';
import { useAppState } from '../../state';
import LoadingPage from '../LoadingPage/LoadingPage';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './MainScene';
import { logger } from '../../Analytics';
import FloorFinder from '../BarLoader/FloorFinder';

export default function MainScene(props: any) {
  const { roomKey } = props;
  const [roomName, setRoomName] = useState<any>('');
  const { profile } = useAppState();

  useEffect(() => {
    if (roomKey) {
      setRoomName(roomKey);
      window.history.replaceState(null, 'Varty', `/room/${roomKey}`);
    }
  }, [roomKey]);

  if (roomName === '' || profile == null || !profile.uid) {
    return <LoadingPage />;
  }

  console.log('MAIN SCENE hub');

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error: Error, componentStack: string) => {
        logger.error('[BOUNDARY] Stack: ' + componentStack + ' : ' + error.message + '\n[BOUNDARY] ENDSTACK:');
      }}
      onReset={() => {
        logger.error('[BOUNDARY] Refreshing page');
        window.location.reload();
      }}
    >
      <FloorFinder roomID={roomKey} userID={profile.uid} webGLSupport={props.webGLSupport} />
    </ErrorBoundary>
  );
}
