// ------------------------------------------------------- Libraries -------------------------------------------------------
import React, { useState, useEffect, useRef, useMemo, useContext, lazy, Suspense } from 'react';
import * as PIXI from 'pixi.js-legacy';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app';
import 'firebase/database';
import { Dialog } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Viewport } from 'pixi-viewport';

import Snackbar from '@material-ui/core/Snackbar';
/** ------------------------------------------------------- Sockets/Backend ------------------------------------------------------- */
import WebRTC, { StreamsObject, User } from '../WebRTC/WebRTC';

// ------------------------------------------------------- Pictures -------------------------------------------------------
import * as tutorial from '../../assets/img/arrowKeys.png';
import * as VartyLogo from '../../assets/img/failedLoad.png';
import * as micOff from '../../assets/img/micOff.png';
import * as cameraOff from '../../assets/img/cameraOff.png';

// ------------------------------------------------------- Functions -------------------------------------------------------
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import {
  pathToBarRoomPlayers,
  pathToBarRoomInvites,
  pathToBarRoomConvos,
  pathToBarRoomDesign,
  pathToBarRoomRemoves,
  pathToBarRoomHost,
  pathToBarRoomPlayer,
  pathToBarRoomNameTag,
} from '../../state/useFirebaseAuth/firebasePaths';
import {
  removeRemovalFromRoom,
  addInviteToRoom,
  updateInvite,
  removeInviteFromRoom,
  addConvoToRoom,
  updateConvo,
  setRoomDesign,
  setHost,
  updatePlayerPosition,
} from '../../state/useFirebaseAuth/firebaseRoomActions';
import DynamicControl from '../Controls/DynamicControl';
import PopUpConfirmAuto from '../PopUpConfirm/PopUpConfirmAuto';
import {
  blobToText,
  enforceBarriers,
  barBoundsPoly,
  barBoundsGraphics,
  backgroundBlobToBase64,
  isBlocked,
} from './BarSceneUtils';
import { BroadcastMode, UserMode, InviteState, ArrowState, glowMap, SpriteLayers } from './BarMapsEnums';
import VartyListener, { Observer } from '../VideoProvider/listener';
import { isDevelopment, logger } from '../../Analytics';
import { getHubStylesBackgroundData, getUserBackgroundData } from '../../state/useFirebaseAuth/firebaseHelper';
import RoomScreenShare from '../Room/RoomScreenShare';
import BroadcastAudio from '../MainControls/BroadcastAudio';
import LoadingPage from '../LoadingPage/LoadingPage';
import { useAppState } from '../../state';
import { BarSceneContext } from './BarSceneProvider';
import { isFirefox, isSafari } from 'react-device-detect';
const VideoComponent = lazy(() => import('../VideoProvider/VideoComponent'));
const MainControls = lazy(() => import('../MainControls/MainControls'));
const NotificationNotistack = lazy(() => import('../NotificationList/NotificationNotistack'));

// import { DataSnapshot } from 'firebase-functions/lib/providers/database';
// import { Callback } from '../../types';
// import { DocumentSnapshot } from 'firebase-functions/lib/providers/firestore';

// ------------------------------------------------------- Global Textures -------------------------------------------------------
const Tutorial = PIXI.Texture.from(tutorial.toString());
const MicOff = PIXI.Texture.from(micOff.toString());
const CameraOff = PIXI.Texture.from(cameraOff.toString());

// ------------------------------------------------------- Global Constants -------------------------------------------------------
const SPEED = 0.7;
const intervalLength = 500; // Time between function calls for refreshPositionInterval, refreshCallStatusInterval
export const spriteSize = [100, 100];
const w = spriteSize[0];
const h = spriteSize[1];
let miniMapScale = 0.048;
const speakerPosition = [1915, 1150];
const INVITE_ACCEPT_TIME = 600;
const INVITE_MAX_WAIT_TIME = 15000;
export const SIDEBAR_WIDTH = 216;
const MAX_STEP_SIZE = 50;
/******* THIS CHECK IS ALSO SERVER SIDE ******/
const MAX_CONVO_SIZE = 6;
/******* (END THIS CHECK IS ALSO SERVER SIDE) ******/

// ------------------------------------------------------- Global Variables -------------------------------------------------------
// let personalTextures: any = {}; <- deleted because its never used
let playerGlowStatus: { [key: string]: string } = {}; // <- changed from playerGlowStatus: any = {}

// A mapping from the current player mode to the net player mode.
let playersMode: { [key: string]: number } = {}; // <- changed from :any = {}

var currMode: number = UserMode.open; // change from inferred to number
var currInvite = '';
// TODO(kevin): VERY BAD local/global variable
export let currConvo: string = '';

var target: Array<number> = [];
var zoomTarget: Array<number> = [];
var zoomBack: Array<number> = [];
var following: string = '';

// -------------- Paths --------------
let invitesPath: string;
let removesPath: string;
let convosPath: string;
let PATH: string;
let barDesignPath: string;
let nameTagPath: string;
let hostPath: string;

// -------------- Firebase References --------------
let refInvites: firebase.database.Reference; // Changed from any
let refRemoves: firebase.database.Reference; // Changed from any;
let refConvos: firebase.database.Reference; // Changed from any;
let ref: firebase.database.Reference; // Changed from any;
let barDesignRef: firebase.database.Reference; // Changed from any;
let nameTagRef: firebase.database.Reference; // Changed from any;
let hostRef: firebase.database.Reference; // Changed from any;

let subscribed: boolean = false;
let allInvites: { [uid: string]: InviteType } = {};
let allConvos: AllConvoType = {};
let allRemoves: any = {}; // Not sure what this is supposed to be
let initialConvo: ConvoDataType | null = null;
let mySprite: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
let playerSpawnX = 1000;
let playerSpawnY = 525;
let currBrightness = 0;
var sprites: { [uid: string]: PIXI.Sprite } = {};
var spritesMini: { [uid: string]: PIXI.Sprite } = {};
var webcamSprites: { [uid: string]: PIXI.Sprite } = {};
var friendsList: string[] = [];
var requestName: string;
var requestFunction: Function;
let spritesInPlace: { [id: string]: boolean } = {};
let tagsChanged: any = {};
let currDesign: string;
let disableMovement = 0;
let playerLeaving = false;
const desiredSpeed = 0.38; //pixels per millisecond
let lastHidden = 0;

var filterStrength = 20;
var frameTime = 0;
var lastLoop = Date.now();
var thisLoop = Date.now();
var lastUpdate = Date.now();
var elements: { [uid: string]: any } = {};
var videoConnected: { [uid: string]: number | null } = {};
var convoConnections: { [uid: string]: boolean } = {};
var convoArrivalTime: { [uid: string]: number } = {};
let hasMoved = false;

const getLayerFromSprite = (sprite: PIXI.Sprite, layer: SpriteLayers): PIXI.Sprite | null => {
  return sprite?.getChildByName(layer) as PIXI.Sprite | null;
};

var boundingPoly: PIXI.Polygon;
var boundingPolyGraphics: PIXI.Graphics;
var keyMap: { [id: number]: boolean } = {};

function BarScene(props: BarSceneProps) {
  // ------------------------------------------------------- Imported Hooks and props -------------------------------------------------------
  const {
    baseRoomID,
    isHub,
    miniMap,
    app,
    userID,
    roomID,
    avatar: myAvatar,
    image: myImage,
    hubStyle,
    userIsAdmin,
    hubList,
  } = props;
  const {
    backend,
    sound,
    otherFunctions: { addStream, clearAllStreams, removedStream, userUpdate, initWebRTC },
    wrtc,
  } = useVideoContext();
  const [render, setRender] = useState(false);
  const [roomChoice, setRoomChoice] = useState(hubStyle || baseRoomID);
  const [openBroadcast, setOpenBroadcast] = useState(0);
  // const [timerKey, setTimerKey] = useState(0);
  const [playerCount, setPlayerCount] = useState(0);
  const [roomHost, setRoomHost] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [mediaEnabled, setMediaEnabled] = useState(true);
  const appviewport = app.stage.getChildAt(0) as Viewport;
  const [isStuck, setIsStuck] = useState(false);
  const [summon, setSummon] = useState({
    value: '',
  });
  const nameTag = useRef(false);
  const openBroadcastRef = useRef(openBroadcast);
  const playersListRef = useRef<string[]>([]);
  const [isInCall, setIsInCall] = useState(false);
  const [nameTagState, setNameTagState] = useState(false);
  const [serverConnected, setServerConnected] = useState(false);
  const serverConnectedRef = useRef(false);
  const [serverConnectedWarning, setServerConnectedWarning] = useState(true);
  const serverConnectedWarningRef = useRef(true);
  const [online, setOnline] = useState(true);
  const [res, setRes] = useState(1);
  const miniMapParticleContainer = miniMap.stage.getChildAt(1) as PIXI.ParticleContainer;
  const miniMapBackgroundContainer = miniMap.stage.getChildAt(0) as PIXI.Container;
  const clickState = useRef(0); //0 means user is not clicked in an element that takes keyboard input >0 means they are
  const roomHostRef = useRef('');
  const isLoadingRef = useRef(true);
  const onlineRef = useRef(true);
  const { userEmail } = useAppState();
  const barscene = useContext(BarSceneContext);
  const refStatus = barscene.refStatus;
  const myPlayerRef = barscene.myPlayerRef;
  let myPlayer = barscene.myPlayerRef.current;
  const players = barscene.players;
  const callLatencySum = useRef(0);
  const callCount = useRef(0);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);
  // async function setBounds(roomChoice: string) {
  //   await getBackgroundData(roomChoice).then((BGD: BackgroundData) => {
  //     backgroundData = BGD;
  //   });
  //   boundingPoly = barBoundsPoly(backgroundData.bounds);
  //   boundingPolyGraphics = barBoundsGraphics(backgroundData.bounds);
  // }

  function setSprites(input: { [uid: string]: PIXI.Sprite }): void {
    sprites = input;
  }
  useEffect(() => {
    roomHostRef.current = roomHost;
  }, [roomHost]);

  useEffect(() => {
    openBroadcastRef.current = openBroadcast;
    zoomCaller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBroadcast, render]); // ok??

  const zoomCaller = (): void => {
    if (!currConvo) return;
    let convo = allConvos?.[currConvo];
    if (!convo) return;
    positionStage(convo, false, openBroadcastRef.current);
  };

  function updateIfAllowed(uid: string, roomID: string, data: PlayerLocationType | PlayerType | FullPlayerType): void {
    if (!playerLeaving && serverConnectedRef.current) updatePlayerPosition(uid, roomID, data);
  }

  useEffect(() => {
    if (!backend || !userID || !roomID) return;
    backend.enterRoom(userID, roomID, myPlayerRef);
    return () => {
      backend.exitRoom();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomID, userID]);

  // Managing user
  useEffect(() => {
    const webcamSprite = getLayerFromSprite(mySprite, SpriteLayers.webcam);
    if (webcamSprite) {
      if (sound?.streamVideoElement) {
        webcamSprite.texture = PIXI.Texture.from(sound.streamVideoElement);
        webcamSprite.width = spriteSize[0];
        webcamSprite.height = spriteSize[1];
      }
      if (sound.videoOn) {
        webcamSprite.alpha = 1;
      } else {
        webcamSprite.alpha = 0;
      }
    }
  }, [sound, sound.streamVideoElement]);

  function getScreenWidth(): number {
    return window.innerWidth - SIDEBAR_WIDTH;
  }
  // TODO: Extract to another class
  // Main load useEffect

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    setCurrMode(UserMode.open);
    setCurrInvite('');
    initWebRTC();
    logger.info(`[BARSCENE] ENTERED  - ` + userEmail);
    function updateAll() {
      const connState = wrtc?.backconn?.socket?.connected ?? false;
      if (!connState && serverConnectedWarningRef.current) {
        // setTimeout(() => {
        //   const futureConnState = wrtc?.backconn?.socket?.connected ?? false;
        //   if (futureConnState) return;
        //   if (currMode === UserMode.inCall || currMode === UserMode.admin) endCall();
        //   removePlayerFromRoom(userID, roomID, 'server disconnected');
        //   setServerConnected(futureConnState);
        //   serverConnectedRef.current = futureConnState;
        // }, 5000);

        logger.info(`[NETWORK] Server disconnected - ` + userEmail);
      } else if (connState && !serverConnectedWarningRef.current) {
        logger.info(`[NETWORK] Server reconnected - ` + userEmail);
      }
      if (connState) {
        setServerConnected(connState);
        serverConnectedRef.current = connState;
      }
      setServerConnectedWarning(connState);
      serverConnectedWarningRef.current = connState;
      if (
        (myPlayer.mode === UserMode.afk || currMode === UserMode.afk) &&
        window.document.visibilityState === 'visible'
      ) {
        setCurrMode(UserMode.open);
        return;
      }
      manageInvites();
      if (currMode === UserMode.open) return;
      refreshPosition();
      refreshCallStatus();
    }

    setInterval(function() {
      reCenter();
      if (
        currMode === UserMode.afk ||
        isLoadingRef.current ||
        window.document.visibilityState !== 'visible' ||
        document.hidden
      ) {
        lastHidden = 3;
        return;
      }
      lastHidden = Math.max(lastHidden - 1, 0); // only begin reducing resolution after window has been visible for 3 time steps
      if (lastHidden) return;
      let targetFPS = 35;

      let bufferLow = 10;
      let bufferHigh = 10;
      if (res < 0.6) {
        bufferHigh = 2;
        bufferLow = 18;
      }
      let stepRate = 1 / 2;
      let fps = 1000 / frameTime;
      // let dif = targetFPS - fps;
      let ratio = targetFPS / fps; //high means we need lower res
      if (isDevelopment) {
        // console.log('fps: ', fps);
        // console.log('res: ', app.renderer.resolution);
      }
      if (fps > targetFPS - bufferLow && fps < targetFPS + bufferHigh) {
        return;
      }
      let destinationPoint = app.renderer.resolution / ratio;
      let step = (destinationPoint - app.renderer.resolution) * stepRate;
      let newRes = app.renderer.resolution + step;
      if (newRes > 1.2) newRes = 1.2;
      if (newRes < 0.4) newRes = 0.4;
      setRes(newRes);

      // setRes((app.renderer.resolution + .2)%2)
    }, 1000);

    app.stage.position.y += 75;

    document.addEventListener('visibilitychange', handleAFK);
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    document.addEventListener('click', clearKeys);
    // TODO (kevin): onblur with timeout
    window.onfocus = onfocus;
    window.addEventListener('resize', function() {
      zoomCaller();
      reCenter();
      app.renderer.resize(getScreenWidth(), window.innerHeight);
      app.render();
    });
    window.onbeforeunload = unloadCleanup;

    function savePos() {
      if ((!target?.length && !myPlayer?.arrowState) || !mySprite) return;
      const data = { curr_x: mySprite.x, curr_y: mySprite.y };
      updateIfAllowed(userID, roomID, data);
    }

    const periodicUpdate = () => {
      if (!hasMoved) {
        return;
      }
      if (currMode === UserMode.open && myPlayer.arrowState === ArrowState.inactive) {
        // Reset Arrowstate every few seconds
        myPlayer.arrowState = ArrowState.stop;
      }
    };

    var savePosInt = setInterval(savePos, 1000 / 3);
    var updateInterval = setInterval(updateAll, intervalLength);
    const joinInterval = setInterval(periodicUpdate, 3000);

    return () => {
      unloadCleanup();
      clearInterval(updateInterval);
      clearInterval(savePosInt);
      clearInterval(joinInterval);
      document.removeEventListener('visibilitychange', handleAFK);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
      document.removeEventListener('onbeforeunload', unloadCleanup);
      document.removeEventListener('resize', reCenter);
      document.removeEventListener('click', clearKeys);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function clearKeys() {
    keyMap = {};
    updateArrowState();
  }

  const handleAFK = () => {
    if (document.visibilityState === 'visible') {
      onfocus();
    } else if (document.visibilityState === 'hidden') {
      onblur();
    }
  };

  useEffect(() => {
    if (!wrtc) return;
    if (isFirefox || isSafari) {
      return;
    }
    navigator.permissions.query({ name: 'microphone' }).then(function(permissionStatus) {
      if (permissionStatus.state !== 'granted') setMediaEnabled(false);
      permissionStatus.onchange = function() {
        logger.info('[PERMISSION] Mic permission changed to ' + this.state);
        if (this.state !== 'granted') setMediaEnabled(false);
      };
    });
    navigator.permissions.query({ name: 'camera' }).then(function(permissionStatus) {
      if (permissionStatus.state !== 'granted') setMediaEnabled(false);
      permissionStatus.onchange = function() {
        logger.info('[PERMISSION] Cam permission changed to ' + this.state);
        if (this.state !== 'granted') setMediaEnabled(false);
      };
    });
  }, [wrtc]);

  useEffect(() => {
    if (!mediaEnabled) clickState.current = -1;
  }, [mediaEnabled]);

  // Add my player useEffect
  useEffect(() => {
    addMyPlayer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, myAvatar]);

  useEffect(() => {
    let interval: any;
    let allActivated = true;
    function activateMiniSprites() {
      logger.info(`[BARSCENE] Activating minimap sprites`);
      Object.keys(players).forEach((id: string) => {
        const sprite = id === userID ? mySprite : sprites[id];
        const spriteMini = spritesMini[id];
        if (!spriteMini || !sprite || !spriteMini?.transform?.position) {
          allActivated = false;
          return;
        }
        // TODO(eli): Something here continues to null. Fixed (I think)
        spriteMini.x = sprite.x * miniMapScale;
        spriteMini.y = sprite.y * miniMapScale;
      });
      if (allActivated) {
        logger.info(`[BARSCENE] All minimap sprites activated`);
        clearInterval(interval);
      }
    }
    if (!isLoading) {
      interval = setInterval(activateMiniSprites, 1000);
      activateMiniSprites();
    }
  }, [isLoading]);

  // Room choice useEffect
  useEffect(() => {
    console.log('runs on first load', roomChoice);
    if (baseRoomID === userID) {
      if (roomChoice === baseRoomID) setRoomChoice('stylishhub');
      else setRoomDesign(baseRoomID, roomChoice);
    }
    if (roomChoice) {
      changeBackground();
    }
    reCenter();
    resizer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomChoice]);

  function resizer(): void {
    app.renderer.resolution = res;
    app.renderer.resize(getScreenWidth(), window.innerHeight);
    app.render();
  }

  useEffect(() => {
    resizer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);

  async function setBackground(isPublic: boolean, backgroundData: BackgroundData): Promise<void> {
    if (!backgroundData) return;
    setIsLoading(true);
    let arrows = new PIXI.Sprite(Tutorial);
    arrows.scale.x = 0.5;
    arrows.scale.y = 0.5;
    playerSpawnX = backgroundData?.spawn[0];
    playerSpawnY = backgroundData?.spawn[1];
    arrows.position.x = backgroundData?.spawn[0] - arrows?.getBounds()?.width / 2 + w / 2;
    arrows.position.y = backgroundData?.spawn[1] + h;
    arrows.zIndex = 2;
    arrows.name = 'arrows';
    let vartyBackground: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.WHITE); // taking too long
    const backgroundString = userID === roomChoice ? 'stylishhub' : isHub ? roomChoice : baseRoomID;
    try {
      let URLString: string = isPublic ? `${baseRoomID}/full` : `users/${baseRoomID}/full`;
      let storageString = isPublic ? `` : `users/${baseRoomID}/full`;
      const background = await backgroundBlobToBase64(`backgrounds/${storageString}`, backgroundString);
      const roomTexture = PIXI.Texture.from(background);
      vartyBackground = new PIXI.Sprite(roomTexture);
      vartyBackground.scale = new PIXI.Point(backgroundData.scale[0] ?? 1, backgroundData?.scale[1] ?? 1);
      miniMapScale = backgroundData?.miniMapScale[0] ?? 0;
      vartyBackground.position.x = 0;
      vartyBackground.position.y = 0;
      vartyBackground.zIndex = 1;
      vartyBackground.name = 'back';
      vartyBackground.interactive = false;
      vartyBackground.buttonMode = false;
    } catch (e) {
      logger.info('Error in set background, in the first try/catch block');
    }
    try {
      if (appviewport.getChildByName('back')) {
        (appviewport.getChildByName('back') as PIXI.Sprite).destroy({ texture: false });
      }
      if (appviewport.getChildByName('arrows')) appviewport.removeChild(appviewport.getChildByName('arrows'));
    } catch {
      logger.info('FAIL image fetch');
    }

    appviewport.addChildAt(vartyBackground, 0);
    appviewport.addChild(arrows);

    /// MINI BACKGROUND
    boundingPolyGraphics.zIndex = 1;
    boundingPolyGraphics.scale.x = miniMapScale;
    boundingPolyGraphics.scale.y = miniMapScale;
    const backgroundMini = new PIXI.Graphics()
      .beginFill(0x1a1a1a)
      .drawRect(0, 0, miniMap.view.width, miniMap.view.height)
      .endFill();
    backgroundMini.name = 'backgroundMini';

    if (miniMapBackgroundContainer.getChildByName('backgroundMini'))
      miniMapBackgroundContainer.removeChild(miniMapBackgroundContainer.getChildByName('backgroundMini'));
    miniMapBackgroundContainer.addChild(backgroundMini);
    miniMapBackgroundContainer.addChild(boundingPolyGraphics);
    setTimeout(() => setIsLoading(false), 100);
    document.body.style.overflow = 'hidden';
  }

  async function changeBackground(): Promise<void> {
    endCall();
    let toFetch = roomChoice;
    if (roomChoice === userID) toFetch = 'stylishhub';
    let tmpIsPublic: boolean = false;
    if (!hubList.includes(roomChoice) && roomChoice !== 'current') tmpIsPublic = true;
    let backgroundData = tmpIsPublic
      ? await getHubStylesBackgroundData(toFetch)
      : await getUserBackgroundData(baseRoomID);
    if (!backgroundData) {
      logger.info('[BACKGROUND] empty');
      backgroundData = await getUserBackgroundData(baseRoomID);
    }
    boundingPoly = barBoundsPoly(backgroundData?.bounds);
    boundingPolyGraphics = barBoundsGraphics(backgroundData?.bounds);
    mySprite.x = backgroundData?.spawn[0];
    mySprite.y = backgroundData?.spawn[1];
    await setBackground(tmpIsPublic, backgroundData);
    const data: PlayerLocationType = {
      curr_x: mySprite.x,
      curr_y: mySprite.y,
    };
    updateIfAllowed(userID, roomID, data);

    Object.keys(spritesMini).forEach(key => {
      if (key === userID) return;
      spritesMini[key].destroy();
      delete spritesMini[key];
      addMiniSpriteOther(key, backgroundData.spawn[0], backgroundData.spawn[1]);
    });
    if (spritesMini[userID]) spritesMini[userID].destroy();
    delete spritesMini[userID];
    addMyMiniSprite();
  }

  // Mute/unmute useEffect
  useEffect(() => {
    refStatus.videoOn = sound.videoOn;
    refStatus.audioOn = sound.audioOn;
  }, [sound.videoOn, sound.audioOn]);

  // Face in avatar useEffect
  useEffect(() => {
    const webcamSprite = getLayerFromSprite(mySprite, SpriteLayers.webcam);
    if (!webcamSprite) return;
    if (sound?.videoOn) {
      webcamSprite.alpha = 1;
    } else {
      webcamSprite.alpha = 0;
      webcamSprite.texture = PIXI.Texture.WHITE;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sound.videoOn]);

  // WRTC useEffect
  useEffect(() => {
    if (!wrtc) return;
    const updateIconsInSprite = (user: User) => {
      if (!user.name) {
        return;
      }
      const sprite = sprites[user.name];
      if (user.videoOn) {
        removeCameraOffFromSprite(sprite);
      } else {
        addCameraOffToSprite(sprite);
      }
      if (user.audioOn) {
        removeMicOffFromSprite(sprite);
      } else {
        addMicOffToSprite(sprite);
      }
    };
    const updateUserStream = (user: User | null, streamObject: StreamsObject | null) => {
      if (isDevelopment) console.log('UPDATING USER STREAM');
      if (!user) {
        // Remove all webcam sprites!
        logger.info('[CALL] REMOVING ALL WEBCAMS');
        Object.keys(webcamSprites).forEach(key => {
          const webcamSprite = webcamSprites[key];
          try {
            webcamSprite.destroy();
          } catch {}
        });
        webcamSprites = {};
        return;
      }
      const sprite = sprites[user.name];
      if (!sprite) {
        logger.info('[SPRITE] Could not update sprite ' + (user.name || 'NO NAME'));
        return;
      }
      if (user) {
        updateIconsInSprite(user);
      }
      let webcamSprite = getLayerFromSprite(sprite, SpriteLayers.webcam);
      const element = streamObject?.audioObject;
      elements[user.name] = element ?? null;
      try {
        if (!element && webcamSprite) {
          webcamSprite.destroy();
          delete webcamSprites[user.name];
          webcamSprite = null;
        }
      } catch {}
      if (!element) {
        return;
      }
      if (!webcamSprite) {
        webcamSprite = new PIXI.Sprite();
        webcamSprite.width = spriteSize[0];
        webcamSprite.height = spriteSize[1];
        webcamSprite.name = SpriteLayers.webcam;
        webcamSprite.zIndex = 4;
        webcamSprite.alpha = 0;
        webcamSprite.texture = PIXI.Texture.EMPTY;
        sprite.addChildAt(webcamSprite, 1);
        webcamSprites[user.name] = webcamSprite;
        try {
          if (element.readyState > 1) {
            if (isDevelopment) console.log('ELEMENT ALREADY READY');
            webcamSprite.texture = PIXI.Texture.from(element);
            webcamSprite.alpha = 1;
            webcamSprite.width = spriteSize[0];
            webcamSprite.height = spriteSize[1];
            element.play();
          }
        } catch {
          logger.info('[CALL] Webcam sprite error - questionable');
        }
        const mycheck = setInterval(() => {
          webcamSprite = webcamSprites[user.name];
          if (!webcamSprite) {
            clearInterval(mycheck);
            logger.info(`[CALL] No webcam sprite on mycheck: ${user.name}`);
            return;
          }
          if (element.readyState > 1) {
            clearInterval(mycheck);
            webcamSprite.alpha = 1;
            if (wrtc.conn?.users[user.id] && !wrtc.conn?.users[user.id].videoOn) {
              webcamSprite.alpha = 0;
              logger.info(`[CALL] Webcam is off (incheck): ${user.name}`);
              return;
            }
          } else {
            logger.info(`[VIDEO] element still not ready: ${user.name}`);
          }
        }, 3000);
      }
      const thisLocalConvo = currConvo;
      element.addEventListener('loadeddata', async () => {
        if (isDevelopment) console.log(element.readyState, currConvo, currMode, webcamSprite);
        if (element.readyState <= 1) {
          return;
        }
        // Add one second delay before updating webcam sprite texture
        await new Promise(resolve => setTimeout(resolve, 0.7)); // 0.7 sec
        webcamSprite = webcamSprites[user.name];
        if (!webcamSprite) {
          logger.info(`[CALL] No webcam sprite: ${user.name}`);
          return;
        }
        if (
          thisLocalConvo !== currConvo ||
          currConvo.length === 0 ||
          (currMode !== UserMode.inCall && currMode !== UserMode.admin)
        ) {
          webcamSprite.alpha = 0;
          logger.info(`[CALL] Convo wrong but element loaded: ${user.name}`);
          return;
        }
        try {
          webcamSprite.alpha = 0;
          webcamSprite.texture = PIXI.Texture.from(element);
          webcamSprite.alpha = 1;
          webcamSprite.width = spriteSize[0];
          webcamSprite.height = spriteSize[1];
          // If webcam is already disabled then ignore
          if (wrtc.conn?.users[user.id] && !wrtc.conn?.users[user.id].videoOn) {
            webcamSprite.alpha = 0;
            logger.info(`[CALL] Webcam is off: ${user.name}`);
            return;
          }
          element.play();
          logger.info(`[CALL] Webcam for ${user.name} done`);
        } catch (e) {
          logger.debug(`[CALL] ERROR ${e}`);
        }
      });
      element.addEventListener('playing', () => {
        webcamSprite = webcamSprites[user.name];
        if (!webcamSprite) {
          logger.info('[CALL] Webcam sprite now null');
          return;
        }
        if (currConvo.length === 0 || (currMode !== UserMode.inCall && currMode !== UserMode.admin)) {
          webcamSprite.alpha = 0;
          logger.info("[CALL] Convo doesn't exist in playing");
          return;
        }
        webcamSprite.alpha = 1;
        if (wrtc.conn?.users[user.id] && !wrtc.conn?.users[user.id].videoOn) {
          webcamSprite.alpha = 0;
          logger.info(`[CALL] Webcam is off: ${user.name}`);
        }
      });
      element.addEventListener('pause', () => {
        webcamSprite = webcamSprites[user.name];
        if (!webcamSprite) {
          logger.info('[CALL] Webcam sprite now null on pause');
          return;
        }
        // webcamSprite.alpha = 0;
        console.log('Paused');
        element.play();
      });
    };

    const updateUser = async (user: User) => {
      if (user.videoOn) {
        await new Promise(res => setTimeout(res, 300));
      }
      updateIconsInSprite(user);
      const webcamSprite = webcamSprites[user.name];
      if (!webcamSprite) {
        logger.info('Could not update sprite data ' + (user.name || 'NO NAME'));
        return;
      }
      logger.info(`[CALL] Update user ${user.name}: user.videoOn`, user.videoOn);
      webcamSprite.alpha = user.videoOn ? 1 : 0;
    };

    const updateAction = async (action: string) => {
      // TODO kevin, may not kick then either because of dependencies
      if (action === 'kick') {
        logger.info(`[CALL] Kicked from call`);
        endCall();
      }
    };

    const updateConnection = async (action: string, user: User | null) => {
      if (!user) {
        logger.info("[SPRITE] Update Connection doesn't exist user");
        return;
      }
      const sprite = sprites[user.name];
      if (!sprite) {
        logger.info("[SPRITE] Sprite doesn't exist");
        return;
      }
      if (action === 'failed' || action === 'disconnected') {
        addReconnectToSprite(sprite);
      }
      if (action === 'connected') {
        removeReconnectFromSprite(sprite);
      }
    };

    VartyListener.attach(updateUserStream, Observer.stream);
    VartyListener.attach(updateConnection, Observer.connection);
    VartyListener.attach(updateUser, Observer.user);
    VartyListener.attach(updateAction, Observer.action);
    return () => {
      VartyListener.detach(updateUserStream, Observer.stream);
      VartyListener.detach(updateUser, Observer.user);
      VartyListener.detach(updateConnection, Observer.connection);
      VartyListener.detach(updateAction, Observer.action);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrtc]);

  // These are any types. Im not sure what to change them to. HTMLUnknownElement and HTMLWebViewElement dont work. These later are used for divs, and
  // attaching an HTMLCanvasElement
  const container = useRef<any>();
  const containerMini = useRef<any>();

  function unloadCleanup(): void {
    playerLeaving = true;
    backend.exitRoom();
    if (myPlayer?.inviteID) removeInviteFromRoom(myPlayer.inviteID, roomID);
    logger.info(`[BARSCENE] Reload - ` + userEmail);
  }

  // let afkTimeout: any = null; // Deleted because this is not used anywhere

  const onfocus = (): void => {
    // if (afkTimeout) {
    //   clearTimeout(afkTimeout);
    //   afkTimeout = null;
    // }
    console.log('ON FOCUS');
    if (currMode === UserMode.afk) {
      setCurrMode(UserMode.open);
      mySprite.alpha = 1;
    }
    clearKeys();
  };

  const onblur = () => {
    // Reset all keymaps
    clearKeys();
    if (currMode !== UserMode.inCall && currMode !== UserMode.admin) {
      if (!players?.[userID]) return;
      if (currMode === UserMode.auto) {
        setCurrMode(UserMode.open);
      }

      const data: PlayerLocationType = {
        curr_x: mySprite.x,
        curr_y: mySprite.y,
      };
      updateIfAllowed(userID, roomID, data);
      if (currMode === UserMode.inviting) {
        endInvite(myPlayer.inviteID);
      } else if (currMode === UserMode.invited) {
        endInvite(currInvite);
      }
      setCurrMode(UserMode.afk);
      mySprite.alpha = 0.15;
    }
  };

  // useEffect(() => {
  //   mySummonsQueue.push(newSummon);
  //   if (!openSummon) setOpenSummon(mySummonsQueue.shift());
  //   if (mySummonsQueue.length) setNumSummons(`(+${mySummonsQueue.length} More)`);
  //   else setNumSummons('');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [newSummon]);

  // function onSummonAdded(summon: any) {
  //   if (!summon) return;
  //   if (!players[summon.from]) {
  //     removeSummonFromRoom(summon.uid, roomID);
  //     return;
  //   }
  //   if (summon.to === userID) {
  //     setNewSummon(summon);
  //   }
  // }
  function manageInvites() {
    if (!allInvites) return;
    if (currMode === UserMode.inviting) checkSentInviteStatusInviting();
    else if (currMode === UserMode.invited) checkInviteStatusInvited();
    else if (currMode === UserMode.accepted) checkAcceptedInvites();
    else if (currMode === UserMode.open) checkForInvites();
  }

  if (!subscribed) {
    invitesPath = pathToBarRoomInvites(roomID);
    removesPath = pathToBarRoomRemoves(roomID);
    refInvites = firebase.database().ref(invitesPath);
    refRemoves = firebase.database().ref(removesPath);
    convosPath = pathToBarRoomConvos(roomID);
    refConvos = firebase.database().ref(convosPath);
    PATH = pathToBarRoomPlayers(props.roomID);
    ref = firebase.database().ref(PATH);
    hostPath = pathToBarRoomHost(baseRoomID);
    hostRef = firebase.database().ref(hostPath);
    barDesignPath = pathToBarRoomDesign(baseRoomID);
    barDesignRef = firebase.database().ref(barDesignPath);
    nameTagPath = pathToBarRoomNameTag(baseRoomID);
    nameTagRef = firebase.database().ref(nameTagPath);

    nameTagRef.on('value', (snap: firebase.database.DataSnapshot) => {
      nameTag.current = snap.val()?.nameTag;
      setNameTagState(nameTag.current);
      handleNameTags();
    });

    hostRef.on('value', (snap: firebase.database.DataSnapshot) => {
      setRoomHost(snap.val());
    });

    // ref.once('value', (snap: any) => {
    //   let val = snap.val();
    //   Object.entries(val).forEach((player: any) => {
    //     if (!sprites[snap.key] && snap.key !== userID) {
    //       const { curr_x, curr_y, mode } = snap.val();
    //       addNewPlayer(snap.key, curr_x, curr_y, mode);
    //     }
    //   })
    // });

    ref.on('child_added', (snap: firebase.database.DataSnapshot) => {
      // try again

      let snapKey: string = snap.key || 'FAILURE';
      let userId = snapKey;
      if (snapKey === 'FAILURE') {
        console.error('Reference returned null value');
        return;
      }
      let playerPath = pathToBarRoomPlayer(roomID, snapKey);
      let playerRef = firebase.database().ref(playerPath);
      if (!sprites[snapKey] && snap.key !== userID) {
        const { curr_x, curr_y, mode } = snap.val();
        addNewPlayer(snapKey, curr_x, curr_y, mode);
      }
      let player = snap.val() as FullPlayerType;
      if (!player) return;
      players[snapKey] = player;
      setTimeout(() => handleNameTag(snapKey), 1000);
      updatePlayersMetaData();
      playerRef.on('value', (snap: firebase.database.DataSnapshot) => {
        let snapKey: string = snap.key || 'FAILURE';
        if (snapKey === 'FAILURE') {
          console.error('Reference returned null value');
          return;
        }
        let player = snap.val();
        let prevPlayer = players[snapKey];
        if (!player) return;
        let modeUpdated = player.mode !== prevPlayer?.mode;
        // let sprite = sprites[snapKey];
        players[snapKey] = player;
        changeBorderColors(snapKey, player.mode);
        if (tagsChanged?.[snapKey] === true) {
          tagsChanged[userId] = false;
          handleNameTag(snapKey);
        }
        if (snap.key === userID) {
          myPlayer = player;
          myPlayerRef.current = myPlayer;
        } else if (modeUpdated) {
          if (currMode === UserMode.accepted) checkAcceptedInvites();
        }
      });
      playerRef.child('nickname').on('value', (snap: firebase.database.DataSnapshot) => {
        tagsChanged[userId] = true;
      });
      setZIndex();
    });

    function updatePlayersMetaData(): void {
      //TODO (pletcher) : make sure this still works as intended
      if (players) playersListRef.current = Object.keys(players);
      if (!players) Object.keys(players).forEach(p => delete players[p]);
      else {
        const count: number = Object.keys(players).length;
        if (count !== playerCount) {
          setPlayerCount(count);
        }
      }
    }

    ref.on('child_removed', (snap: firebase.database.DataSnapshot) => {
      let snapKey: string = snap.key || 'FAILURE';
      if (snapKey === 'FAILURE') {
        logger.info('Reference returned null value');
        return;
      }
      if (snapKey === userID && wrtc?.backconn?.socket?.connected) {
        if (currMode === UserMode.awaitingCall || currMode === UserMode.accepted || currMode === UserMode.joiningCall)
          setCurrMode(UserMode.open);
        else updateIfAllowed(userID, roomID, myPlayer);
        return;
      }
      if (snapKey === roomHostRef.current && roomID === userID) setHost(roomID, userID);
      handleChildRemoved(snapKey);
      delete players[snapKey];
      updatePlayersMetaData();
    });

    refInvites.on('value', (snap: firebase.database.DataSnapshot) => {
      if (snap.val()) {
        allInvites = snap.val();
      } else {
        allInvites = {};
      }
    });

    //TODO: make this use child_added
    refRemoves.on('value', (snap: firebase.database.DataSnapshot) => {
      allRemoves = snap.val();
      checkForRemoval();
    });

    refConvos.on('value', (snap: firebase.database.DataSnapshot) => {
      let newConvos = snap.val();
      if (newConvos) {
        if (currConvo) {
          let oldMyConvo = allConvos?.[currConvo];
          let newMyConvo = newConvos?.[currConvo];
          if (newMyConvo) {
            newMyConvo?.members?.forEach((member: string) => {
              if (!convoArrivalTime[member] && member !== userID) {
                convoArrivalTime[member] = Date.now();
              }
            });
            oldMyConvo?.members?.forEach((member: string) => {
              if (!newMyConvo?.members?.includes(member)) {
                if (!convoConnections[member] && member !== userID) {
                  //DETECTED Member left before connecting
                  createFailedConnectionReport('OTHER');
                }
                onDisconnectFromSprite(sprites[member]);
                delete convoArrivalTime[member];
                delete convoConnections[member];
              }
            });
          }
        }

        if (currConvo) {
          let oldThrottleUpdate = allConvos?.[currConvo]?.throttleUpdate;
          let newThrottleUpdate = newConvos?.[currConvo]?.throttleUpdate;
          if (oldThrottleUpdate !== newThrottleUpdate) {
            resetThrottleTimer();
          }
        }
        allConvos = newConvos;
      } else {
        allConvos = {};
      }
      setZIndex();
    });

    barDesignRef.on('value', (snap: firebase.database.DataSnapshot) => {
      currDesign = snap.val();
      if (currDesign && roomID !== userID) {
        setRoomChoice(currDesign);
      }
    });

    subscribed = true;
  }

  function setCurrInvite(inviteID: string): void {
    if (!myPlayer) return;
    myPlayer.inviteID = inviteID;
    updateIfAllowed(userID, roomID, myPlayer);
    currInvite = inviteID;
  }

  function setCurrMode(input: number, caller = 'd'): void {
    if (isDevelopment) console.log('setCurrMode', input, caller, Date.now());
    if (!myPlayer) return;
    myPlayer.mode = input;
    updateIfAllowed(userID, roomID, myPlayer);
    if (input !== UserMode.auto) {
      following = '';
    }
    setZIndex();
    currMode = input;
    changeBorderColors(userID, myPlayer.mode);
  }

  function refreshPosition(): void {
    if (currMode === UserMode.inviting) checkInviteeDistance();
    else if (following) {
      let followedPlayer = players[following];
      if (!followedPlayer) return;
      if (!playersOverlapSquares(myPlayer, followedPlayer)) follow(following);
    }
  }

  const changeStates = (sprite: PIXI.Sprite, isPrivate: boolean, isAfk: boolean, isFocused: boolean): void => {
    const border = getLayerFromSprite(sprite, SpriteLayers.border);
    if (!isAfk && border) {
      if (isFocused) {
        border.alpha = isPrivate ? 1 : 0;
        sprite.alpha = 1;
      } else {
        sprite.alpha = isPrivate ? 0.5 : 1;
        border.alpha = 0;
      }
    }
  };

  function privateFader(player: PlayerType, playerID: string, sprite: PIXI.Sprite, isMyPlayer: boolean): void {
    if (!player || !sprite) return;
    const convoPlayers = allConvos?.[currConvo]?.members;
    if (isMyPlayer) {
      convoPlayers?.forEach((player: string) => {
        let thisSprite = player === userID ? mySprite : sprites?.[player];
        let isPrivate = allConvos?.[currConvo]?.private;
        changeStates(thisSprite, isPrivate, false, true);
      });
    }
    if (!convoPlayers?.includes(playerID)) {
      let thisSprite = playerID === userID ? mySprite : sprite;
      let isPrivate = player.private;
      let isAfk = player.mode === UserMode.afk;
      changeStates(thisSprite, isPrivate, isAfk, isMyPlayer);
    }
  }

  function changeBorderColors(key: string, mode: number): void {
    /* Create a subscription to player mode in the database. that way we update this incrementally */

    //const color = colorDict[mode];
    let sprite = key === userID ? mySprite : sprites[key];

    let player = players[key];
    if (!sprite || !player) return;

    if (playersMode && playersMode[key] && mode === playersMode[key]) {
      privateFader(player, key, sprite, key === userID);
      return;
    }

    // const border = getLayerFromSprite(sprite, SpriteLayers.border) as PIXI.Graphics | null;
    // if (!border) return;
    playersMode[key] = mode;

    //From the mode, we can change the avatar mode.
    if (
      (UserMode.inCall === mode ||
        UserMode.admin === mode ||
        UserMode.invited === mode ||
        UserMode.pendingJoin === mode ||
        UserMode.inviting === mode) &&
      playerGlowStatus[key] !== glowMap.glow
    ) {
      playerGlowStatus[key] = glowMap.glow;
      sprite.alpha = 1;
    } else if (UserMode.afk === mode && playerGlowStatus[key] !== glowMap.afk) {
      playerGlowStatus[key] = glowMap.afk;
      sprite.alpha = 0.15;
    } else if ((UserMode.open === mode || UserMode.leavingCall === mode) && playerGlowStatus[key] !== glowMap.base) {
      playerGlowStatus[key] = glowMap.base;
      sprite.alpha = 1;
    }
    privateFader(player, key, sprite, key === userID);
  }

  function refreshCallStatus(): void {
    switch (currMode) {
      case UserMode.arrived:
        setCurrMode(UserMode.open, 'arrived');
        // const from = summon.value;
        // checkIfCanInvite(from);
        break;
      case UserMode.pendingJoin:
        if (!callOverlap(allConvos[currInvite])) {
          logger.info('[INVITE] Convo no longer overlap');
          setCurrMode(UserMode.open, 'pendingJoin');
        }
        break;
      case UserMode.joiningCall:
        removeInviteFromRoom(currInvite, roomID);
        if (!joinSpecificConvo(currInvite)) {
          setCurrMode(UserMode.open, 'joiningCall');
        }
        setCurrInvite('');
        break;
      case UserMode.inCall:
      case UserMode.admin:
        checkConvoMembers();
        break;
      case UserMode.leavingCall:
        break;
      default:
        return;
    }
  }

  function startCall(): void {
    if (currMode === UserMode.awaitingCall) {
      console.error('MODE IS WRONG = 2');
      return;
    }
    // API for starting a call

    currConvo = myPlayer?.inviteID;
    if (myPlayer) {
      myPlayer.convoID = currConvo;
      // TODO(ethan): extract this function into a memoized helper function
      // that also calls WebRTC.updateLocation(wrtc.conn, ....)

      updateIfAllowed(userID, roomID, myPlayer);
    }
    if (wrtc) {
      setCurrMode(UserMode.awaitingCall);
      /*
      This is where we start the proper call.
      this is where we bring people from them to us, and begin seeing all the convos in the call.
      the extra comment to make sure compile happened
      */
      console.log('Starting call', refStatus.audioOn);
      let start = Date.now();
      WebRTC.connect(
        wrtc,
        userID,
        currConvo,
        refStatus.audioOn,
        refStatus.videoOn,
        userUpdate,
        addStream,
        removedStream,
        () => {},
        wrtc => {
          let called = Date.now();
          updateCallLatency(called - start);
          logger.info(`[BARSCENE] time to startCall: ${called - start} avg: ${getAvgCallLatency()}`);
          myPlayer.socketID = wrtc.conn?.socket.id || '';
          updateIfAllowed(userID, roomID, myPlayer);
          // WEBRTC WAITS UNTIL DATABASE IS UPDATED
          setCurrMode(UserMode.inCall, 'startCall');
          disableMovement = 3;
          setIsInCall(true);
        }
      );
      setRender(!render);
    } else {
      alert('WRTC IS NULL');
    }
  }

  function updateCallLatency(time: number) {
    callLatencySum.current = callLatencySum.current + time;
    callCount.current = callCount.current + 1;
  }

  function getAvgCallLatency() {
    if (!callCount.current) return INVITE_ACCEPT_TIME - 300;
    //Default latency estimate
    else return Math.min(callLatencySum.current / callCount.current, INVITE_ACCEPT_TIME);
  }

  function checkInviteStatusInvited(): void {
    const invite = allInvites?.[currInvite];
    if (!invite) {
      setCurrInvite('');
      setCurrMode(UserMode.open, 'checkInviteStatusInvited');
    } else if (invite.state === InviteState.rejected) {
      console.log('Rejected 1');
      setCurrMode(UserMode.open, 'checkInviteStatusInvited');
      removeInviteFromRoom(myPlayer?.inviteID, roomID);
      setCurrInvite('');
    } else if (players?.[invite.fromID]?.mode !== UserMode.inviting) {
      endInvite(invite.uid);
      setCurrMode(UserMode.open);
    }
  }

  function checkInviteeDistance(): void {
    const invite = allInvites?.[myPlayer.inviteID];
    function outOfRange() {
      if (!invite) return true;
      if (invite?.state !== InviteState.open) return true;
      let invitedUser = players[invite.toID];
      if (!invitedUser) return true;
      if (!playersOverlapSquares(myPlayer, invitedUser)) return true;
      return false;
    }
    if (outOfRange()) {
      // TODO: Determine if we want to keep this logging statement
      logger.info('User out of range for invite');
      if (invite) {
        if (isDevelopment) console.log('USER OUT OF RANGE');
        invite.state = InviteState.rejected;
        updateInvite(myPlayer?.inviteID, roomID, invite);
      }
      setCurrMode(UserMode.open, 'checkInviteeDistance');
    }
  }

  function checkSentInviteStatusInviting(): void {
    const invite = allInvites[myPlayer?.inviteID];
    if (!invite) return;
    if (invite.state === InviteState.rejected) {
      // TODO: Determine if we want to keep this logging statement
      // TODO: Add notification
      console.log('Rejected 2');
      setCurrMode(UserMode.open, 'checkSentInviteStatusInviting1');
      removeInviteFromRoom(myPlayer?.inviteID, roomID);
    } else if (invite.state === InviteState.accepted) {
      const convoPos = [mySprite.x, mySprite.y];
      const convoMembers = [userID, invite.toID];
      const newConvoID = myPlayer?.inviteID;
      backend.sendHeartbeat(userID, roomID, newConvoID);
      // Allocate locally before pushing to server
      initialConvo = {
        throttleUpdate: Date.now(),
        members: convoMembers,
        pos: convoPos,
        private: false,
        uid: newConvoID,
      };
      convoArrivalTime[invite.toID] = Date.now();
      addConvoToRoom(newConvoID, roomID, convoPos, convoMembers);
      invite.state = InviteState.calling;
      updateInvite(newConvoID, roomID, invite);
      startCall();
      console.log('invite Updated', invite.state);
    } else if (players?.[invite.toID]?.mode !== UserMode.invited) {
      if (players?.[invite.toID]?.mode === UserMode.inviting) {
        //Are they inviting me?
        let collision = false;
        Object.values(allInvites).forEach(inviteOther => {
          if (collision) return; //only attempt to handle the first collision discovered;
          if (
            inviteOther.fromID === invite.toID &&
            inviteOther.toID === userID &&
            inviteOther.state === InviteState.open
          ) {
            //Yes they are inviting me
            if (isDevelopment) console.log('collision found');
            collision = true;
            if (userID > invite.toID) {
              //Wait for them to switch over to my invite
              if (isDevelopment) console.log('Waiting for other side to switch to my invite');
            } else {
              // I switch over to theirs
              setCurrInvite(inviteOther.uid);
              setCurrMode(UserMode.invited, 'checkSentInviteStatusInviting2');
              setTimeout(function() {
                if (allInvites?.[inviteOther.uid]?.state !== InviteState.open) {
                  if (isDevelopment) console.log('not accepting because partner bailed');
                  return;
                }

                if (inviteOther.uid !== currInvite) {
                  if (isDevelopment) console.log('not accepting because invite has been switched');
                  return;
                }

                acceptInvite();
              }, INVITE_ACCEPT_TIME - getAvgCallLatency());
              return;
            }
          }
        });
        if (collision) return;
        console.log('ending invite');
        endInvite(invite.uid);
        setCurrMode(UserMode.open, 'checkSentInviteStatusInviting3');
      }
    }
  }

  function checkAcceptedInvites(): void {
    const invite = allInvites[currInvite];
    if (invite && players[invite.fromID]?.mode === UserMode.open) {
      let tempInvite = currInvite;
      endInvite(currInvite);
      removeInviteFromRoom(tempInvite, roomID);
    }
    if (!invite) setCurrMode(UserMode.open, 'checkAcceptedInvites1');
    else if (invite.state === InviteState.calling) setCurrMode(UserMode.joiningCall, 'checkAcceptedInvites2');
    else if (invite.state === InviteState.rejected) setCurrMode(UserMode.open, 'checkAcceptedInvites3');
    else console.log('Pending call');
  }

  function checkForInvites(): void {
    if (!allInvites) return;
    Object.values(allInvites).forEach(invite => {
      // One inviter at a time. (TODO? Maybe add functionality to deal with multiple invites at a time)
      if (invite.toID === userID && invite.state === InviteState.open) {
        setCurrInvite(invite.uid);
        setCurrMode(UserMode.invited, 'checkForInvites');
        setTimeout(function() {
          if (allInvites?.[invite.uid]?.state !== InviteState.open) return;
          acceptInvite();
        }, INVITE_ACCEPT_TIME - getAvgCallLatency());
        return;
      }
    });
  }

  function checkForRemoval(): void {
    if (!allRemoves) return;
    Object.keys(allRemoves).forEach(key => {
      if (key === userID) {
        // Check to see if we are in the bar as the person
        removeRemovalFromRoom(userID, roomID);
        if (currConvo) endCall();
        setTimeout(() => {
          window.location.href = `${window.location.origin}/hub`;
        }, 1000);
      }
    });
  }

  function follow(followID: string): void {
    if (
      currMode !== UserMode.open &&
      currMode !== UserMode.auto &&
      currMode !== UserMode.inCall &&
      currMode !== UserMode.admin
    )
      return;
    if (currMode === UserMode.inCall || currMode === UserMode.admin) {
      if (allConvos?.[currConvo]?.members?.includes(followID)) return;
      endCall();
    }
    let sprite = sprites[followID];
    if (!sprite) return;
    let followedUser = [sprite.x, sprite.y];
    if (!followedUser) return;
    if (myPlayer.private) {
      myPlayer.private = false;
      logger.info('[PRIVATE] Following user; will unprivatize');
    }
    if (currMode !== UserMode.inCall) {
      setCurrMode(UserMode.auto, 'follow');
    }
    following = followID;
    const pos = followedUser;
    walkSpriteToPoint(mySprite, pos);
  }

  let inviteTimeout: number; // changed from any to number
  function createInvite(invitee: string): boolean {
    // TODO: Make sure this creates a banner when called
    if (currMode !== UserMode.open) return false;
    const invite = allInvites[myPlayer?.inviteID];
    if (invite?.state === InviteState.rejected) return false;
    let inviterUser = [myPlayer.curr_x, myPlayer.curr_y];
    let inviteePlayer = players[invitee];
    if (!inviteePlayer || inviteePlayer.mode !== UserMode.open) return false;
    if (inviteTimeout) {
      clearTimeout(inviteTimeout);
      inviteTimeout = 0;
    }
    if (playersOverlapSquares(myPlayer, inviteePlayer)) {
      let thisInvite = uuidv4().toString();
      setCurrInvite(thisInvite);
      addInviteToRoom(myPlayer?.inviteID, roomID, inviterUser, invitee, userID);
      updateIfAllowed(userID, roomID, myPlayer);
      setCurrMode(UserMode.inviting, 'createInvite');

      inviteTimeout = setTimeout(function() {
        endInvite(thisInvite);
      }, INVITE_MAX_WAIT_TIME);
      return true;
    }
    return true; // In order to type check the function, we are returning a default value
  }

  function acceptInvite(): void {
    const invite = allInvites[currInvite];
    // If invite has been removed.
    if (!invite) {
      logger.info(`[INVITE] Does not exist - USERID: ${userID}`);
      setCurrMode(UserMode.open, 'acceptInvite');
      setCurrInvite('');
      return;
    }

    if (invite.state === InviteState.open && invite.toID === userID) {
      if (players[invite.fromID] && !playersOverlapSquares(myPlayer, players[invite.fromID])) {
        logger.info(`[INVITE] Not Overlapping - USERID: ${userID}`);
        endInvite(currInvite);
        return;
      }
      invite.state = InviteState.accepted;
      updateInvite(currInvite, roomID, invite);
      setCurrMode(UserMode.accepted, 'acceptInvite');
    } else {
      console.log('Invite Closed');
      setCurrMode(UserMode.open, 'acceptInvite');
      setCurrInvite('');
    }
  }

  function endInvite(inviteUID: string): void {
    if (inviteTimeout) clearTimeout(inviteTimeout);
    let invite = allInvites?.[inviteUID];
    if (!invite) return;
    if (invite.toID === userID || inviteUID === myPlayer?.inviteID) {
      invite.state = InviteState.rejected;
      updateInvite(inviteUID, roomID, invite);
      if (inviteUID === currInvite) {
        setCurrMode(UserMode.open, 'endInvite');
        setCurrInvite('');
      }
    }
  }

  function joinSpecificConvo(uid: string): boolean {
    let user = [mySprite.x, mySprite.y];
    if (
      !allConvos ||
      !user ||
      (currMode !== UserMode.open && currMode !== UserMode.joiningCall && currMode !== UserMode.pendingJoin)
    ) {
      logger.info(`[BARSCENE] joinSpecificConvo false 1`);
      return false;
    }

    const convo = allConvos[uid];
    if (!convo) {
      logger.info(`[BARSCENE] joinSpecificConvo false 2`);
      return false;
    }
    // THIS CHECK IS ALSO SERVER SIDE (roomlogic.ts)
    // TODO(kevin)(eli): Why do you check for classroom? Answer: if its a classroom, the host/teacher should be able to join any call
    if (
      (convo?.private || convo.members.length >= MAX_CONVO_SIZE) &&
      (roomID !== userID || currDesign !== 'classroom')
    ) {
      logger.info(`[BARSCENE] joinSpecificConvo false 3`);
      return false;
    }
    const admin = convo?.members[0];
    convo?.members?.forEach((member: string) => {
      if (member !== userID) {
        convoArrivalTime[member] = Date.now();
      }
    });
    // If admin deosn't exist if you don't overlap with conversation
    if (!admin) return false;
    if (!callOverlap(convo)) return false;
    if (wrtc?.conn) {
      logger.info(`[BARSCENE] joinSpecificConvo true 1`);
      console.log('Already initialized');
      return true;
    }
    setCurrMode(UserMode.awaitingCall, 'joinSpecificConvo');
    currConvo = convo.uid;
    myPlayer.convoID = currConvo;
    backend.sendHeartbeat(userID, roomID, uid);
    updateIfAllowed(userID, roomID, myPlayer);
    backend.joinConvo(currConvo);
    setTimeout(() => positionSelfInConvo(allConvos?.[currConvo]), 100);
    // TODO(kevin): Refactor
    let start = Date.now();
    if (wrtc) {
      WebRTC.connect(
        wrtc,
        userID,
        convo.uid,
        refStatus.audioOn,
        refStatus.videoOn,
        userUpdate,
        addStream,
        removedStream,
        () => {},
        newWrtc => {
          positionSelfInConvo(allConvos?.[currConvo]);
          let called = Date.now();
          updateCallLatency(called - start);
          logger.info(
            `[BARSCENE] time to joinSpecificConvo with ${convo.members}: ${called - start} avg: ${getAvgCallLatency()}`
          );
          logger.info('[CONVO] Call started');
          if (!newWrtc.conn) {
            logger.info('[CONVO] No longer in call');
            return;
          }
          myPlayer.socketID = newWrtc.conn?.socket.id || '';
          updateIfAllowed(userID, roomID, myPlayer);
          // WEBRTC AUTOMATICALLY STARTS INCALL

          setCurrMode(UserMode.inCall);
          disableMovement = 3;
          setIsInCall(true);
        }
      );
      setRender(!render);
    } else {
      alert('WRTC IS NULL');
    }
    logger.info(`[BARSCENE] joinSpecificConvo true 2`);
    return true;
  }

  function callOverlap(convo: ConvoDataType): boolean {
    let allMembers = false;
    if (!convo) {
      return allMembers;
    }
    for (let i = 0; i < convo.members.length; i++) {
      if (convo.members[i] !== userID) {
        // Check if the curr_x is approximate to anyone in the call
        if (players[convo.members[i]]) {
          const player = players[convo.members[i]];
          allMembers = allMembers || playersOverlapSquares(myPlayer, player);
        } else {
          allMembers = allMembers || false;
        }
      }
    }
    return allMembers;
  }

  function executeThrottle(throttleLevel: number): void {
    if (throttleLevel > 6) throttleLevel = 6;
    else if (throttleLevel < 0) throttleLevel = 0;
    if (wrtc) {
      WebRTC.setBandwidth(wrtc, throttleLevel);
    }
  }

  function inspectElements(): number {
    updateConvoConnections();
    let convo = allConvos[currConvo];
    if (!convo) return 0;
    let timeCount = 0;
    for (let i = 0; i < convo.members.length; i++) {
      if (convo.members[i] !== userID) {
        let element = elements[convo.members[i]];
        if (element && element.readyState > 2) {
          videoConnected[convo.members[i]] = null;
          element.play();
        } else if (element) {
          if (videoConnected[convo.members[i]] === null) {
            videoConnected[convo.members[i]] = Date.now();
          } else {
            const failTime = videoConnected[convo.members[i]];
            if (failTime !== null) timeCount += Date.now() - failTime;
          }
        }
      }
      let playerSocket = players?.[convo.members[i]]?.socketID;
      if (playerSocket) {
        let sprite = convo.members[i] === userID ? mySprite : sprites[convo.members[i]];
        if (wrtc?.conn?.users[playerSocket]?.videoOn) removeCameraOffFromSprite(sprite);
        else addCameraOffToSprite(sprite);
        if (wrtc?.conn?.users[playerSocket]?.audioOn) removeMicOffFromSprite(sprite);
        else addMicOffToSprite(sprite);
      }
    }
    if (timeCount > 3000 && wrtc && wrtc.throttleLevel < 6) {
      logger.info(
        `[THROTTLE] setting throttle level to ${wrtc.throttleLevel + 1} for user ${userID} in convo ${currConvo}`
      );
      executeThrottle(wrtc.throttleLevel + 1);
      updateConvo(currConvo, roomID, { throttleUpdate: Date.now() }); //Be sure to not update anything more than this single attribute
      resetThrottleTimer();
    }
    return timeCount;
  }

  //Only used for logger. Designed to detect when a call connection failed.
  //uses convoArrivalTime and convoConnections
  function updateConvoConnections(): void {
    let convo = allConvos[currConvo];
    if (!convo) return;
    for (let i = 0; i < convo.members.length; i++) {
      if (convo.members[i] !== userID) {
        let element = elements[convo.members[i]];
        if (element && element.readyState > 2) {
          convoConnections[convo.members[i]] = true;
        }
      }
    }
  }

  function resetThrottleTimer(): void {
    let convo: ConvoDataType = allConvos[currConvo];
    if (!convo) return;
    for (let i = 0; i < convo.members.length; i++) {
      if (convo.members[i] !== userID) {
        videoConnected[convo.members[i]] = null;
      }
    }
  }

  //This function automatically closes a convo when there is one user left
  const checkConvoMembers = (): void => {
    if (currConvo.length === 0) {
      // Something out of sync happened so correcting
      initialConvo = null;
      return;
    }
    if (disableMovement) disableMovement--;
    let convo = allConvos[currConvo];

    if (!convo && !initialConvo) {
      logger.info(`[CONVO] Not Convo - ConvoID: ${currConvo} userID: ${userID}`);
      endCall();
      return;
    }
    // DO NOT ADD LOGIC HERE (ADD BELOW);
    // If convo hasn't been updated we will continue to return until it has
    if (convo) {
      initialConvo = null;
    } else if (initialConvo) {
      convo = initialConvo;
    } else {
      logger.info(`[CONVO] Not convo 2 - ConvoID: ${currConvo} userID: ${userID}`);
      endCall();
      return;
    }
    //Ignore all members who are not actively participating in the call
    convo.members = convo.members.filter(function(member: string) {
      const player = players?.[member];
      if (!player) return false;
      const mode = player.mode;
      if (
        !mode ||
        mode === UserMode.open ||
        mode === UserMode.leavingCall ||
        mode === UserMode.afk ||
        mode === UserMode.endingCall
      ) {
        if (isDevelopment) console.log('OPEN PLAYER', member, player);
        return false;
      }
      return true;
    });
    // ADD LOGIC BELOW THIS LINE
    // Use different logic than TODO isInCall (create a new timeout after joining a call)

    if ((currMode === UserMode.inCall || currMode === UserMode.admin) && !convo.members.includes(userID)) {
      //logger.info('[CONVO] Not a member of the convo, add them to the list');
      //This is an interesting idea but it causes crashes if unable to connect to the server
      // if (!resettingCall) {
      //   resettingCall = true;
      //   setTimeout(resetCall, 1000);
      // }
      // if (!resettingCall) {
      //   resettingCall = true;
      //   setTimeout(() => {
      //     resettingCall = false;
      //     if (allConvos?.[currConvo]?.members?.includes(userID)) return;
      //     else endCall();
      //   }, 2000);
      // }
      // backend.joinConvo(currConvo);
      logger.info(`[CONVO] Not a member of the convo, leaving - ConvoID: ${currConvo} userID: ${userID}`);
      endCall();
      return;
    }

    if (convo.members.length === 1) {
      // If so end this conversation and disconnect the Twilio call.
      logger.info(`[CONVO] Only one person in the convo - ConvoID: ${currConvo} userID: ${userID}`);
      endCall();
      return;
    }

    if (!callOverlap(convo)) {
      if (target?.length && myPlayer.arrowState === ArrowState.inactive) return;
      logger.info(`[CONVO] Not Overlap -  ConvoID: ${currConvo} userID: ${userID}`);
      endCall();
      return;
    }

    inspectElements();
    zoomCaller();
    if (convo.members.length >= MAX_CONVO_SIZE && !convo.private) {
      // Update once
      if (!myPlayer.private) {
        logger.info(`[CONVO] 6 person call toggilng self private - ConvoID: ${currConvo} userID: ${userID}`);
        myPlayer.private = true;
        updateIfAllowed(userID, roomID, myPlayer);
      }
    } else if (convo.private !== myPlayer.private) {
      logger.info(
        `[CONVO] toggling from private: ${myPlayer.private} to ${convo.private} - ConvoID: ${currConvo} userID: ${userID}`
      );
      myPlayer.private = convo.private;
      updateIfAllowed(userID, roomID, myPlayer);
    }
    positionSelfInConvo(convo);
  };

  function positionSelfInConvo(convo: ConvoDataType) {
    if (!convo?.members?.includes(userID)) return;
    let pos = [convo.pos[0], convo.pos[1]];
    let xDisplacement = w;
    let yDisplacement = h;
    if (convo.members[0] === userID) {
      if (currMode !== UserMode.admin) setCurrMode(UserMode.admin);
    } else if (convo.members[1] === userID) {
      pos[0] += xDisplacement;
    } else if (convo.members.length === 3) {
      if (convo.members[2] === userID) {
        pos[0] += xDisplacement;
        pos[1] += yDisplacement;
      }
    } else if (convo.members.length === 4) {
      if (convo.members[2] === userID) {
        pos[0] += xDisplacement;
        pos[1] += yDisplacement;
      } else if (convo.members[3] === userID) {
        pos[1] += yDisplacement;
      }
    } else if (convo.members.length === 5) {
      if (convo.members[2] === userID) {
        pos[0] += 2 * xDisplacement;
      } else if (convo.members[3] === userID) {
        pos[0] += 2 * xDisplacement;
        pos[1] += yDisplacement;
      } else if (convo.members[4] === userID) {
        pos[0] += xDisplacement;
        pos[1] += yDisplacement;
      }
    } else if (convo.members.length === 6) {
      if (convo.members[2] === userID) {
        pos[0] += 2 * xDisplacement;
      } else if (convo.members[3] === userID) {
        pos[0] += 2 * xDisplacement;
        pos[1] += yDisplacement;
      } else if (convo.members[4] === userID) {
        pos[0] += xDisplacement;
        pos[1] += yDisplacement;
      } else if (convo.members[5] === userID) {
        pos[1] += yDisplacement;
      }
    } else if (convo.members.length === 7) {
      if (convo.members[2] === userID) {
        pos[0] += 2 * xDisplacement;
      } else if (convo.members[3] === userID) {
        pos[0] += 3 * xDisplacement;
      } else if (convo.members[4] === userID) {
        pos[0] += 3 * xDisplacement;
        pos[1] += yDisplacement;
      } else if (convo.members[5] === userID) {
        pos[0] += 2 * xDisplacement;
        pos[1] += yDisplacement;
      } else if (convo.members[6] === userID) {
        pos[0] += xDisplacement;
        pos[1] += yDisplacement;
      }
    } else if (convo.members.length === 8) {
      if (convo.members[2] === userID) {
        pos[0] += 2 * xDisplacement;
      } else if (convo.members[3] === userID) {
        pos[0] += 3 * xDisplacement;
      } else if (convo.members[4] === userID) {
        pos[0] += 3 * xDisplacement;
        pos[1] += yDisplacement;
      } else if (convo.members[5] === userID) {
        pos[0] += 2 * xDisplacement;
        pos[1] += yDisplacement;
      } else if (convo.members[6] === userID) {
        pos[0] += xDisplacement;
        pos[1] += yDisplacement;
      } else if (convo.members[7] === userID) {
        pos[1] += yDisplacement;
      }
    }
    if ((Math.abs(pos[0] - mySprite.x) < 1 && Math.abs(pos[1] - mySprite.y) < 1) || target.length) return;
    walkSpriteToPoint(mySprite, pos);
  }

  function setZIndex(): void {
    Object.keys(sprites).forEach(key => {
      const sprite = sprites[key];
      if (currMode === UserMode.inCall || currMode === UserMode.admin) {
        if (allConvos?.[currConvo]?.members?.includes(key)) sprite.zIndex = 5;
        else sprite.zIndex = 2;
      } else {
        const player = players[key];
        if (player && (player.mode === UserMode.inCall || player.mode === UserMode.admin)) sprite.zIndex = 2;
        else sprite.zIndex = 3;
      }
    });
    if (currMode === UserMode.inCall || currMode === UserMode.admin) {
      mySprite.zIndex = 6;
    } else {
      mySprite.zIndex = 4;
    }
  }

  function getLoveCampusScalingFactor(): number {
    let scalingFactor: number = Math.min(getScreenWidth() / (3776 / 2), (window.innerHeight - 90) / (1000 / 2));
    return scalingFactor;
  }

  function getClassroomScalingFactor(): number {
    let scalingFactor: number = Math.min(getScreenWidth() / 2000, (window.innerHeight - 90) / 1200);
    return scalingFactor;
  }

  function positionStage(convo: ConvoDataType, recenterCheck: boolean, open: number): void {
    if (!recenterCheck) {
      let stageY: number = convo.pos[1];
      let stageX: number = convo.pos[0];
      let scalingFactor: number = 1;
      let numMembers: number = convo.members.length;
      let xOffset: number = w;
      let convoWidth: number = 2;
      if (numMembers === 6 || numMembers === 5) {
        xOffset = (3 * w) / 2;
        convoWidth = 3;
      }
      if (numMembers === 7 || numMembers === 8) {
        xOffset = 2 * w;
        convoWidth = 4;
      }
      if (open === BroadcastMode.closedHost) {
        stageY += h;
        stageX += xOffset;
        scalingFactor = (window.innerHeight - 165) / (2 * h);
      } else if (open > 0) {
        //if open (host or listener)
        stageX += xOffset;
        stageY += 19;
        scalingFactor = (window.innerHeight + h - 15) / (2 * h) / 2;
      } else {
        stageX += xOffset;
        scalingFactor = (window.innerHeight - 91) / (2 * h);
        stageY += h - 25 / scalingFactor;
      }
      const dx = stageX - app.stage.pivot.x;
      const dy = stageY - app.stage.pivot.y;
      // const dxB = app.stage.pivot.x - stageX;
      // const dyB = app.stage.pivot.y - stageY;
      // let prevFactor = 1;
      // if (roomID === 'lovecampus') prevFactor = getLoveCampusScalingFactor();
      // else if (currDesign === 'classroom') prevFactor = getClassroomScalingFactor();
      //TODO: get zoom back working again
      //zoomBack = [app.stage.pivot.x, app.stage.pivot.y, dxB, dyB, prevFactor];
      if (w * convoWidth * scalingFactor > getScreenWidth()) {
        scalingFactor = getScreenWidth() / (convoWidth * w + 5);
      }
      zoomTarget = [stageX, stageY, dx, dy, scalingFactor];
    } else {
      zoomTarget = zoomBack;
      zoomBack = [];
    }
  }

  const gameLoop = (): void => {
    // something every tick
    moveSprite(target);
    //updateSprites();
    moveZoom(zoomTarget);
    thisLoop = Date.now();
    var thisFrameTime: number = thisLoop - lastLoop;
    frameTime += (thisFrameTime - frameTime) / filterStrength;
    lastLoop = thisLoop;
  };

  function checkIfCanInvite(otherKey: string): void {
    const player = players[otherKey];
    if (!player) return;
    if (myPlayer.arrowState) return;
    // We want to check if you are visibly invitable
    if (playersOverlapSquares(myPlayer, player)) {
      if ((myPlayer.private || player.private) && (roomID !== userID || currDesign !== 'classroom')) return;
      if (player.mode !== UserMode.inCall && player.mode !== UserMode.admin) {
        if (player.arrowState) return;
        createInvite(otherKey);
      } else {
        if (!allConvos?.[player.convoID]) return;
        if (allConvos?.[player.convoID].private && (roomID !== userID || currDesign !== 'classroom')) return;
        if (currMode === UserMode.pendingJoin) {
          logger.info("[INVITE] Can't send duplicate invites");
          return;
        }
        setCurrInvite(player.convoID);
        setCurrMode(UserMode.pendingJoin);
        setTimeout(function() {
          if (currMode !== UserMode.pendingJoin) return;
          setCurrMode(UserMode.joiningCall, 'pendingJoin');
        }, INVITE_ACCEPT_TIME - getAvgCallLatency());
      }
    }
  }

  /**
   * Method animates a given sprite to the data location
   *
   * Called very frequently
   */
  const moveSprite = (data: number[]): void => {
    if (!serverConnectedRef.current || !onlineRef.current) return;
    let id: string = userID;
    let sprite: PIXI.Sprite = mySprite;
    let player: PlayerType = myPlayer;
    let arrow: number = player?.arrowState;
    if (disableMovement && !(player?.mode === UserMode.inCall || player?.mode === UserMode.admin)) {
      disableMovement = 0;
    }
    //Turning off disable movement to prevent any possibility of freezing
    if (arrow) {
      // commonly used for
      if (arrow === ArrowState.stop) {
        //tag
        myPlayer.arrowState = ArrowState.inactive;
        if (!hasMoved) {
          updateIfAllowed(id, roomID, myPlayer);
          return;
        }
        myPlayer.curr_x = sprite.x;
        myPlayer.curr_y = sprite.y;
        updateIfAllowed(id, roomID, myPlayer);
        if (currMode === UserMode.open && !target.length && !myPlayer.arrowState) {
          Object.keys(sprites).forEach(key => {
            checkIfCanInvite(key);
          });
        }
        return;
      }

      let timeDif = Date.now() - thisLoop;

      var arrowSpeed = Math.min(MAX_STEP_SIZE, desiredSpeed * timeDif);
      let currX = sprite.x;
      let currY = sprite.y;
      let nextX = currX;
      let nextY = currY;
      let yString = '';
      let xString = '';
      if (arrow === ArrowState.north || arrow === ArrowState.northeast || arrow === ArrowState.northwest) {
        nextY = sprite.y - arrowSpeed;
        yString = 'up';
      } else if (arrow === ArrowState.south || arrow === ArrowState.southwest || arrow === ArrowState.southeast) {
        nextY = sprite.y + arrowSpeed;
        yString = 'down';
      }
      if (arrow === ArrowState.northwest || arrow === ArrowState.west || arrow === ArrowState.southwest) {
        nextX = sprite.x - arrowSpeed;
        xString = 'left';
      } else if (arrow === ArrowState.northeast || arrow === ArrowState.east || arrow === ArrowState.southeast) {
        nextX = sprite.x + arrowSpeed;
        xString = 'right';
      }

      spritesInPlace[id] = false;

      const { trueX, trueY } = enforceBarriers(boundingPoly, currX, currY, nextX, nextY, xString, yString);
      if (trueX === currX && trueY === currY && isBlocked(boundingPoly, currX, currY, arrowSpeed - 3)) {
        logger.info('BLOCKED');
        let pos = allConvos?.[myPlayer?.convoID]?.pos;
        if (currMode === UserMode.inCall || (currMode === UserMode.admin && pos)) {
          sprite.x = pos[0];
          sprite.y = pos[1];
          sprite.zIndex = 7;
          const data = { curr_x: mySprite.x, curr_y: mySprite.y };
          updateIfAllowed(userID, roomID, data);
          // const dx = pos[0] - currX;
          // const dy = pos[1] - currY;
          // const mag = Math.hypot(dx, dy);
          // const adjDX = (dx / mag) * arrowSpeed;
          // const adjDY = (dy / mag) * arrowSpeed;
          // sprite.x = currX + adjDX;
          // sprite.y = currY + adjDY;
        } else {
          logger.info('STUCK');
          setIsStuck(true);
          keyMap = {};
        }
      } else {
        sprite.x = trueX;
        sprite.y = trueY;
      }

      let spriteMini = spritesMini[id];
      spriteMini.x = sprite.x * miniMapScale;
      spriteMini.y = sprite.y * miniMapScale;
      reCenter();
    } else if (data.length) {
      // used for forced placement
      var [x, y, xRate, yRate] = data; // eslint-disable-line
      const dx = x - sprite.x - w / 2;
      const dy = y - sprite.y - h;
      // If within 5 pixels, just snap to desired target
      if (Math.hypot(dx, dy) <= 5) {
        sprite.x = x - w / 2;
        sprite.y = y - h;

        target = [];
        myPlayer.curr_x = sprite.x;
        myPlayer.curr_y = sprite.y;
        updateIfAllowed(userID, roomID, myPlayer);
        if (following && following !== userID) {
          let followedSprite = players[following];
          if (playersOverlapSquares(myPlayer, followedSprite)) {
            const fol = following;
            setCurrMode(UserMode.open, 'moveSprite');
            checkIfCanInvite(fol);
          }
        }
      } else {
        if (following && following !== userID) {
          let followedSprite = players[following];
          if (playersOverlapSquares(myPlayer, followedSprite)) {
            const fol = following;
            setCurrMode(UserMode.open, 'moveSprite');
            checkIfCanInvite(fol);
            const playerUpdateData: PlayerLocationType = {
              curr_x: sprite.x,
              curr_y: sprite.y,
            };
            updateIfAllowed(userID, roomID, playerUpdateData);
            target = [];

            return;
          }
        }

        let dx2 = dx;
        let dy2 = dy;
        let nextX = sprite.x;
        let nextY = sprite.y;
        const modifier = 1;
        var maxSpeed = 7 * modifier;
        if (dx2 && dy2) maxSpeed = 9.8 * modifier;
        let dif = Math.hypot(dx2, dy2);
        if (dif <= maxSpeed) {
          nextX += dx2;
          nextY += dy2;
        } else {
          dx2 = (dx2 / dif) * maxSpeed;
          dy2 = (dy2 / dif) * maxSpeed;
          nextX += dx2;
          nextY += dy2;
        }

        // let nextX = sprite.x + xRate / SPEED / 30;
        // let nextY = sprite.y + yRate / SPEED / 30;

        //TODO (Hicks) Can you enforce barriers here
        let spriteMini = spritesMini[id];

        // by this time we have nextX and nextY defined

        // let {trueX, trueY} = enforceBarriers(sprite.x, sprite.y, nextX, nextY);

        // This is the only place where the position is set for automatic
        if (!isNaN(nextX)) {
          sprite.x = nextX;
          spriteMini.x = sprite.x * miniMapScale;
        }

        if (!isNaN(nextY)) {
          sprite.y = nextY;
          spriteMini.y = sprite.y * miniMapScale;
        }
      }
      reCenter();
    }
  };

  function moveZoom(data: number[]): void {
    if (data.length) {
      var [x, y, xRate, yRate, scalingFactor] = data;
      let zRate = scalingFactor - app.stage.scale.x;
      const dx = x - app.stage.pivot.x;
      const dy = y - app.stage.pivot.y;
      // If within 5 pixels, just snap to desired target
      if (Math.hypot(dx, dy) <= 20) {
        // if (w * 2 * scalingFactor > window.innerHeight - 100)
        //   scalingFactor = window.innerHeight / (window.innerHeight / 10 + 2 * h);
        app.stage.scale.x = scalingFactor;
        app.stage.scale.y = scalingFactor;
        app.stage.pivot.x = x;
        app.stage.position.x = getScreenWidth() / 2;
        app.stage.pivot.y = y;
        app.stage.position.y = window.innerHeight / 2;
        zoomTarget = [];
      } else {
        let nextX = app.stage.pivot.x + xRate / SPEED / 30;
        let nextY = app.stage.pivot.y + yRate / SPEED / 30;
        let nextZ = app.stage.scale.x + zRate / SPEED / 30;

        let nextDX = x - nextX;
        let nextDY = y - nextY;

        if (Math.abs(nextDX) > Math.abs(dx) || Math.sign(nextDX) !== Math.sign(dx)) {
          nextX = app.stage.pivot.x;
          zoomTarget[0] = nextX;
          zoomTarget[2] = 0;
        }
        if (Math.abs(nextDY) > Math.abs(dy) || Math.sign(nextDY) !== Math.sign(dy)) {
          nextY = app.stage.pivot.y;
          zoomTarget[1] = nextY;
          zoomTarget[3] = 0;
        }

        // if (Math.abs(zRate) < 0.0001) {
        //   zoomTarget = [];
        // }

        app.stage.scale.x = nextZ;
        app.stage.scale.y = nextZ;
        app.stage.pivot.x = nextX;
        app.stage.position.x = getScreenWidth() / 2;
        app.stage.pivot.y = nextY;
        app.stage.position.y = window.innerHeight / 2;
      }
    }
  }

  function playersOverlapSquares(sprite: PlayerType, value: PlayerType): boolean {
    if (!sprite || !value) return false;
    if (sprite.curr_x === value.curr_x && sprite.curr_y === value.curr_y) return true;
    let otherRect = new PIXI.Rectangle(value.curr_x - 5, value.curr_y - 5, spriteSize[0] + 10, spriteSize[1] + 10);
    let thisRect = new PIXI.Rectangle(sprite.curr_x, sprite.curr_y, spriteSize[0], spriteSize[1]);

    let collision =
      otherRect.contains(thisRect.x, thisRect.y) ||
      otherRect.contains(thisRect.x + thisRect.width, thisRect.y) ||
      otherRect.contains(thisRect.x + thisRect.width, thisRect.y + thisRect.height) ||
      otherRect.contains(thisRect.x, thisRect.y + thisRect.height);
    return collision;
  }

  function walkSpriteToPoint(sprite: PIXI.Sprite, pos: number[]): void {
    if (sprite !== mySprite) return;
    if (pos[0] === mySprite.x && pos[1] === mySprite.y) return;
    pos[0] = pos[0] + w / 2;
    pos[1] = pos[1] + h;
    const dx = pos[0] - sprite.x - w / 2;
    const dy = pos[1] - sprite.y - h;
    // Update texture based on direction of mouse click
    if (sprite === mySprite) {
      target = [pos[0], pos[1], dx, dy];
    }
    const updatedMyPlayer: PlayerType = {
      ...myPlayer,
      click_x: pos[0],
      click_y: pos[1],
      curr_x: mySprite.x,
      curr_y: mySprite.y,
    };
    updateIfAllowed(userID, roomID, updatedMyPlayer);
    return;
  }

  function reCenter(): void {
    if (currMode === UserMode.inCall || currMode === UserMode.admin) return;
    if (roomID === 'lovecampus') {
      let scalingFactor = getLoveCampusScalingFactor();
      app.stage.pivot.x = 2394 / 2;
      app.stage.position.x = getScreenWidth() / 2;
      app.stage.pivot.y = 1511 / 2 - 100;
      app.stage.position.y = window.innerHeight / 2;

      app.stage.scale.x = scalingFactor;
      app.stage.scale.y = scalingFactor;
      return;
    } else if (currDesign === 'classroom') {
      let scalingFactor = getClassroomScalingFactor();
      app.stage.pivot.x = 2300 / 2;
      app.stage.position.x = getScreenWidth() / 2;
      app.stage.pivot.y = 1100 / 2;
      app.stage.position.y = window.innerHeight / 2;

      app.stage.scale.x = scalingFactor;
      app.stage.scale.y = scalingFactor;
      return;
    }

    let dx = mySprite.x - app.stage.pivot.x;
    let dy = mySprite.y - (window.innerHeight / 4 - 70) - app.stage.pivot.y;
    reCenterSmoother(dx, dy);
    app.stage.scale.x = 1 / res;
    app.stage.scale.y = 1 / res;
  }

  function reCenterSmoother(dx: number, dy: number): void {
    app.stage.pivot.x = app.stage.pivot.x + dx;
    app.stage.position.x = getScreenWidth() / 2 - w / 2;
    app.stage.pivot.y = app.stage.pivot.y + dy;
    app.stage.position.y = window.innerHeight / 2;
    //setTimeout(() => reCenterSmoother(dx, dy, frames-1), 1000/30/frames);
  }

  const brightnessFilter = (n: number): number => {
    const b = (Math.cos(n) + 1) / 4;
    return b;
  };

  let increment = 0.2;

  /**
   * Method is called every game tick.
   * Loops through all other players in room, and updates their position if changed
   */
  const updateSprites = (): void => {
    if (!players) {
      lastUpdate = Date.now();
      return;
    }
    Object.keys(players).forEach(key => {
      const player = players[key];
      if (sprites?.[key]) {
        const sprite = sprites[key];
        const filterSprite = getLayerFromSprite(sprite, SpriteLayers.filter);
        const webcamSprite = getLayerFromSprite(sprite, SpriteLayers.webcam);
        if (filterSprite) {
          if (
            player.mode === UserMode.invited ||
            player.mode === UserMode.accepted ||
            player.mode === UserMode.inviting ||
            player.mode === UserMode.pendingJoin ||
            player.mode === UserMode.joiningCall ||
            player.mode === UserMode.awaitingCall
          ) {
            currBrightness = currBrightness + increment;
            filterSprite.alpha = brightnessFilter(currBrightness);
          } else if (player.mode !== UserMode.afk) {
            filterSprite.alpha = 0;
          }
        }
        const { curr_x, curr_y } = player;
        const dx = curr_x - sprite.x;
        const dy = curr_y - sprite.y;
        if (dx || dy) {
          moveSpriteOther(key, sprite, [curr_x, curr_y], dx, dy);
        }

        if (currConvo && webcamSprite) {
          webcamSprite.width = spriteSize[0];
          webcamSprite.height = spriteSize[1];
        }
      }
    });
    const filterSprite = getLayerFromSprite(mySprite, SpriteLayers.filter);
    if (filterSprite) {
      if (
        currMode === UserMode.invited ||
        currMode === UserMode.inviting ||
        currMode === UserMode.accepted ||
        currMode === UserMode.pendingJoin ||
        currMode === UserMode.joiningCall ||
        currMode === UserMode.awaitingCall
      ) {
        currBrightness = currBrightness + increment;
        filterSprite.alpha = brightnessFilter(currBrightness);
      } else {
        if (currMode !== UserMode.afk) {
          filterSprite.alpha = 0;
        }
      }
    }
    lastUpdate = Date.now();
  };

  function handleNameTags(): void {
    if (!sprites) return;
    Object.keys(sprites).forEach((sprite: string) => {
      handleNameTag(sprite);
    });
    handleNameTag(userID);
  }

  function handleNameTag(sprite: string): void {
    if (nameTag.current) {
      addNameTagToSprite(sprite);
    } else {
      removeNameTagFromSprite(sprite);
    }
  }

  async function moveSpriteOther(
    id: string,
    sprite: PIXI.Sprite,
    point: number[],
    dx: number,
    dy: number
  ): Promise<void> {
    // if (point[0] === playerSpawnX && point[1] === playerSpawnY && !sprite.placed) {
    //   sprite.x = point[0];
    //   sprite.y = point[1];
    //   sprite.placed = true;
    // }
    let timeDif = Date.now() - lastUpdate;
    let arrowSpeed = desiredSpeed * timeDif;
    let maxSpeed = arrowSpeed;
    if (dx && dy) maxSpeed = Math.hypot(maxSpeed, maxSpeed);
    let dif = Math.hypot(dx, dy);

    if (dif > maxSpeed * 20) {
      sprite.x = point[0];
      sprite.y = point[1];
    } else if (dif <= maxSpeed) {
      sprite.x = point[0];
      sprite.y = point[1];
    } else {
      dx = (dx / dif) * maxSpeed;
      dy = (dy / dif) * maxSpeed;
      sprite.x += dx;
      sprite.y += dy;
    }
    const spriteMini = spritesMini[id];
    if (!spriteMini || !spriteMini?.transform?.position) return;
    // TODO(eli): Something here continues to null. Fixed (I think)
    spriteMini.x = sprite.x * miniMapScale;
    spriteMini.y = sprite.y * miniMapScale;
  }

  // Game loop useEffect
  useEffect(() => {
    // app.ticker.add(gameLoop);
    setInterval(gameLoop, 1000 / 60);
    setInterval(updateSprites, 1000 / 21);
    // app.renderer.resolution = 0.2;
    // app.renderer.width = window.innerWidth - SIDEBAR_WIDTH;
    // app.renderer.height = window.innerHeight;
    const ticker = app.ticker;
    ticker.maxFPS = 20;

    miniMap.ticker.maxFPS = 2;
    container.current.appendChild(app.view);
    console.log('container: ', container);
    containerMini.current.appendChild(miniMap.view);
    console.log('containerMini: ', containerMini);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCircleTexture = (radius: number, color: number): PIXI.RenderTexture => {
    const gfx = new PIXI.Graphics();
    const tileSize = radius * 1;
    const texture = PIXI.RenderTexture.create({ width: tileSize, height: tileSize });

    gfx.beginFill(color);
    gfx.drawCircle(tileSize / 2, tileSize / 2, radius);
    gfx.endFill();

    miniMap.renderer.render(gfx, texture);

    return texture;
  };

  function addMyMiniSprite(): void {
    if (spritesMini[userID]) return;
    let circleTexture = generateCircleTexture(5, 0xc20000);
    let circleSprite = new PIXI.Sprite(circleTexture);
    miniMapParticleContainer.addChild(circleSprite);
    circleSprite.x = playerSpawnX * miniMapScale;
    circleSprite.y = playerSpawnY * miniMapScale;
    spritesMini[userID] = circleSprite;
  }

  const addMyPlayer = (): void => {
    const faceSprite = getLayerFromSprite(mySprite, SpriteLayers.face);
    if (mySprite && faceSprite) {
      faceSprite.texture = PIXI.Texture.from(myImage);
      return;
    }
    mySprite = createPlayer(playerSpawnX, playerSpawnY, userID);
    mySprite.zIndex = 4;
    mySprite.interactive = false;
    mySprite.buttonMode = false;
    const webcamSprite = new PIXI.Sprite();
    webcamSprite.width = spriteSize[0];
    webcamSprite.height = spriteSize[1];
    webcamSprite.name = SpriteLayers.webcam;
    mySprite.addChildAt(webcamSprite, 1);

    appviewport.addChild(mySprite);
    addMyMiniSprite();
  };

  function createPlayer(x: number, y: number, key: string): PIXI.Sprite {
    const playerSprite: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
    const faceSprite: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);

    const roundedMask = new PIXI.Graphics()
      .beginFill(0x161616, 1)
      .lineStyle(0, 0xff0000)
      .drawRoundedRect(0, 0, spriteSize[0], spriteSize[1], 20)
      .endFill();
    roundedMask.name = 'roundedMask';

    if (key === userID) {
      if (myImage) {
        faceSprite.texture = PIXI.Texture.from(myImage);
      } else {
        blobToText(key)
          .then((e: any) => {
            faceSprite.texture = PIXI.Texture.from(e);
          })
          .catch((e: any) => {
            console.log('%c ERROR with contacting server', 'color: blue;');
            faceSprite.texture = PIXI.Texture.from(VartyLogo.toString());
            console.log('Error contacting server, please refresh. Error code BS-LOAD-001');
          });
      }
    } else {
      if (key.includes('bot_')) {
        faceSprite.texture = PIXI.Texture.from(VartyLogo.toString());
      } else {
        blobToText(key)
          .then((e: any) => {
            faceSprite.texture = PIXI.Texture.from(e);
          })
          .catch((e: any) => {
            console.log('%c ERROR with contacting server', 'color: blue;');
            faceSprite.texture = PIXI.Texture.from(VartyLogo.toString());
            console.log('%c Error contacting server, please refresh. Error code BS-LOAD-002', 'color: orange;');
          });
      }
    }

    faceSprite.name = 'face';
    faceSprite.mask = roundedMask;
    faceSprite.zIndex = 2;

    faceSprite.width = spriteSize[0];
    faceSprite.height = spriteSize[1];

    playerSprite.addChild(faceSprite);

    playerSprite.addChild(roundedMask);
    playerSprite.mask = roundedMask;

    const borderFrame = new PIXI.Graphics()
      .lineStyle(4, 0xffffff)
      .drawRoundedRect(0, 0, spriteSize[0], spriteSize[1], 20)
      .endFill();
    borderFrame.name = SpriteLayers.border;
    borderFrame.alpha = 0;
    const connectionFrame = new PIXI.Graphics()
      .beginFill(0xffffff, 0.5)
      .lineStyle(4, 0xffffff)
      .drawRoundedRect(0, 0, spriteSize[0], spriteSize[1], 20)
      .endFill();
    connectionFrame.name = SpriteLayers.filter;
    connectionFrame.alpha = 0;
    playerSprite.addChild(borderFrame);
    playerSprite.addChild(connectionFrame);
    playerSprite.x = x;
    playerSprite.y = y;
    playerSprite.interactive = false;
    playerSprite.buttonMode = false;
    playerSprite.name = 'player';
    playerSprite.hitArea = new PIXI.Rectangle(0, 0, spriteSize[0], spriteSize[1]);

    faceSprite.scale.x *= -1;
    faceSprite.pivot.set(320, 0);

    // setInterval(()=>{
    //   addReconnectToSprite(playerSprite);
    //   setTimeout(()=> removeReconnectFromSprite(playerSprite), 1000)
    // }, 3000)
    playerSprite.scale.x *= -1;
    playerSprite.pivot.set(spriteSize[0], 0);
    return playerSprite;
  }

  function onDisconnectFromSprite(sprite: PIXI.Sprite) {
    removeReconnectFromSprite(sprite);
    removeCameraOffFromSprite(sprite);
    removeMicOffFromSprite(sprite);
  }

  function addReconnectToSprite(sprite: PIXI.Sprite): void {
    if (!sprite) return;
    let text = sprite?.getChildByName('reconnecting');
    if (text) {
      // Already added
      return;
    }
    text = new PIXI.Text('Reconnecting...', { fontFamily: 'Arial', fontSize: 10, fill: 0x1a1a1a, align: 'center' });
    text.name = 'reconnecting';
    sprite.addChild(text);
    text.scale.x = -1;
    text.x = 80;
    text.y = 40;
  }

  function removeReconnectFromSprite(sprite: PIXI.Sprite): void {
    if (!sprite) return;
    let text = sprite?.getChildByName('reconnecting');
    while (text) {
      text.destroy();
      text = sprite?.getChildByName('reconnecting');
    }
  }

  function addCameraOffToSprite(sprite: PIXI.Sprite): void {
    if (!sprite) return;
    let icon = sprite?.getChildByName('cameraOff');
    if (icon) {
      return;
    }
    icon = new PIXI.Sprite(CameraOff);
    icon.name = 'cameraOff';
    sprite.addChild(icon);
    icon.scale.x = -0.07;
    icon.scale.y = 0.07;
    icon.x = 15;
    icon.y = 20;
  }

  function addMicOffToSprite(sprite: PIXI.Sprite): void {
    if (!sprite) return;
    let icon = sprite?.getChildByName('micOff');
    if (icon) {
      return;
    }
    icon = new PIXI.Sprite(MicOff);
    icon.name = 'micOff';
    sprite.addChild(icon);
    icon.scale.x = 0.07;
    icon.scale.y = 0.07;
    icon.y = 5;
  }

  function removeCameraOffFromSprite(sprite: PIXI.Sprite): void {
    if (!sprite) return;
    let text = sprite?.getChildByName('cameraOff');
    while (text) {
      text.destroy();
      text = sprite?.getChildByName('cameraOff');
    }
  }

  function removeMicOffFromSprite(sprite: PIXI.Sprite): void {
    if (!sprite) return;
    let text = sprite?.getChildByName('micOff');
    while (text) {
      text.destroy();
      text = sprite?.getChildByName('micOff');
    }
  }

  function addNameTagToSprite(spriteKey: string): void {
    let sprite = sprites[spriteKey];
    if (spriteKey === userID) sprite = mySprite;
    if (!sprite) return;
    let spriteName = players?.[spriteKey]?.nickname;
    if (!spriteName || !sprite) {
      logger.info(
        `[NAMETAG] not added because: spriteName=${spriteName} sprite=${sprite} player=${JSON.stringify(
          players?.[spriteKey]
        )}`
      );
      return;
    }
    if (sprite?.getChildByName('nameTag')) {
      removeNameTagFromSprite(spriteKey);
    }
    let text = new PIXI.Text(spriteName, { fontFamily: 'Arial', fontSize: 10, fill: 0x1a1a1a, align: 'center' });
    let txtBG = new PIXI.Sprite(PIXI.Texture.WHITE);
    text.name = 'nameTag';
    txtBG.name = 'nameTag';
    sprite.addChild(txtBG);
    txtBG.x = 100;
    txtBG.y = 85;
    text.x = 95;
    text.y = 85;
    text.scale.x = -1;
    txtBG.scale.x = -1;
    txtBG.width = text.width + 8;
    txtBG.height = text.height;
    sprite.addChild(text);
    logger.info(`[NAMETAG] added`);
  }

  function removeNameTagFromSprite(spriteKey: string): void {
    let sprite = sprites[spriteKey];
    if (spriteKey === userID) sprite = mySprite;
    if (!sprite) return;
    let text = sprite?.getChildByName('nameTag');
    while (text) {
      text.destroy();
      text = sprite.getChildByName('nameTag');
      logger.info(`[NAMETAG] removed`);
    }
  }

  /**
   * This function adds players to the game, creating three buttons and setting their parameters as they are created.
   */
  const addNewPlayer = (key: string, curr_x: number, curr_y: number, mode: number): void => {
    const sprite: PIXI.Sprite = createPlayer(curr_x, curr_y, key);
    sprite.interactive = true;
    sprite.buttonMode = false;
    sprite.name = key;
    sprite.zIndex = 3;

    sprites[key] = sprite;
    setSprites(sprites);

    appviewport.addChild(sprite);
    addMiniSpriteOther(key, curr_x, curr_y);
  };

  const addMiniSpriteOther = (key: string, curr_x: number, curr_y: number): void => {
    if (spritesMini[key]) return;
    let circleTexture: PIXI.Texture;
    if (friendsList.includes(key)) circleTexture = generateCircleTexture(5, 0x00c2b8);
    else circleTexture = generateCircleTexture(5, 0xffffff);
    let circleSprite = new PIXI.Sprite(circleTexture);
    circleSprite.x = curr_x * miniMapScale;
    circleSprite.y = curr_y * miniMapScale;
    spritesMini[key] = circleSprite;
    miniMapParticleContainer.addChild(circleSprite);
  };

  const handleChildRemoved = (key: string): void => {
    /* To remove the player a few steps must occur:*/
    /* Grab the data from the hooks*/
    // let hoverInvite = hoverInvites[key];
    let sprite = sprites?.[key];
    let spriteMini = spritesMini?.[key];
    /* Destroy the sprites (Do we need to pass in children=True? */
    try {
      const webcamSprite = getLayerFromSprite(sprite, SpriteLayers.webcam);
      if (webcamSprite) {
        webcamSprite.destroy();
        delete webcamSprites[key];
      }
      // TODO(kevin): can't remove facesprite due to delayed loading
      // const faceSprite = getLayerFromSprite(sprite, SpriteLayers.face);
      // if (faceSprite) faceSprite.destroy({ texture: true });
      sprite.destroy({ children: true });
      spriteMini.destroy();
      delete spritesMini[key];
      delete sprites[key];
    } catch {
      console.log('error with sprite removal');
    }
    /* Delete the data from the hook array */
    delete sprites[key];
    /* Finally update the hook array with the new object */
    setSprites(sprites);
  };

  function onKeyUp(event: KeyboardEvent) {
    if (clickState.current) {
      keyMap = {};
      return;
    }
    if (![87, 38, 65, 37, 68, 83, 40, 39].includes(event.keyCode) || event.metaKey) return;
    keyMap[event.keyCode] = false;
    updateArrowState();
    // TODO(kevin): Might be too strict
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }

  function onKeyDown(event: KeyboardEvent) {
    if (clickState.current) {
      keyMap = {};
      return;
    }
    if (![87, 38, 65, 37, 68, 83, 40, 39].includes(event.keyCode) || event.metaKey) return;
    if (currMode === UserMode.auto) setCurrMode(UserMode.open);

    hasMoved = true;
    keyMap[event.keyCode] = true;
    updateArrowState();
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }

  function updateArrowState(): void {
    let newState = 0;

    if (currMode === UserMode.awaitingCall || currMode === UserMode.accepted || currMode === UserMode.joiningCall) {
      newState = ArrowState.stop;
    } else if ((keyMap[87] || keyMap[38]) && (keyMap[65] || keyMap[37])) {
      // up left
      newState = ArrowState.northwest;
    } else if ((keyMap[87] || keyMap[38]) && (keyMap[68] || keyMap[39])) {
      // up right
      newState = ArrowState.northeast;
    } else if ((keyMap[83] || keyMap[40]) && (keyMap[65] || keyMap[37])) {
      // down left
      newState = ArrowState.southwest;
    } else if ((keyMap[83] || keyMap[40]) && (keyMap[68] || keyMap[39])) {
      // down right
      newState = ArrowState.southeast;
    } else if (keyMap[87] || keyMap[38]) {
      // If the W key or the Up arrow is pressed, move the player up.
      newState = ArrowState.north;
    } else if (keyMap[83] || keyMap[40]) {
      // If the S key or the Down arrow is pressed, move the player down.
      newState = ArrowState.south;
    } else if (keyMap[65] || keyMap[37]) {
      // If the A key or the Left arrow is pressed, move the player to the left.
      newState = ArrowState.west;
    } else if (keyMap[68] || keyMap[39]) {
      // If the D key or the Right arrow is pressed, move the player to the right.
      newState = ArrowState.east;
    } else {
      newState = ArrowState.stop;
    }
    if (newState !== myPlayer.arrowState && mySprite.x + mySprite.y >= 0) {
      try {
        if (newState !== ArrowState.stop) {
          while (appviewport.getChildByName('arrows')) appviewport.getChildByName('arrows').destroy();
        }
      } catch {}
      const updatedMyPlayer = {
        ...myPlayer,
        click_x: mySprite.x,
        click_y: mySprite.y,
        curr_x: mySprite.x,
        curr_y: mySprite.y,
        arrowState: newState,
      };
      //tag
      updateIfAllowed(userID, roomID, updatedMyPlayer);
      target = [];
    }
  }

  // TODO(Kevin): may have to callback
  const endCall = (inPlace = false): void => {
    if (currMode === UserMode.endingCall) return;
    createFailedConnectionReport('TEST');
    checkForFailedConnections();
    target = [];
    console.log('end called', inPlace);
    setCurrMode(UserMode.endingCall);
    setTimeout(() => {
      if (currMode !== UserMode.endingCall) return;
      setCurrMode(UserMode.open);
      if (myPlayer.arrowState === ArrowState.inactive) {
        // Allow for player to check if they can invite right after ending call.
        myPlayer.arrowState = ArrowState.stop;
      }
    }, 600);
    setRender(!render);
    initialConvo = null;
    setIsInCall(false);
    if (wrtc) {
      WebRTC.disconnect(wrtc, removedStream);
      clearAllStreams();
    } else {
      alert('WRTC is null');
    }
    if (!inPlace) zoomTarget = zoomBack;
    let convo = allConvos?.[currConvo];

    if (!inPlace) positionStage(convo, true, openBroadcast);
    myPlayer.convoID = '';
    myPlayer.private = false;
    updateIfAllowed(userID, roomID, {
      ...myPlayer,
      curr_x: mySprite.x,
      curr_y: mySprite.y,
    });
    if (!inPlace) backend.sendHeartbeat(userID, roomID, '');
    setCurrInvite('');
    currConvo = '';
    if (!inPlace) reCenter();

    convo?.members?.forEach((member: string) => {
      let sprite = sprites[member];
      onDisconnectFromSprite(sprite);
      changeBorderColors(member, players?.[member]?.mode);
    });
    onDisconnectFromSprite(mySprite);
    handleAFK();
    //Check to see if any failed connections
    convoArrivalTime = {};
    convoConnections = {};
  };

  //Call this when leaving a call to determine if there
  function checkForFailedConnections() {
    let convo = allConvos[currConvo];
    if (!convo) return;
    convo?.members?.forEach((member: string) => {
      if (!convoConnections[member]) {
        //We never connected to someone
        createFailedConnectionReport('SELF');
        return;
      }
    });
  }

  function createFailedConnectionReport(leaver: string) {
    let wrtcObj = wrtc?.conn?.connections;
    if (!wrtcObj) return;
    let now = Date.now();
    let tag = `[FAILED CONNECTION REPORT] - ${leaver}`;
    let userData: { [uid: string]: number } = {};
    let convo = allConvos[currConvo];
    if (!convo) return;
    let mx = 0;
    convo?.members?.forEach((member: string) => {
      if (userID !== member && !convoConnections[member]) {
        //We never connected to this person
        const val = now - convoArrivalTime[member];
        userData[member] = val;
        mx = Math.max(mx, val);
      }
    });

    let connectionData = ``;
    // Object.entries(wrtcObj).forEach(item => {
    //   connectionData += `${item[0]}: ${JSON.stringify(item[1])} --`;
    // });

    // const pcAttr = ["canTrickleIceCandidates",
    // "connectionState",
    // "currentLocalDescription",
    // "currentRemoteDescription",
    // "iceConnectionState",
    // "iceGatheringState",
    // "localDescription",
    // "pendingLocalDescription",
    // "pendingRemoteDescription",
    // "remoteDescription",
    // "sctp",
    // "signalingState"]
    Object.entries(wrtc?.conn?.connections || {}).forEach(item => {
      connectionData += `${item[0]}: `;
      connectionData += `canTrickleIceCandidates = ${item[1].pc?.canTrickleIceCandidates} --`;
      connectionData += `connectionState = ${item[1].pc?.connectionState} --`;
      connectionData += `currentLocalDescription = ${item[1].pc?.currentLocalDescription} --`;
      connectionData += `currentRemoteDescription = ${item[1].pc?.currentRemoteDescription} --`;
      connectionData += `iceConnectionState = ${item[1].pc?.iceConnectionState} --`;
      connectionData += `iceGatheringState = ${item[1].pc?.iceGatheringState} --`;
      connectionData += `localDescription = ${item[1].pc?.localDescription} --`;
      connectionData += `pendingLocalDescription = ${item[1].pc?.pendingLocalDescription} --`;
      connectionData += `pendingRemoteDescription = ${item[1].pc?.pendingRemoteDescription} --`;
      connectionData += `remoteDescription = ${item[1].pc?.remoteDescription} --`;
      connectionData += `sctp = ${item[1].pc?.sctp} --`;
      connectionData += `signalingState = ${item[1].pc?.signalingState} --`;
    });
    if (mx) {
      logger.info(
        `${tag} | ${mx} | throttleLevel: ${wrtc?.throttleLevel} Durations: ${JSON.stringify(
          userData
        )} | ${connectionData}`
      );
    }
  }

  // async function endCallInPlace(): Promise<void> {
  //   if (wrtc) {
  //     await WebRTC.disconnect(wrtc, removedStream);
  //     clearAllStreams();
  //   } else {
  //     alert('WRTC is null');
  //   }
  // }

  // async function resetCall(): Promise<void> {
  //   logger.info('[CONVO] RESET CALL');
  //   await endCallInPlace();
  //   joinCallInPlace();
  // }

  // function joinCallInPlace(): boolean {
  //   if (wrtc?.conn) {
  //     console.log('Already initialized');
  //     return true;
  //   }
  //   if (wrtc) {
  //     WebRTC.connect(
  //       wrtc,
  //       userID,
  //       currConvo,
  //       videoOn,
  //       userUpdate,
  //       addStream,
  //       removedStream,
  //       () => {},
  //       newWrtc => {
  //         logger.info('[CONVO] Call started');
  //         if (!newWrtc.conn) {
  //           logger.info('[CONVO] No longer in call');
  //           return;
  //         }
  //         myPlayer.socketID = newWrtc.conn?.socket.id || '';
  //         updateIfAllowed(userID, roomID, myPlayer);
  //         // WEBRTC AUTOMATICALLY STARTS INCALL
  //         setCurrMode(UserMode.inCall);
  //         setIsInCall(true);
  //         // resettingCall = false;
  //       }
  //     );
  //   } else {
  //     alert('WRTC IS NULL');
  //     return false;
  //   }
  //   return false;
  // }

  const broadcastAudio = useMemo(() => <BroadcastAudio userID={userID} roomID={roomID} myPlayerRef={myPlayerRef} />, [
    userID,
    roomID,
    myPlayerRef,
  ]);

  // TODO (kevin): toggle affects rendering
  const isLocked = allConvos?.[currConvo]?.private;
  let toggle = React.useMemo(() => {
    if (isInCall) {
      return (
        <>
          <div
            style={{ color: 'white', marginLeft: 5, display: 'flex' }}
            onClick={() => {
              let convo = allConvos?.[currConvo];
              if (!convo) return;
              convo.private = !convo.private;
              // Only update that private variable
              updateConvo(currConvo, roomID, { private: convo.private });
            }}
          >
            <DynamicControl state={isLocked} convo={currConvo} roomID={roomID} isConvo={true} />
          </div>
          <RoomScreenShare />
          {broadcastAudio}
        </>
      );
    }
    return (
      <>
        <div
          style={{ color: 'white', marginLeft: 5, display: 'flex' }}
          onClick={() => {
            myPlayer.private = !myPlayer.private;
            // Reset to check if they want to reconnect
            if (currMode === UserMode.open && myPlayer.arrowState === ArrowState.inactive) {
              myPlayer.arrowState = ArrowState.stop;
            }
            updateIfAllowed(userID, roomID, myPlayer);
          }}
        >
          {/* <div style={{ alignItems: 'center' }}>
          <Fab style={{ backgroundColor: '#1a1a1a', color: 'white' }} size="small"> */}
          <DynamicControl state={myPlayer.private} player={userID} roomID={roomID} isConvo={false} />
          {/* </Fab>
        </div> */}
        </div>
        {broadcastAudio}
      </>
    );
  }, [userID, roomID, broadcastAudio, isLocked, isInCall]);

  function updateFriendsList(input: string[]): void {
    friendsList = input;
    if (!players) return;
    Object.keys(players).forEach((player: string) => {
      let spriteMini = spritesMini[player];
      if (!spriteMini) {
        console.error('MINI SPRITE', player, 'MISSING');
        return;
      }
      if (player === userID) return;
      if (friendsList.includes(player)) {
        spriteMini.texture = generateCircleTexture(5, 0x00c2b8);
      } else {
        spriteMini.texture = generateCircleTexture(5, 0xffffff);
      }
    });
  }
  // const [playerState, setPlayerState] = useState(players)
  // setInterval(function(){if (players) setPlayerState(players)}, 1000)
  const [open, setOpen] = useState(false);

  // function nextSummon() {
  //   if (openSummon) removeSummonFromRoom(openSummon.uid, roomID);
  //   setOpenSummon(null);
  //   if (mySummonsQueue.length) {
  //     let next = mySummonsQueue.shift();
  //     if (!players[next.from]) {
  //       removeSummonFromRoom(next.uid, roomID);
  //       nextSummon();
  //     } else {
  //       setOpenSummon(next);
  //       setTimerKey(timerKey + 1);
  //     }
  //     if (mySummonsQueue.length) setNumSummons((+${mySummonsQueue.length} More));
  //     else setNumSummons('');
  //   }
  // }

  // function handleRejectSummon() {
  //   nextSummon();
  // }
  // function handleRejectSummonCaller(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   handleRejectSummon();
  // }

  useEffect(() => {
    const from = summon.value;
    follow(from);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summon]);

  return (
    <div>
      {isLoading ? <LoadingPage /> : null}
      <Suspense fallback={null}>
        <NotificationNotistack roomId={roomID} userId={userID} setSummon={setSummon} />
      </Suspense>
      {/* <Button style={{ position: 'absolute', left: 0, bottom: 0, zIndex: 1000 }} onClick={() => resetCall()}>
        RESET
      </Button> */}
      <PopUpConfirmAuto
        Query={'Friend Request'}
        Description={'Send friend invite to ' + requestName}
        AcceptText="send"
        DeclineText="cancel"
        AcceptFunction={requestFunction}
        open={open}
        setOpen={setOpen}
      />
      <Dialog open={!mediaEnabled} style={{ backgroundColor: '#1a1a1a', color: 'white' }}>
        <DialogTitle id="alert-dialog-slide-title">
          {'We no longer have permission to use your camera and/or microphone'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            To fix this, click the "<VideocamIcon />" in your URL bar, select "Allow", and then refresh the page.
            Otherwise, for Chrome, paste this url into a new tab's URL bar to access permission settings directly:
            <br />
            chrome://settings/content/siteDetails?site={window.location.origin}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={window.location.reload} color="primary">
            Refresh
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!online}
        message="You are not connected to the internet"
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!serverConnectedWarning}
        message="You are not connected to the server. Reconnecting ..."
      />

      <Dialog open={!serverConnected && !isLoading && !playerLeaving} style={{ color: 'white' }}>
        <DialogTitle id="alert-dialog-slide-title">Reconnecting to server...</DialogTitle>
        <DialogActions>
          <Button onClick={() => window.location.reload()} color="primary">
            Refresh page
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isStuck} style={{ backgroundColor: '#1a1a1a', color: 'white' }}>
        <DialogTitle id="alert-dialog-slide-title">{'You seem stuck :('}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Would you like to reset to this room's start point?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              mySprite.x = playerSpawnX;
              mySprite.y = playerSpawnY;
              setIsStuck(false);
              const data = { curr_x: mySprite.x, curr_y: mySprite.y };
              updateIfAllowed(userID, roomID, data);
              reCenter();
            }}
            color="primary"
          >
            Reset
          </Button>
        </DialogActions>
      </Dialog>
      <Suspense fallback={null}>
        <MainControls
          userIsAdmin={userIsAdmin}
          players={players}
          myPlayerRef={myPlayerRef}
          playersList={playersListRef}
          updateFriendsList={updateFriendsList}
          setRoomChoice={setRoomChoice}
          roomChoice={roomChoice}
          hubStyle={hubStyle}
          hubList={hubList}
          roomID={roomID}
          userID={userID}
          toggle={toggle}
          speakerPosition={speakerPosition}
          open={openBroadcast}
          setOpen={setOpenBroadcast}
          playerCount={playerCount}
          roomHost={roomHost}
          isInCall={isInCall}
          baseRoomID={baseRoomID}
          clickState={clickState}
          nameTagState={nameTagState}
        />
      </Suspense>
      <div
        style={{ backgroundColor: '#161616', width: '100%', height: '100%' }}
        id="pixi-canvas"
        className="canvas"
        ref={container}
        onClick={() => (clickState.current = 0)}
      ></div>
      <Suspense fallback={null}>
        <VideoComponent />
      </Suspense>

      <div
        style={{
          position: 'absolute',
          right: '15.5px',
          top: '77.5px',
          zIndex: 3,
          width: '185px',
          height: '100px',
          backgroundColor: '#1a1a1a',
        }}
      >
        <div
          style={{ backgroundColor: '#1a1a1a', border: '1px solid #707070' }}
          id="pixi-canvas"
          className="canvas"
          ref={containerMini}
        ></div>
      </div>
    </div>
  );
}

export default BarScene;

type BarSceneProps = {
  app: PIXI.Application;
  roomID: string;
  userID: string;
  name: string;
  avatar: string;
  image: string;
  miniMap: PIXI.Application;
  hubStyle: string;
  isHub: boolean;
  baseRoomID: string;
  userIsAdmin: boolean;
  hubList: any[];
};

// Comment to mark the beginning of eliminating linter warnings
