import React, { useState, useEffect, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '../../state';
import { getPublicRoomData } from '../../state/useFirebaseAuth/firebaseHelper';
import LoadingPage from '../LoadingPage/LoadingPage';
import { removePlayerFromRoom } from '../../state/useFirebaseAuth/firebaseRoomActions';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { logger } from '../../Analytics';
import { Card, Container, Button, CardContent } from '@material-ui/core';
import FloorFinder from '../BarLoader/FloorFinder';

export const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Container>
      <Card>
        <CardContent>
          <h1>Something went wrong:</h1>
          <p>Oh no! Something terrible went wrong and we're working hard to make sure that this doesn't happen again</p>
          <a href="mailto:admin@varty.io">
            You can help us by sending us an email (and explain how the error happened).
          </a>
          <p>
            We want to make <i>sure</i> it's not just your fault... jk... unless?
          </p>
          <pre>{error?.message}</pre>
          <Button onClick={resetErrorBoundary}>Try again</Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default function MainScene(props: any) {
  const { BarID } = useParams();
  const [roomName, setRoomName] = useState<any>('');
  const [publicRoomData, setPublicRoomData] = useState<string | boolean | null>(null);
  const { profile } = useAppState();
  const [cleanID, setCleanID] = useState('');

  useEffect(() => {
    const cleaned = BarID.replace(/[.,!*;:{}`~()]/g, '');
    setCleanID(cleaned);

    const newURL = window.location.href.replace(BarID, cleaned);
    window.history.replaceState(null, 'Varty', newURL);
  }, [BarID]);

  useEffect(() => {
    if (!cleanID || !profile) return;
    var roomIdLC = cleanID.toLowerCase();
    let prefix = roomIdLC;
    if (roomIdLC.includes('@')) {
      prefix = roomIdLC.split('@')[0];
    }
    getPublicRoomData(prefix, profile?.uid).then((res: string | boolean) => {
      setPublicRoomData(res);
      if (res) setRoomName(roomIdLC);
      else setRoomName(cleanID);
    });
  }, [cleanID, profile]);

  window.onbeforeunload = unloadCleanup;

  function unloadCleanup() {
    removePlayerFromRoom(profile.uid, cleanID, 'mainSceneCleanup');
  }

  if (roomName === '' || publicRoomData === null || !profile) {
    return <LoadingPage />;
  }
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error: Error, componentStack: string) => {
        logger.error('[BOUNDARY] Stack: ' + componentStack + ' : ' + error.message + '\n[BOUNDARY] ENDSTACK:');
      }}
      onReset={() => {
        logger.error('[BOUNDARY] Refreshing page');
        window.location.reload();
      }}
    >
      <FloorFinder
        roomID={roomName}
        publicRoomData={publicRoomData}
        userID={profile.uid}
        webGLSupport={props.webGLSupport}
      />
    </ErrorBoundary>
  );
}
