import React from 'react';
import Button from '@material-ui/core/Button';
import './UnsupportedBrowser.scss';
import instagramIcon from '../../assets/img/instagram.svg';
import facebookIcon from '../../assets/img/facebook.svg';
import twitterIcon from '../../assets/img/twitter.svg';
import vartyIcon from '../../assets/img/coloredLogo.svg';
import chromeIcon from '../../assets/img/chrome.svg';
import UnsupportedMobile from '../UnsupportedMobile/UnsupportedMobile';
import { DEFAULT_ROOM } from '../../App';

const UnsupportedBrowser = (props: any) => {
  function handleContinue() {
    window.location.href = `https://varty.io${DEFAULT_ROOM}`;
  }

  function handleHub() {
    window.location.href = `https://varty.io/hub`;
  }

  let socialMedia: any = (
    <div className="SocialMediaDiv">
      <a href="https://instagram.com/varty.io">
        <div className="SocialMedia">
          <img className="Icon" src={instagramIcon} alt="handle "></img>
          <p>@varty.io</p>
        </div>
      </a>
      <a href="https://twitter.com/varty_io">
        <div className="SocialMedia">
          <img className="Icon" src={twitterIcon} alt="handle "></img>
          <p>@varty.io</p>
        </div>
      </a>
      <a href="https://www.facebook.com/vartyio-110265477464918">
        <div className="SocialMedia">
          <img className="Icon" src={facebookIcon} alt="handle "></img>
          <p>varty.io</p>
        </div>
      </a>
    </div>
  );
  let vartyImg: any = <img className="VartyIcon" src={vartyIcon} alt="varty logo time"></img>;

  let mobile: any = {};
  let message: any = {};

  // cascading down the tree. First we check if its mobile
  if (props.mobile) {
    return <UnsupportedMobile />;
  } else if (props.badBrowser) {
    message = (
      <div>
        <h3>Oops, it looks like you are using a browser that is not officially supported</h3>
        <h3 style={{ margin: 30 }}>We recommend using Chrome or Microsoft Edge</h3>

        <a href="https://www.google.com/chrome/?brand=CHBD&gclid=EAIaIQobChMInpi76pW46wIVnD6tBh3GCwoNEAAYASAAEgLs6vD_BwE&gclsrc=aw.ds">
          <div className="enterbutton">
            <p>
              Download: <img className="enter" src={chromeIcon} alt="download"></img>
            </p>
          </div>
        </a>
      </div>
    );
  } else {
    message = (
      <div>
        <h3>You have a Working Browser!</h3>
        <p>
          You must have stumbled across the URL of the page somewhere on your travels around Varty. We appreciate your
          interest in the site but the fun can be found by vartying with your friends. So hop on into the Global Lobby
          or into your Hub and lets get the varty started!
        </p>
        <Button onClick={() => handleContinue()}>Jump into the Global Lobby</Button>
        <Button onClick={() => handleHub()}>Jump into your Hub</Button>
      </div>
    );
  }

  let text: any = (
    <div className="Body">
      <div className="Header">{vartyImg}</div>
      <div className="Message">{message}</div>
      {socialMedia}
    </div>
  );

  return (
    <div className="UnsupportedBrowser">
      <div className="Page">{props.mobile ? mobile : text}</div>
    </div>
  );
};

export default UnsupportedBrowser;
