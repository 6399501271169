import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { pathToBarRoomCount, pathToUsermapUser } from '../../state/useFirebaseAuth/firebasePaths';
import LoadingPage from '../LoadingPage/LoadingPage';
import BarLoader from '../BarLoader/BarLoader';
let roomCount: number;
function FloorFinder(props: any) {
  const { roomID, publicRoomData, userID, webGLSupport } = props;
  let initialRoomID = roomID;
  if (roomID.includes('@') && roomID.split('@')[1] === '0') initialRoomID = roomID.split('@')[0];
  const [foundFloor, setFoundFloor] = useState(initialRoomID);
  const [roomNumber, setRoomNumber] = useState(0);
  const [ready, setReady] = useState(false);
  const [capacity, setCapacity] = useState<null | number>(null);
  const [disablePicture, setDisablePicture] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [baseRoomID, setBaseRoomID] = useState(initialRoomID);
  //TODO: deal with hubs.
  useEffect(() => {
    let base = roomID.includes('@') ? roomID.split('@')[0] : roomID;
    setBaseRoomID(base);
    if (baseRoomID === userID) {
      setReady(true);
      //   return;
    } else if (isFull && !disablePicture && foundFloor !== base) {
      setDisablePicture(true);
      setFoundFloor(base);
      return;
      //setReady(true);
      //return;
    }
    const countPath = pathToBarRoomCount(foundFloor);
    const countRef = firebase.database().ref(countPath);
    countRef.once('value', (snap: any) => {
      if (!capacity) return;
      roomCount = snap.val();
      if (!roomCount) roomCount = 0;
      if (roomCount >= capacity && !isFull) {
        setIsFull(true);
      } else if (roomCount >= capacity && isFull) {
        setRoomNumber(roomNumber + 1);
        setFoundFloor(`${base}@${roomNumber + 1}`);
      } else {
        const newURL = window.location.href.replace(roomID, foundFloor);
        window.history.replaceState(null, 'Varty', newURL);
        setReady(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundFloor, isFull, capacity]);

  useEffect(() => {
    if (baseRoomID.length) {
      const userPath = pathToUsermapUser(baseRoomID);
      const userRef = firebase.database().ref(userPath);
      userRef.child('capacity').once('value', (snap: any) => {
        setCapacity(snap.val() || 25);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseRoomID]);
  if (!ready) return <LoadingPage />;
  return (
    <BarLoader
      count={roomCount}
      capacity={capacity || 25}
      roomId={foundFloor}
      userId={userID}
      publicRoomData={publicRoomData}
      baseRoomId={baseRoomID}
      disablePictureTaker={disablePicture}
      webGLSupport={webGLSupport}
    />
  );
}

export default FloorFinder;
