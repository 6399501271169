import ReactGA from 'react-ga';
import adapter from 'webrtc-adapter';
import log from 'loglevel';
// @ts-ignore-nextline
import remote from 'loglevel-plugin-remote';
// @ts-ignore-nextline
import prefix from 'loglevel-plugin-prefix';

export enum AnalyticsCategory {
  Friends = 'friends',
  Screensharing = 'screenshare',
  Call = 'calling',
}

export enum AnalyticsActionFriends {
  Accepted = 'accepted',
  Invited = 'invited',
  Summoned = 'summoned',
}
export enum AnalyticsActionCall {
  Invited = 'invited',
  Joined = 'joined',
  Left = 'left',
}
export enum AnalyticsAction {
  Start = 'started',
  End = 'ended',
  Joined = 'joined',
}

export const logger = log;

// Initialize google analytics page view tracking
export const logPage = (): void => {
  ReactGA.set({ page: window.location.pathname }); // Update the user's current page
  ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
  log.info('[PAGE] ' + window.location.pathname);
};

export const initializePage = (): void => {
  ReactGA.set({
    browser: adapter.browserDetails.browser,
    browserVersion: adapter.browserDetails.version,
  });
};
export const logEvent = (category: AnalyticsCategory, action: string) => {
  ReactGA.event({ category: category.toString(), action: action });
  log.info('[EVENT] ', category.toString(), action);
};

export const USER_SIGNEDOUT = 'ANON';

export const setLogUser = (uid: string) => {
  prefix.apply(log, {
    template: '(%n):',
    nameFormatter: (name: string | undefined) => {
      return (name || uid) + (isDevelopment ? ' DEV' : '');
    },
  });
  if (uid !== USER_SIGNEDOUT) {
    logger.info('[USER]' + JSON.stringify(adapter.browserDetails));
    if (
      adapter.browserDetails.browser === 'firefox' &&
      adapter.browserDetails.version &&
      adapter.browserDetails.version < 67
    ) {
      alert(
        'Your browser is out of date. This site might not work as expected. Please update your browser to use this site!'
      );
      logger.error('[BROWSER] Outdated browser');
    }
  }
  logger.info('[USER] status changed');
};

export const isDevelopment: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.REACT_APP_IS_DEVELOPMENT === 'true';

if (isDevelopment) {
  log.setLevel('trace', true);
} else {
  log.setLevel('error', true);
}

const defaults = {
  url: process.env.REACT_APP_LOGGER_URL + '/logger',
  method: 'POST',
  headers: {},
  token: '',
  onUnauthorized: () => {},
  timeout: 0,
  interval: 5000,
  level: 'trace',
  backoff: {
    multiplier: 2,
    jitter: 0.1,
    limit: 600000,
  },
  capacity: 500,
  stacktrace: {
    levels: ['trace', 'warn', 'error'],
    depth: 3,
    excess: 0,
  },
  timestamp: () => new Date().toISOString(),
  format: remote.plain,
};
log.enableAll();
prefix.reg(log);
remote.apply(log, defaults);

ReactGA.initialize('UA-175200355-1', {
  debug: isDevelopment,
});

if (isDevelopment) {
  ReactGA.set({
    userId: 'DEVELOPMENT',
  });
}
// Last commit
console.log('KING: ', process.env.REACT_APP_LAST_COMMIT);

export default function Analytics() {
  return null;
}
